import ITenantReducer from '../data/tenant/ITenantReducer';
import {
    GET_TENANTS_ACTION,
    GET_TENANTS_FOR_PARTY_ACTION,
    SELECT_TENANT_ACTION
} from '../actions/tenantActions';
import FindTenantsResponse from '../data/response/FindTenantsResponse';
import IAction from '../data/common/IAction';
import Tenant from '../data/tenant/Tenant';

const InitialState: ITenantReducer = {
    tenants: undefined,
    selectedTenant: undefined,
};

const tenantReducer = (state: ITenantReducer = InitialState, action: IAction) => {
    switch (action.type) {
        case SELECT_TENANT_ACTION:
            return {
                ...state,
                selectedTenant: new Tenant(action.payload?.id, action.payload?.name, action.payload?.externalId)
            };
        case GET_TENANTS_ACTION:
        case GET_TENANTS_FOR_PARTY_ACTION:
            return {
                ...state,
                tenants: new FindTenantsResponse(action.payload)
            };
        default:
            return state;
    }
};

export default tenantReducer;