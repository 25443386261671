import {LicenceType} from '../application/LicenceType';

export default class AssignAppToTenantRequest {
    type: LicenceType;
    quantityAssigned: number | undefined;
    quantityUsed: number | undefined;
    keys: string[] | undefined;

    constructor(type: LicenceType, quantityAssigned: number | undefined, quantityUsed: number | undefined, keys: string[] | undefined) {
        this.type = type;
        this.quantityAssigned = quantityAssigned;
        this.quantityUsed = quantityUsed;
        this.keys = keys;
    }
}