import Party from "./Party";

export default class Parties {
  content: Party[];
  total: number | undefined;

  constructor(content: Party[], total: number | undefined) {
    this.content = content;
    this.total = total;
  }

  static create(parties: Parties | undefined): Parties | undefined {
    if (!parties) {
      return undefined;
    }

    return new Parties(
      parties?.content.map((party): any => Party.create(party)),
      parties?.total,
    );
  }
}