import React from 'react';
import i18next from 'i18next';
import {toast} from 'react-toastify';

import {backendClient} from '../rest/restClient';
import CreateTenantRequest from '../data/request/CreateTenantRequest';
import Tenant from '../data/tenant/Tenant';
import ToastBody, {ToastIcons} from '../components/common/other/ToastBody';
import history from '../router/history';
import {AppRoute} from '../router/appRoutes';
import ErrorResponse from '../data/response/ErrorResponse';
import {setAppError} from './appActions';
import {getApplications} from './applicationActions';
import {getPartyForTenant} from './partyActions';
import {getErrorMsg} from '../utils/restUtils';

export const GET_TENANTS_ACTION = 'GET_TENANTS_ACTION';
export const GET_TENANTS_FOR_PARTY_ACTION = 'GET_TENANTS_FOR_PARTY_ACTION';
export const SELECT_TENANT_ACTION = 'SELECT_TENANT_ACTION';

export const getTenants = () => {
    return (dispatch: Function) => {
        return new Promise((resolve, reject) => {
            backendClient.get(`/tenant`)
                .then((response) => {
                    dispatch({
                        type: GET_TENANTS_ACTION,
                        payload: response.data
                    });
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(setAppError(new ErrorResponse(error?.response?.data?.errorCode, error?.response?.data?.errorMessage)));
                    history.push(AppRoute.ERROR);
                    reject(error);
                })
        });
    }
};

export const getTenantsForParty = () => {
    return (dispatch: Function, getState: Function) => {
        const partyId: string = getState().loginReducer.loginData.accessTokenPayload.subB2bUser;
        return new Promise((resolve, reject) => {
            backendClient.get(`/party/${partyId}/findTenants`)
                .then((response) => {
                    dispatch({
                        type: GET_TENANTS_FOR_PARTY_ACTION,
                        payload: response.data
                    });
                    resolve();
                })
                .catch((error: any) => {
                    dispatch(setAppError(new ErrorResponse(error?.response?.data?.errorCode, error?.response?.data?.errorMessage)));
                    history.push(AppRoute.ERROR);
                    reject(error);
                })
        });
    }
};

export const createTenant = () => {
    return (dispatch: Function, getState: Function) => {
        return new Promise(((resolve, reject) => {
            const tenantFormValues = getState().form?.tenant?.values;
            if (!tenantFormValues) {
                toast.error(<ToastBody message={ i18next.t('messages.error.basic') } icon={ ToastIcons.ERROR } />);
                reject();
                return;
            }
            backendClient.post(`/tenant`, getCreateTenantRequest(tenantFormValues))
                .then((response) => {
                    dispatch(getTenants());
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.createTenant')) } icon={ ToastIcons.ERROR } />);
                    reject(error);
                })
        }))
    }
};

export const deleteTenant = (tenantId: string) => {
    return (dispatch: Function, getState: Function) => {
        return new Promise(((resolve, reject) => {
            if (tenantId === getState().tenantReducer.selectedTenant?.id) {
                toast.error(<ToastBody message={i18next.t('messages.error.deleteLoggedTenant')} icon={ToastIcons.ERROR}/>);
                reject();
                return;
            }
            backendClient.delete(`/tenant/${tenantId}`)
                .then((response) => {
                    dispatch(getTenants());
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.deleteTenant'))} icon={ToastIcons.ERROR}/>);
                    reject(error);
                })
        }))
    }
};

export const selectTenant = (selectedTenant: Tenant) => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            if (!selectedTenant) {
                selectedTenant = getState().tenantReducer.tenants?.tenants?.find((tenant: Tenant) => tenant.id === getState().form.tenantSelect?.values?.selected);
            }
            if (selectedTenant) {
                dispatch({
                    type: SELECT_TENANT_ACTION,
                    payload: new Tenant(selectedTenant.id, selectedTenant.name, selectedTenant.externalId)
                });
                dispatch(getApplications());
                dispatch(getPartyForTenant());
                resolve();
            } else {
                dispatch(setAppError(new ErrorResponse(undefined, i18next.t('messages.error.deleteTenant'))));
                reject()
            }
        })
    }
};

const getCreateTenantRequest = (tenantFormValues: any) => {
    return new CreateTenantRequest(tenantFormValues.email,  new Tenant( undefined, tenantFormValues?.name, tenantFormValues?.externalId));
};