import React from 'react';
import i18next from 'i18next';
import { RowInputWithLabel } from '../../common/form/InputTypes';
import { email, required } from '../../../utils/formValidators';
import { InputType } from '../../common/form/Input';
import { ButtonPrimary } from '../../common/button/buttonTypes';

export const TenantFormComponent = (
    {
        onFormSubmit,
        label,
        buttonLabel
    }: ITenantFormComponentProps
) => {

    return (
        <form onSubmit={ onFormSubmit }>
            <div className="row mb-3">
                <div className="col-12 col-sm-9 offset-sm-3">
                    <h5>{ label }</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <RowInputWithLabel name={'email'}
                                       label={ i18next.t('pages.tenant.form.email') }
                                       validate={[ required, email ]}
                                       type={ InputType.MAIL }
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                </div>
            </div>
          <div className="row">
            <div className="col-12">
              <RowInputWithLabel name={'name'}
                                 label={ i18next.t('pages.tenant.form.name') }
                                 validate={[ required ]}
                                 appendIcon
                                 labelClassName="col-12 col-sm-3 text-sm-right"
                                 inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
            </div>
          </div>
            <div className="row mb-3">
                <div className="col-12">
                    <RowInputWithLabel name={'externalId'}
                                       label={ i18next.t('pages.tenant.form.externalId') }
                                       validate={[ required ]}
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5"
                                       maxlength={ 10 } />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-9 offset-sm-3">
                    <ButtonPrimary>{ buttonLabel }</ButtonPrimary>
                </div>
            </div>
        </form>
    )
};

/*PROPS*/

export interface ITenantFormComponentProps {
    onFormSubmit: any,
    label: string
    buttonLabel: string
}