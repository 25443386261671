import { connect } from 'react-redux';
import { IState } from '../../reducers/indexReducer';
import { bindActionCreators } from 'redux';
import { setModalType } from '../../actions/appActions';
import PartyListPage from '../pages/party/PartyListPage';
import { deleteParty, getPartyForTenant, importPartiesForTenant, changeInput, resetPartyFilter, exportPartiesForTenant } from '../../actions/partyActions';

const mapStateToProps = (state: IState) => {
    return {
        userRole: state.loginReducer.userRole,
        modalType: state.appReducer.modalType,
        selectedTenant: state.tenantReducer.selectedTenant,
        partyList: state.partyReducer.partyList,
        total: state.partyReducer.total,
        filter: state.partyReducer.partyFilter
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setModalType,
            deleteParty,
            importPartiesForTenant,
            getPartyForTenant,
            changeInput,
            resetPartyFilter,
            exportPartiesForTenant
        }, dispatch)
    }
};

const PartyListContainer = connect(mapStateToProps, mapDispatchToProps)(PartyListPage);

export default PartyListContainer;