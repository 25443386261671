import LoginData from '../data/login/LoginData';

export const SET_LOGIN_DATA_ACTION = 'SET_LOGIN_DATA_ACTION';

export const setLoginData = (loginData: LoginData): Function => {
  return (dispatch: Function) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_LOGIN_DATA_ACTION,
        payload: loginData
      });
      resolve();
    })
  }
};