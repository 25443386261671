export default class Application {
    id: string;
    activated: boolean;
    description: string;
    guiName: string;
    originalName: string;

    constructor(id: string, activated: boolean, description: string, guiName: string, originalName: string) {
        this.id = id;
        this.activated = activated;
        this.description = description;
        this.guiName = guiName;
        this.originalName = originalName;
    }

    static create(app: any): Application | undefined {
        if (!app) {
            return undefined;
        }

        return new Application(
            app?.id,
            app?.activated,
            app?.description,
            app?.guiName,
            app?.originalName
        );
    }
}
