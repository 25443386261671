import React, {ReactNode} from 'react';
import cx from 'classnames'

import './form.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const RowLabelWrapperForInput = (
    {
        wrapperClassName,
        inputWrapperClassName,
        labelClassName,
        label,
        children,
        readOnly,
        appendIcon= false,
        meta
    }: IRowLabelWrapperForInputProps
) => {

    const wrapperClasses = cx(
        'form-group',
        'row',
        wrapperClassName
    );

    const labelClasses = cx(
        'col-auto',
        'col-form-label',
        labelClassName
    );

    const inputWrapperClasses = cx(
        'col',
        inputWrapperClassName
    );

    return (
        <div className={wrapperClasses}>
            {!!label && <label className={labelClasses}>{label}</label>}
            <div className={inputWrapperClasses}>
                <div className="input-group">
                    {children}
                    {!readOnly && appendIcon &&
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <FontAwesomeIcon className="ml-2" icon={"pen"} />
                        </span>
                    </div>
                    }
                </div>
                {meta && meta.touched && meta.error &&
                <div className={'invalid-feedback'}>
                    {meta.error}
                </div>
                }
            </div>
        </div>
    )
};

export default RowLabelWrapperForInput;

/*PROPS*/ 

export interface IRowLabelWrapperForInputProps {
    wrapperClassName?: string
    inputWrapperClassName?: string
    labelClassName?: string
    label?: any
    children?: ReactNode
    readOnly?: boolean
    appendIcon?: boolean
    meta?: {
        touched?: boolean
        error?: string
    }
}