import { connect } from 'react-redux';
import TenantListPage from '../pages/tenant/TenantListPage';
import { IState } from '../../reducers/indexReducer';
import { bindActionCreators } from 'redux';
import { setModalType } from '../../actions/appActions';
import { deleteTenant, getTenants } from '../../actions/tenantActions';

const mapStateToProps = (state: IState) => {
    return {
        tenants: state.tenantReducer.tenants,
        userRole: state.loginReducer.userRole,
        modalType: state.appReducer.modalType,
        selectedTenant: state.tenantReducer.selectedTenant
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setModalType,
            deleteTenant,
            getTenants
        }, dispatch)
    }
};

const TenantListContainer = connect(mapStateToProps, mapDispatchToProps)(TenantListPage);

export default TenantListContainer;