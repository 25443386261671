export default class PartyListFilterRequest {
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  assigned: boolean | undefined;

  constructor(email: string | undefined, firstName: string | undefined,  lastName: string | undefined, assigned: boolean | undefined) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.assigned = assigned;
  }
}