export default class ApplicationParamType {
    name: string;
    value: string;
    
    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
    
    static create(applicationParamType: any): ApplicationParamType {
        return new ApplicationParamType(applicationParamType?.name, applicationParamType?.value);
    }
}