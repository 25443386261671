import React from 'react';
import {ButtonPrimary, ButtonSecondary} from "../common/button/buttonTypes";
import {ModalType} from "../../constants";
import i18next from 'i18next';
import {toast} from 'react-toastify';
import ToastBody from '../common/other/ToastBody';

const ModalDeleteTenant = (
    {
        setModalType,
        tenantId,
        actions: {
            deleteTenant
        }
    }: IModalDeleteTenantProps
) => {

    const handleContinueClick = ():void => {
        setModalType(ModalType.UNSET);
    };

    const handleDeleteClick = ():void => {
        deleteTenant(tenantId).then(() => toast.success(<ToastBody message={i18next.t('messages.success.deleteTenant')} />));
        setModalType(ModalType.UNSET);
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{i18next.t('modal.deleteTenant.title')}</h5>
            </div>
            <div className="modal-footer">
                <div className="row float-right">
                    <div className="col-auto pr-2">
                        <ButtonSecondary isBlock type={"button"} onClick={handleContinueClick}>{i18next.t('common.buttons.back')}</ButtonSecondary>
                    </div>
                    <div className="col-auto pl-1">
                        <ButtonPrimary isBlock type={"button"} onClick={handleDeleteClick}>{i18next.t('common.buttons.delete')}</ButtonPrimary>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalDeleteTenant;

/*PROPS*/

export interface IModalDeleteTenantProps {
    setModalType?: any
    tenantId: string | undefined
    actions: {
        deleteTenant: any
    }
}