import React from 'react';
import { getLoginDataUrlParamsURL } from '../utils/restUtils';
import qs from 'qs';
import LoginData from '../data/login/LoginData';

const OAuthFrame = (
    {
        actions: {
            setSilentRefresh,
            setLoginData
        }
    }: IOAuthFrameProps
) => {

    const onFrameLoad = (e: any) => {
        const hash: any = qs.parse(e.target?.contentWindow?.location?.hash?.slice(1), {ignoreQueryPrefix: true});
        if (LoginData.validateAccessToken(hash)) {
            setLoginData(new LoginData(hash)).then(() => setSilentRefresh(false));
        }
    };

    return (
        <iframe src={getLoginDataUrlParamsURL(true)}
                title={'OAuthFrame'}
                onLoad={onFrameLoad}
                style={{display: 'none'}}
        />
    )
};

export default OAuthFrame;

export interface IOAuthFrameProps {
    actions: {
        setSilentRefresh: any
        setLoginData: any
    }
}