import React from 'react';
import i18next from 'i18next';

const LogoutPage = () => {

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p className="text-size-3 text-center">
                        {i18next.t('pages.logout.title')}
                        <strong>
                            <a href="/">{i18next.t('common.buttons.here')}</a>
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default LogoutPage;