import React from 'react';
import {Field} from 'redux-form';
import Input, {IInputProps, InputType} from './Input';
import LabelWrapperForInput, {ILabelWrapperForInputProps} from './LabelWrapperForInput';
import RowLabelWrapperForInput, {IRowLabelWrapperForInputProps} from './RowLabelWrapperForInput';
import SelectInput, {ISelectInputProps} from './SelectInput';
import LabelWrapperForCheckbox, {ILabelWrapperForCheckboxProps} from './LabelWrapperForCheckbox';
import Textarea, {ITextareaProps} from './Textarea';
import FileUpload, {IFileUploadProps} from './FileUpload';

export const InputText = (props: IFieldBase & IInputProps) => <Field component={Input} {...props} />;
export const Select = (props: IFieldBase & ISelectInputProps) => <Field component={SelectInput} {...props} />;
export const TextareaInput = (props: IFieldBase & ITextareaProps) => <Field component={Textarea} {...props} />;
export const FileUploadInput = (props: IFieldBase & IFileUploadProps) => <Field component={FileUpload} {...props} />;

export const InputWithLabel = (props: IFieldBase & ILabelWrapperForInputProps & IInputProps) => <Field component={InputWithLabelComponent} {...props} />;
export const RowInputWithLabel = (props: IFieldBase & IRowLabelWrapperForInputProps & IInputProps) => <Field component={RowInputWithLabelComponent} {...props} />;
export const SelectInputWithLabel = (props: IFieldBase & ILabelWrapperForInputProps & ISelectInputProps) => <Field component={SelectInputWithLabelComponent} {...props}/>;
export const RowSelectInputWithLabel = (props: IFieldBase & IRowLabelWrapperForInputProps & ISelectInputProps) => <Field component={RowSelectInputWithLabelComponent} {...props}/>;
export const CheckboxWithLabel = (props: IFieldBase & ILabelWrapperForCheckboxProps & IInputProps) => <Field component={CheckboxWithLabelComponent} {...props} />;
export const TextareaInputWithLabel = (props: IFieldBase & ILabelWrapperForInputProps & ITextareaProps) => <Field component={TextareaInputWithLabelComponent} {...props} />;
export const RowTextareaInputWithLabel = (props: IFieldBase & IRowLabelWrapperForInputProps & ITextareaProps) => <Field component={RowTextareaInputWithLabelComponent} {...props} />;

const InputWithLabelComponent = (props: ILabelWrapperForInputProps & IInputProps) => <LabelWrapperForInput {...props}><Input {...props}/></LabelWrapperForInput>;
const RowInputWithLabelComponent = (props: IRowLabelWrapperForInputProps & IInputProps) => <RowLabelWrapperForInput {...props}><Input {...props}/></RowLabelWrapperForInput>;
const SelectInputWithLabelComponent = (props: ILabelWrapperForInputProps & ISelectInputProps) => <LabelWrapperForInput {...props}><SelectInput {...props}/></LabelWrapperForInput>;
const RowSelectInputWithLabelComponent = (props: IRowLabelWrapperForInputProps & ISelectInputProps) => <RowLabelWrapperForInput {...props}><SelectInput {...props}/></RowLabelWrapperForInput>;
const CheckboxWithLabelComponent = (props: ILabelWrapperForCheckboxProps & IInputProps) => <LabelWrapperForCheckbox {...props}><Input type={InputType.CHECKBOX} {...props}/></LabelWrapperForCheckbox>;
const TextareaInputWithLabelComponent = (props: ILabelWrapperForInputProps & ITextareaProps) => <LabelWrapperForInput {...props}><Textarea {...props}/></LabelWrapperForInput>;
const RowTextareaInputWithLabelComponent = (props: IRowLabelWrapperForInputProps & ITextareaProps) => <RowLabelWrapperForInput {...props}><Textarea {...props}/></RowLabelWrapperForInput>;


interface IFieldBase {
    validate?: any,
    name: string
}
