import axios, { AxiosRequestConfig } from 'axios';
import conf from '../configuration/conf';
import store from '../configureStore';
import { getLoginDataUrlParamsURL, redirectToURL } from '../utils/restUtils';
import { setActiveRequests } from '../actions/appActions';

const basicHeaders: IBasicHeaders = {
    'Content-Type': 'application/json',
};

export const loginRestClient = axios.create({
    baseURL: conf.loginDataURL,
    headers: basicHeaders
});

export const logoutRestClient = axios.create({
    baseURL: conf.logoutURL,
    headers: basicHeaders
});

export const backendClient = axios.create({
    baseURL: conf.backendURL,
    headers: basicHeaders
});

/*INTERCEPTORS*/

backendClient.interceptors.request.use( (config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${store.getState().loginReducer.loginData?.accessToken}`
    };
    increaseActiveRequestCount();
    return config;
});

backendClient.interceptors.response.use( (response) => {
    reduceActiveRequestCount();
    return response;
}, (error) => {
    reduceActiveRequestCount();
    if (error?.response?.status === 401) {
        redirectToURL(getLoginDataUrlParamsURL());
    }
    return Promise.reject(error);
});

const increaseActiveRequestCount = () => {
    store.dispatch(setActiveRequests(store.getState().appReducer.activeRequests +1));
};

const reduceActiveRequestCount = () => {
    if (store.getState().appReducer.activeRequests > 0) {
        store.dispatch(setActiveRequests(store.getState().appReducer.activeRequests -1));
    }
};

/*PROPS*/

export interface IBasicHeaders {
    'Content-Type': string,
    'Authorization'?: string
}