import ResourceAccess from './ResourceAccess';

export default class TokenPayload {
    aud: string;
    exp: number;
    iat: number;
    idf: string;
    iss: string;
    jti: string;
    preferredUsername: string;
    resourceAccess: ResourceAccess | undefined;
    subB2bUser: string;
    subAccount: string | undefined;

    constructor(aud: string, exp: number, iat: number, idf: string, iss: string, jti: string, preferredUsername: string, resourceAccess: ResourceAccess | undefined, subB2bUser: string, subAccount: string | undefined) {
        this.aud = aud;
        this.exp = exp;
        this.iat = iat;
        this.idf = idf;
        this.iss = iss;
        this.jti = jti;
        this.preferredUsername = preferredUsername;
        this.resourceAccess = resourceAccess;
        this.subB2bUser = subB2bUser;
        this.subAccount = subAccount;
    }
}