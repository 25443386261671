export default class ErrorResponse {
    errorCode: String | undefined;
    errorMessage: string;
    
    constructor(errorCode: String | undefined, errorMessage: string) {
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }

    static create(errorResponse: any) {
        if (!errorResponse) {
            return undefined
        }

        return new ErrorResponse(errorResponse.errorCode, errorResponse.errorMessage);
    }
}