import React from 'react';
import { ButtonPrimary, ButtonSecondary } from '../common/button/buttonTypes';
import { ModalType, loginDataUrlParams } from '../../constants';
import i18next from 'i18next';
import { redirectToURL } from '../../utils/restUtils';
import LoginData from '../../data/login/LoginData';
import FindTenantsResponse from '../../data/response/FindTenantsResponse';
import conf from '../../configuration/conf';
import qs from 'qs';

const ModalLogout = (
  {
    setModalType,
    loginData,
    tenants
  }: IModalLogoutProps
) => {

  const redirectLoginUrl = ():string => {
    return encodeURIComponent(conf.loginDataURL + qs.stringify({ ...loginDataUrlParams }, { encode: false, addQueryPrefix: true }));
  }

  const handleContinueClick = ():void => {
    setModalType(ModalType.UNSET);
  };

  const handleLogoutClick = (e?: React.MouseEvent<HTMLElement>):void => {
    e?.preventDefault();
    if (tenants?.party) {
      redirectToURL(`${ conf.logoutURL }?id_token_hint=${ loginData?.idToken }&sid=${ loginData?.identityTokenPayload?.sidB2bUser }&post_logout_redirect_uri=${ redirectLoginUrl() }`);
    } else {
      redirectToURL(`${ conf.logoutURL }?id_token_hint=${ loginData?.idToken }`);
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">{i18next.t('modal.logout.title')}</h5>
      </div>
      <div className="modal-footer">
        <div className="row float-right">
          <div className="col-auto pr-2">
            <ButtonPrimary isBlock type={"button"} onClick={handleLogoutClick}>{i18next.t('common.buttons.logout')}</ButtonPrimary>
          </div>
          <div className="col-auto pl-1">
            <ButtonSecondary isBlock type={"button"} onClick={handleContinueClick}>{i18next.t('common.buttons.back')}</ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ModalLogout;

/*PROPS*/

interface IModalLogoutProps {
  setModalType?: any
  loginData: LoginData | undefined
  tenants: FindTenantsResponse | undefined
}