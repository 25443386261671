import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import ModalHolder from '../common/other/ModalHolder';
import {IState} from '../../reducers/indexReducer';
import {createTenant, selectTenant} from '../../actions/tenantActions';

const mapStateToProps = (state: IState, ownProps: any) => {
    return {
        modalType: state.appReducer.modalType,
        tenants: state.tenantReducer.tenants,
        userRole: state.loginReducer.userRole,
        loginData: state.loginReducer.loginData,
        ownProps
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            selectTenant,
            createTenant
        }, dispatch)
    }
};

const ModalHolderContainer = connect(mapStateToProps, mapDispatchToProps)(ModalHolder);

export default ModalHolderContainer;