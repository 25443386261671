import React, {ReactNode} from 'react'
import Card from './Card';

const CardFormWrapper = (
    {
        children
    }:ICardFormWrapperProps
) => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Card>
                        {children}
                    </Card>
                </div>
            </div>
        </div>
    )
};

export default CardFormWrapper

/*PROPS*/

interface ICardFormWrapperProps {
    children: ReactNode
}