import React from 'react'
import i18next from 'i18next';
import {toast} from 'react-toastify';
import ToastBody from '../../common/other/ToastBody';
import { TenantFormComponent } from './TenantFormComponent';
import CardFormWrapper from '../../common/other/CardFormWrapper';
import history from '../../../router/history';
import {AppRoute} from '../../../router/appRoutes';
import Tenant from '../../../data/tenant/Tenant';

export const TenantCreatePage = (
    {
        selectedTenant,
        handleSubmit,
        actions: {
            createTenant
        }
    }: ITenantCreatePageProps
) => {

    const onFormSubmit = () => {
        createTenant().then(() => {
            toast.success(<ToastBody message={i18next.t('messages.success.createTenant')} />);
            history.push(AppRoute.TENANT_LIST);
        });
    };

    if (!selectedTenant) {
        return <div/>
    }

    return (
        <CardFormWrapper>
            <TenantFormComponent onFormSubmit={handleSubmit(() => onFormSubmit())}
                                 label={i18next.t('pages.tenant.create.label')}
                                 buttonLabel={i18next.t('common.buttons.create')}/>
        </CardFormWrapper>
    )
};

/*PROPS*/

export interface ITenantCreatePageProps {
    selectedTenant: Tenant | undefined
    handleSubmit: any
    error: any
    actions: {
        createTenant: any
    }
}