export default class Tenant {
    id: string | undefined;
    name: string;
    externalId: string | undefined;

    constructor(id: string | undefined, name: string, externalId: string | undefined) {
        this.id = id;
        this.name = name;
        this.externalId = externalId;
    }
}