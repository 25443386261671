import Application from '../application/Application';
import ApplicationParam from '../application/ApplicationParam';
import Licence from '../application/Licence';
import Parties from "../party/Parties";

export default class ApplicationResponse {
    application: Application | undefined;
    parameters: ApplicationParam[] | undefined;
    licence: Licence | undefined;
    parties: Parties | undefined;

    constructor(application: Application | undefined, parameters: ApplicationParam[] | undefined, licence: Licence | undefined, parties: Parties | undefined) {
        this.application = application;
        this.parameters = parameters;
        this.licence = licence;
        this.parties = parties;
    }

    static create(tenantApplicationResponse: ApplicationResponse): ApplicationResponse | undefined {
        if (!tenantApplicationResponse) {
            return undefined;
        }

        return new ApplicationResponse(
            Application.create(tenantApplicationResponse?.application),
            tenantApplicationResponse?.parameters?.map((param): any => ApplicationParam.create(param)),
            Licence.create(tenantApplicationResponse?.licence),
            Parties.create(tenantApplicationResponse?.parties),
        )
    }
}