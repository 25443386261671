import ErrorResponse from '../data/response/ErrorResponse';

export const SET_MODAL_TYPE_ACTION = 'SET_MODAL_TYPE_ACTION';
export const SET_APP_ERROR_ACTION = 'SET_APP_ERROR_ACTION';
export const SET_ACTIVE_REQUESTS_ACTION = 'SET_ACTIVE_REQUESTS_ACTION';
export const SET_OPEN_MODAL_FOR_ID = 'SET_OPEN_MODAL_FOR_ID';

export const setModalType = (modalType: number, e?: React.MouseEvent<HTMLElement>) => {
    e && e.preventDefault();
    return (dispatch: any, getState: any) => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_MODAL_TYPE_ACTION,
                payload: modalType
            });
            resolve();
        })
    }
};

export const setModalPartyId = (id: string | undefined) => {
    return (dispatch: any, getState: any) => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_OPEN_MODAL_FOR_ID,
                payload: id
            });
            resolve();
        })
    }
};

export const setAppError = (error: ErrorResponse) => {
    return (dispatch: any) => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_APP_ERROR_ACTION,
                payload: error
            });
            resolve();
        })
    }
};

export const setActiveRequests = (count: number) => {
    return (dispatch: any) => {
        return new Promise((resolve) => {
            dispatch({
                type: SET_ACTIVE_REQUESTS_ACTION,
                payload: count
            });
            resolve();
        })
    }
};