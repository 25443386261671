import {reduxForm} from "redux-form";

export const tenantForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'tenant',
    touchOnBlur: false
})(component);

export const tenantSelectForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'tenantSelect',
    touchOnBlur: false
})(component);