import i18next from 'i18next';

export const required = (value: any) => (!!value ? undefined : i18next.t('messages.error.formValidators.required'));
export const requiredCustomMsg = (msg: string | undefined) => (value: any) => (!!value ? undefined : !!msg ? msg : i18next.t('messages.error.formValidators.wrongFormat'));

export const regex = (regex: string) => (value: string) => !!value && !RegExp(regex).test(value) ? i18next.t('messages.error.formValidators.wrongFormat') : undefined;
export const regexCustomMsg = (regex: string, msg: string | undefined) => (value: string) => !!value && !RegExp(regex).test(value) ? !!msg ? msg : i18next.t('messages.error.formValidators.wrongFormat') : undefined;
export const email = (value: string) => !!value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value) ? i18next.t('messages.error.formValidators.email') : undefined;

export const minValue = (min: number) => (value: number) => !!value && value < min ? i18next.t('messages.error.formValidators.minValue', {min: min}) : undefined;
export const maxValue = (max: number) => (value: number) => !!value && value > max ? i18next.t('messages.error.formValidators.maxValue', {max: max}) : undefined;

export const minValue1 = minValue(1);
