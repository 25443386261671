import IAppReducer from '../data/app/IAppReducer';
import IAction from '../data/common/IAction';
import {
    SET_ACTIVE_REQUESTS_ACTION,
    SET_APP_ERROR_ACTION,
    SET_MODAL_TYPE_ACTION,
    SET_OPEN_MODAL_FOR_ID
} from '../actions/appActions';

const initialState: IAppReducer = {
    modalType: 0,
    activeRequests: 0,
    appError: undefined,
    modalPartyId: undefined
};

const appReducer = (state: IAppReducer = initialState, action: IAction): IAppReducer => {
    switch (action.type) {
        case SET_MODAL_TYPE_ACTION:
            return {
                ...state,
                modalType: action.payload
            };
        case SET_OPEN_MODAL_FOR_ID:
            return {
                ...state,
                modalPartyId: action.payload
            };
        case SET_APP_ERROR_ACTION:
            return {
                ...state,
                modalType: 0,
                appError: action.payload
            };
        case SET_ACTIVE_REQUESTS_ACTION:
            return {
                ...state,
                activeRequests: action.payload
            };
        default:
            return state;
    }
};

export default appReducer;


