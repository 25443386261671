import ResourceAccess from './ResourceAccess';
import TokenPayload from './TokenPayload';
import {IRawAccessTokenPayload} from './IRawTokenPayloads';

export default class AccessTokenPayload extends TokenPayload{

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(aud: string, exp: number, iat: number, idf: string, iss: string, jti: string, preferredUsername: string, resourceAccess: ResourceAccess | undefined, subB2bUser: string, subAccount: string | undefined) {
        super(aud, exp, iat, idf, iss, jti, preferredUsername, resourceAccess, subB2bUser, subAccount)
    }

    static create(accessTokenPayload: any): AccessTokenPayload | undefined {
        if (!accessTokenPayload) {
            return undefined
        }

        return new AccessTokenPayload(
            accessTokenPayload.aud,
            accessTokenPayload.exp,
            accessTokenPayload.iat,
            accessTokenPayload.idf,
            accessTokenPayload.iss,
            accessTokenPayload.jti,
            accessTokenPayload['preferred_username'],
            ResourceAccess.create(accessTokenPayload['resource_access']),
            accessTokenPayload['sub.account'],
            accessTokenPayload['sub.b2bMu']
        );
    }

    static validate = (data: IRawAccessTokenPayload): boolean => !!data && !!data.resource_access && !!data['sub.b2bMu'];
}
