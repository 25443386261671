import qs from 'qs';
import { loginDataUrlParams } from '../constants';
import conf from '../configuration/conf';
import IOauthStateParam from '../data/common/IOauthStateParam';
import history from '../router/history';
import { AppRoute } from '../router/appRoutes';
import ILoginDataUrlParams from '../data/common/ILoginDataUrlParams';
import ErrorResponse from '../data/response/ErrorResponse';

export const getLoginDataUrlParamsURL = (silentRefresh?: boolean): string => {
  return conf.loginDataURL + qs.stringify(getLoginDataUrlParamsObj(silentRefresh), { addQueryPrefix: true });
};

export const getLoginDataUrlParamsObj = (silentRefresh: boolean = false): ILoginDataUrlParams => {
  if (silentRefresh) {
    return {
      ...loginDataUrlParams,
      prompt: 'none',
      redirect_uri: conf.oauthRedirectURL
    };
  }
  return {
    ...loginDataUrlParams,
    state: createOauthStateParam(),
    redirect_uri: conf.oauthRedirectURL
  };
};

export const redirectToURL = (redirectURL: string, sameWindow: boolean = true): void => {
  window.open(redirectURL, sameWindow ? '_self' : '_blank');
};

export const parseJWT = (token: string) => {
  if (!token) {
    return undefined; //TODO error handling
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const createOauthStateParam = (): string | undefined => {
  if (history.location.pathname === AppRoute.INDEX && !history.location.search) {
    return undefined;
  }
  const OauthStateParam: IOauthStateParam = {
    path: history.location.pathname,
    params: history.location.search
  };

  return btoa(JSON.stringify(OauthStateParam));
};

export const getOauthStatePath = (stateParam: string | undefined) => {
  if (!stateParam) {
    return undefined;
  }
  const OauthStateParam: IOauthStateParam = JSON.parse(atob(stateParam));
  return OauthStateParam.path + OauthStateParam.params;
};

export const getErrorMsg = (errorResponse: ErrorResponse | undefined, defaultMsg: string): string => {
  return !!errorResponse?.errorMessage ? errorResponse?.errorMessage : defaultMsg;
};
