import React, { useCallback } from 'react';
import Table from '../../common/table/Table';
import i18next from 'i18next';
import ApplicationResponse from '../../../data/response/ApplicationResponse';
import { ButtonSecondary } from '../../common/button/buttonTypes';
import { ModalType } from '../../../constants';
import IApplicationPartyAttributes from "../../../data/application/IApplicationPartyAttributes";
import Pagination from "../../common/other/Pagination";
import ITableHeader from "../../../data/common/ITableHeader";
import PartyListFilterRequest from "../../../data/request/PartyListFilterRequest";
import { debounce } from '../../../utils/utils';

const ApplicationDetailPartyTable = (
  {
    applicationDetail,
    actions: {
      setModalType,
      setModalPartyId,
      setSelectedPartyId,
      getApplicationDetail,
      changeInput
    },
    total,
    filter,
    applicationId
  }: IApplicationDetailPartyTableProps
) => {

  const handleDetailClick = (e: React.MouseEvent<HTMLElement>, partyId: string | undefined): void => {
    handleOnClick(e, partyId, ModalType.APPLICATION_PARTY_DETAIL);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, partyId: string | undefined): void => {
    handleOnClick(e, partyId, ModalType.DELETE_APPLICATION_PARTY_BINDING);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement>, partyId: string | undefined, modalType: ModalType): void => {
    if (!!partyId) {
      setSelectedPartyId(partyId);
      setModalType(modalType, e);
    }
  };

  const getParty = (page: number): void => {
    getApplicationDetail(applicationId, page);
  }

  const debouncedGetApplicationDetail = useCallback(
    debounce(() => getApplicationDetail(applicationId)), []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeInput(e.target.name, e.target.value);
    debouncedGetApplicationDetail();
  }

  const handleAssignAppToParty = (e: React.MouseEvent<HTMLElement>, id: string | undefined) => {
    setModalPartyId(id);
    setModalType(ModalType.ASSIGN_APPLICATION_TO_PARTY, e);
  }

  const applicationDetailTableColumns: ITableHeader[] = [
    {
      title: i18next.t('pages.application.detail.table.header.order'),
      dataIndex: 'order',
      filterable: false
    },
    {
      title: i18next.t('pages.application.detail.table.header.email'),
      dataIndex: 'email',
      filterable: true
    },
    {
      title: i18next.t('pages.application.detail.table.header.firstName'),
      dataIndex: 'firstName',
      filterable: true
    },
    {
      title: i18next.t('pages.application.detail.table.header.lastname'),
      dataIndex: 'lastName',
      filterable: true
    },
    {
      title: i18next.t('pages.application.detail.table.header.role'),
      dataIndex: 'role',
      filterable: false
    },
    {
      title: i18next.t('pages.application.detail.table.header.serialNumber'),
      dataIndex: 'serialNumber',
      filterable: false
    }
  ];

  const partyList = applicationDetail?.parties?.content;

  const partyButtons = (partyId: string | undefined) => {
    if (filter.assigned) return (
      <span>
        <ButtonSecondary type={'button'}
                         className="mr-1"
                         onClick={ (e: React.MouseEvent<HTMLElement>) => handleDetailClick(e, partyId) }>
          { i18next.t('common.buttons.detail') }
        </ButtonSecondary>
        <ButtonSecondary type={'button'}
                         className="ml-1"
                         onClick={ (e: React.MouseEvent<HTMLElement>) => handleDeleteClick(e, partyId) }>
          { i18next.t('common.buttons.delete') }
        </ButtonSecondary>
      </span>
    )
    else return (
      <span>
        <ButtonSecondary type={'button'}
                         className="mr-1"
                         onClick={ (e: React.MouseEvent<HTMLElement>) => handleAssignAppToParty(e, partyId) }>
          { i18next.t('pages.application.detail.assignPartyButton') }
        </ButtonSecondary>
      </span>
    )
  }

  return (
    <div className="overflow-auto">
      <Table headers={ applicationDetailTableColumns } emptyHeader={["after"]}
             onInputChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e) }
      >
        { partyList && partyList.length > 0
          ? partyList.map((party, index) => {

            const attributes: IApplicationPartyAttributes = {
              application: undefined,
              role: undefined,
              serial_number: undefined,
              tenant: undefined
            }

            if (party.attributes) {
              for (const attr of party.attributes) {
                switch (attr.name) {
                  case 'role':
                    attributes.role = attr.value;
                    break;
                  case 'serial_number':
                    attributes.serial_number = attr.value;
                    break;
                }
              }
            }

            return (
              <tr key={ party.id }>
                <th scope="row">{ ++index }</th>
                <td>{ party.email }</td>
                <td>{ party.firstName }</td>
                <td>{ party.lastName }</td>
                { Object.entries(attributes).map(([key, value]) => {
                  if (key !== 'tenant' && key !== 'application') return <td key={ key }>{ value }</td>
                })}
                <td className="p-2">{ partyButtons(party.id) }</td>
              </tr>
            )
          })
          : <tr><td colSpan={ 8 }>{ i18next.t('pages.application.detail.noPartiesFound') }</td></tr>
        }
      </Table>
      <Pagination label="application detail party list pagination"
                  total={ total }
                  filter={ filter }
                  actions={{ getParty }}/>
    </div>
  )
};

export default ApplicationDetailPartyTable;

// PROPS
export interface IApplicationDetailPartyTableProps {
  applicationDetail: ApplicationResponse | undefined
  actions: {
    setModalType: any
    setModalPartyId: any
    setSelectedPartyId: any
    getApplicationDetail: any
    changeInput: any
  }
  total: number | undefined
  filter: PartyListFilterRequest
  applicationId: string
}