import React, { useState } from 'react';
import Card from '../../common/other/Card';
import i18next from 'i18next';
import Tenant from '../../../data/tenant/Tenant';
import Table from '../../common/table/Table';
import ApplicationResponse from '../../../data/response/ApplicationResponse';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonSecondaryInverted
} from '../../common/button/buttonTypes';
import { LicenceOperation, ModalType } from '../../../constants';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';
import ModalContainer from '../../containers/ModalContainer';
import ModalAssignApplicationToTenant from '../../modal/ModalAssignApplicationToTenant';
import { LicenceType } from '../../../data/application/LicenceType';
import ModalUpdateApplicationLicence from '../../modal/ModalUpdateApplicationLicence';
import ModalApplicationTenantBindingDelete from '../../modal/ModalApplicationTenantBindingDelete';

import './ApplicationListPage.scss';

const ApplicationListPage = ({
  modalType,
  selectedTenant,
  applications,
  applicationDetail,
  licenceTypeFieldValue,
  licenceOperationFieldValue,
  actions: {
    setModalType,
    assignAppToTenant,
    getApplications,
    getApplicationDetail,
    resetApplicationDetail,
    updateApplicationLicence,
    deleteApplicationTenantBinding
  }
}: IApplicationListPageProps) => {
  const [applicationDetailId, setApplicationDetialId] = useState<any>();

  const handleActionClick = (
    e: React.MouseEvent<HTMLElement>,
    applicationId: string | undefined,
    modalType: ModalType = ModalType.ASSIGN_APPLICATION_TO_TENANT
  ) => {
    if (!!applicationId) {
      setApplicationDetialId(applicationId);
      setModalType(modalType, e);
    }
  };

  if (!selectedTenant) {
    return <div />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Card title={i18next.t('pages.application.list.label')}>
            <Table
              headers={i18next.t('pages.application.list.table.header', {
                returnObjects: true
              })}
              emptyHeader={['after']}
            >
              {applications?.map(
                (applicationResponse: ApplicationResponse, index) => {
                  return (
                    <tr key={applicationResponse.application?.id}>
                      <th scope="row">{++index}</th>
                      <td>{applicationResponse.application?.guiName}</td>
                      <td>{applicationResponse.application?.originalName}</td>
                      <td>
                        <strong>
                          {i18next.t(
                            applicationResponse.application?.activated
                              ? 'common.yes'
                              : 'common.no'
                          )}
                        </strong>
                      </td>
                      <td className="p-2 flex-end">
                        {applicationResponse.application?.activated && (
                          <React.Fragment>
                            {applicationResponse.licence?.type !==
                              LicenceType.UNLIMITED && (
                              <ButtonSecondary
                                type={'button'}
                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                  handleActionClick(
                                    e,
                                    applicationResponse.application?.id,
                                    ModalType.UPDATE_APPLICATION_LICENCE
                                  )
                                }
                              >
                                {i18next.t('common.buttons.edit')}
                              </ButtonSecondary>
                            )}
                            <ButtonSecondaryInverted
                              type={'button'}
                              className="ml-2"
                              onClick={(e: React.MouseEvent<HTMLElement>) =>
                                handleActionClick(
                                  e,
                                  applicationResponse.application?.id,
                                  ModalType.DELETE_APPLICATION_TENANT_BINDING
                                )
                              }
                            >
                              {i18next.t('common.buttons.remove')}
                            </ButtonSecondaryInverted>
                          </React.Fragment>
                        )}
                        {!applicationResponse.application?.activated && (
                          <ButtonPrimary
                            type={'button'}
                            onClick={(e: React.MouseEvent<HTMLElement>) =>
                              handleActionClick(
                                e,
                                applicationResponse.application?.id
                              )
                            }
                          >
                            {i18next.t('common.buttons.assign')}
                          </ButtonPrimary>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </Table>
          </Card>
        </div>
      </div>
      <CSSTransitionHOC
        renderOn={modalType === ModalType.ASSIGN_APPLICATION_TO_TENANT}
      >
        <ModalContainer modalContentClassName="modal-overflow--visible">
          <ModalAssignApplicationToTenant
            applicationDetailId={applicationDetailId}
            applicationDetail={applicationDetail}
            licenceTypeFieldValue={licenceTypeFieldValue}
            actions={{
              assignAppToTenant,
              getApplications,
              getApplicationDetail,
              resetApplicationDetail
            }}
          />
        </ModalContainer>
      </CSSTransitionHOC>
      <CSSTransitionHOC
        renderOn={modalType === ModalType.UPDATE_APPLICATION_LICENCE}
      >
        <ModalContainer modalContentClassName="modal-overflow--visible">
          <ModalUpdateApplicationLicence
            applicationDetailId={applicationDetailId}
            applicationDetail={applicationDetail}
            licenceOperationFieldValue={licenceOperationFieldValue}
            actions={{
              getApplications,
              getApplicationDetail,
              resetApplicationDetail,
              updateApplicationLicence
            }}
          />
        </ModalContainer>
      </CSSTransitionHOC>
      <CSSTransitionHOC
        renderOn={modalType === ModalType.DELETE_APPLICATION_TENANT_BINDING}
      >
        <ModalContainer>
          <ModalApplicationTenantBindingDelete
            applicationId={applicationDetailId}
            actions={{ getApplications, deleteApplicationTenantBinding }}
          />
        </ModalContainer>
      </CSSTransitionHOC>
    </div>
  );
};

export default ApplicationListPage;

/*PROPS*/

export interface IApplicationListPageProps {
  modalType: ModalType;
  selectedTenant: Tenant | undefined;
  applications: ApplicationResponse[] | undefined;
  applicationDetail: ApplicationResponse | undefined;
  licenceTypeFieldValue: LicenceType | undefined;
  licenceOperationFieldValue: LicenceOperation | undefined;
  actions: {
    setModalType: any;
    assignAppToTenant: any;
    getApplications: any;
    getApplicationDetail: any;
    resetApplicationDetail: any;
    updateApplicationLicence: any;
    deleteApplicationTenantBinding: any;
  };
}
