import ILoginDataUrlParams from './data/common/ILoginDataUrlParams';

export const loginDataUrlParams: ILoginDataUrlParams = {
    'response_type': 'token',
    'client_id': 'b2bmu',
    'scope': 'openid',
    'acr_values': 'sk:orange:sso:role:b2bUser'
};

export enum ModalType {
    UNSET,
    CREATE_TENANT,
    CREATE_TENANT_REQUIRED,
    DELETE_PARTY,
    SELECT_TENANT,
    RESELECT_TENANT,
    DELETE_TENANT,
    ASSIGN_APPLICATION_TO_TENANT,
    ASSIGN_APPLICATION_TO_PARTY,
    UPDATE_APPLICATION_LICENCE,
    APPLICATION_PARTY_DETAIL,
    DELETE_APPLICATION_PARTY_BINDING,
    LOGOUT,
    DELETE_APPLICATION_TENANT_BINDING
}

export enum FormOperation {
    CREATE,
    READ,
    UPDATE
}

export enum Scope {
    BASE_INFO = 'baseInfo',
    PARAM_DETAILS = 'paramDetails',
    LICENCE = 'licence',
    ASSIGNED_PARTIES = 'assigned_parties'
}

export enum LicenceOperation {
    INCREASE = 'increaseQty',
    DECREASE = 'decreaseQty'
}

export enum ApplicationParamTypeName {
    DROP_DOWN_OPTION = 'dropDownOption',
    PLACEHOLDER = 'placeholder',
    DEFAULT_VALUE = 'defaultValue',
    TOOL_TIP = 'tooltip',
    ERROR_MSG = 'errorMessage'
}

export enum WebSocketEvent {
    LOGOUT = 'http://schemas.openid.net/event/backchannel-logout'
}

export const TENANT_PAGE_SIZE: number = 10;