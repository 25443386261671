import React, {ReactNode, useState} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './accordion.scss'

const Accordion = (
    {
        title,
        children,
        className
    }: IAccordionProps
) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    };

    const classes = cx(
        'card',
        'accordion',
        className
    );


    return (
        <div className={classes}>
            <div className="card-body">
                <h6 className="card-title accordion--header" onClick={onClickHandler}>
                    {title}
                    <FontAwesomeIcon className={`float-right icon ${!isCollapsed ? 'rotate' : ''}`} icon={"angle-down"} />
                </h6>
                <div className={`accordion--body ${isCollapsed ? 'collapsed' : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Accordion;

/*PROPS*/

export interface IAccordionProps {
    title: string
    children: ReactNode
    className?: string
}