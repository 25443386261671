import { connect } from 'react-redux';
import ApplicationDetailPage from '../pages/app/ApplicationDetailPage';
import { IState } from '../../reducers/indexReducer';
import { bindActionCreators } from 'redux';
import {
    assignAppToParty,
    deleteApplicationPartyBinding,
    getApplicationDetail,
    getApplicationPartyBindingDetail,
    importPartiesForApplication,
    resetApplicationDetail,
    resetApplicationPartyBindingDetail,
    updateApplicationPartyBinding,
    changeInput,
    resetApplicationFilter,
    exportPartiesForApplication
} from '../../actions/applicationActions';
import { applicationDetailForm } from './form/ApplicationForm';
import { setModalType, setModalPartyId } from '../../actions/appActions';
import { getFormValues } from 'redux-form';

const mapStateToProps = (state: IState) => {
    return {
        applicationDetail: state.applicationReducer.applicationDetail,
        selectedTenant: state.tenantReducer.selectedTenant,
        modalType: state.appReducer.modalType,
        modalPartyId: state.appReducer.modalPartyId,
        partyList: state.applicationReducer.applicationDetail?.parties?.content,
        assignApplicationToPartyFormValues: getFormValues('assignApplicationToPartyForm')(state),
        applicationPartyBindingDetail: state.applicationReducer.applicationPartyBindingDetail,
        total: state.applicationReducer.applicationDetail?.parties?.total,
        filter: state.applicationReducer.applicationFilter
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setModalType,
            setModalPartyId: setModalPartyId,
            getApplicationDetail,
            resetApplicationDetail,
            assignAppToParty,
            getApplicationPartyBindingDetail,
            resetApplicationPartyBindingDetail,
            updateApplicationPartyBinding,
            deleteApplicationPartyBinding,
            importPartiesForApplication,
            changeInput,
            resetApplicationFilter,
            exportPartiesForApplication
        }, dispatch)
    }
};

const ApplicationDetailPageContainer = connect(mapStateToProps, mapDispatchToProps)(applicationDetailForm(ApplicationDetailPage));

export default ApplicationDetailPageContainer;