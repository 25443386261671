import Tenant from '../tenant/Tenant';

export default class CreateTenantRequest {
    initialAdminEmail: string;
    tenant: Tenant;


    constructor(initialAdminEmail: string, tenant: Tenant) {
        this.initialAdminEmail = initialAdminEmail;
        this.tenant = tenant;
    }
}