import {reduxForm} from "redux-form";

export const partyCreateForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'partyCreate',
    touchOnBlur: false
})(component);

export const partyDetailForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'partyDetail',
    touchOnBlur: false
})(component);