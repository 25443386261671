import React from 'react';
import i18next from 'i18next';
import {ButtonPrimary, ButtonSecondary} from '../common/button/buttonTypes';
import {ModalType} from '../../constants';
import {toast} from 'react-toastify';
import ToastBody from '../common/other/ToastBody';

const ModalApplicationPartyBindingDelete = (
    {
        setModalType,
        selectedPartyId,
        applicationId,
        actions:{
            deleteApplicationPartyBinding,
            getApplicationDetail
        }
    }: IModalApplicationPartyBindingDeleteProps
) => {


    const handleOnDeleteClick = ():void => {
        deleteApplicationPartyBinding(selectedPartyId).then(() => {
            getApplicationDetail(applicationId).then(() => {
                toast.success(<ToastBody message={i18next.t('messages.success.deleteApplicationPartyBinding')} />);
                setModalType(ModalType.UNSET);
            });
        });
    };

    const handleOnCancelClick = (): void => {
        setModalType(ModalType.UNSET);
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{i18next.t('modal.applicationPartyBindingDelete.title')}</h5>
            </div>
            <div className="modal-footer">
                <div className="row float-right">
                    <div className="col-auto pr-2">
                        <ButtonPrimary type="button"
                                       onClick={handleOnDeleteClick}>
                            {i18next.t('common.buttons.delete')}
                        </ButtonPrimary>
                    </div>
                    <div className="col-auto pl-1">
                        <ButtonSecondary type="button"
                                         onClick={handleOnCancelClick}>
                            {i18next.t('common.buttons.back')}
                        </ButtonSecondary>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalApplicationPartyBindingDelete;

/*PROPS*/

export interface IModalApplicationPartyBindingDeleteProps {
    setModalType?: any
    selectedPartyId: string
    applicationId: string
    actions: {
        deleteApplicationPartyBinding: any
        getApplicationDetail: any
    }
}