import i18n from 'i18next';
import lang_sk from './i18n/sk.json';
/*
* Initialization for i18next module
*/
i18n
    .init({
        resources: {
            sk: lang_sk
        },
        fallbackLng: 'sk',
        debug: true,
        lng: 'sk'
    });

export default i18n;