import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IState } from '../../reducers/indexReducer';
import { setModalType, setModalPartyId } from '../../actions/appActions';
import Modal from '../modal/Modal';

const mapStateToProps = (state: IState, ownProps: any) => {
    return {
        modalType: state.appReducer.modalType,
        ownProps
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setModalType,
            setModalPartyId: setModalPartyId
        }, dispatch)
    }
};

const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default ModalContainer;