import {connect} from 'react-redux';
import ApplicationListPage from '../pages/app/ApplicationListPage';
import {IState} from '../../reducers/indexReducer';
import {bindActionCreators} from 'redux';
import {
    assignAppToTenant, deleteApplicationTenantBinding,
    getApplicationDetail,
    getApplications,
    resetApplicationDetail, updateApplicationLicence
} from '../../actions/applicationActions';
import {setModalType} from '../../actions/appActions';
import {formValueSelector} from 'redux-form';

const mapStateToProps = (state: IState) => {
    const selector = formValueSelector('application'); // <-- same as form name

    return {
        licenceTypeFieldValue: selector(state, 'licenceType'),
        licenceOperationFieldValue: selector(state, 'licenceOperation'),
        modalType: state.appReducer.modalType,
        selectedTenant: state.tenantReducer.selectedTenant,
        applications: state.applicationReducer.applications,
        applicationDetail: state.applicationReducer.applicationDetail
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setModalType,
            assignAppToTenant,
            getApplications,
            getApplicationDetail,
            resetApplicationDetail,
            updateApplicationLicence,
            deleteApplicationTenantBinding
        }, dispatch)
    }
};

const ApplicationListPageContainer = connect(mapStateToProps, mapDispatchToProps)(ApplicationListPage);

export default ApplicationListPageContainer;