import {IRawLogoutTokenPayload} from './IRawTokenPayloads';

export default class LogoutTokenPayload {
    aud: string;
    exp: number;
    iat: number;
    sid: string | undefined;
    sidB2bUser: string | undefined;
    events: Map<string, any> | undefined;

    constructor(aud: string, exp: number, iat: number, sid: string | undefined, sidB2bUser: string | undefined, events: Map<string, any> | undefined) {
        this.aud = aud;
        this.exp = exp;
        this.iat = iat;
        this.sid = sid;
        this.sidB2bUser = sidB2bUser;
        this.events = events;
    }

    static create(logoutTokenPayload: any): LogoutTokenPayload | undefined {
        if (!logoutTokenPayload) {
            return undefined
        }

        return new LogoutTokenPayload(
            logoutTokenPayload.aud,
            logoutTokenPayload.exp,
            logoutTokenPayload.iat,
            logoutTokenPayload.sid,
            logoutTokenPayload['sid.b2bMu'],
            !!logoutTokenPayload.events ? new Map<string,any>(Object.entries(logoutTokenPayload.events)) : undefined
        );
    }

    static validate = (data: IRawLogoutTokenPayload): boolean => !!data && !!data.resource_access && !!data.sid && !!data['sid.b2bMu'] && !!data.events;
}
