import store from '../configureStore';
import { Role } from '../data/login/Role';
import { RefObject } from 'react';
import { ApplicationParamTypeName } from '../constants';
import ApplicationParamType from '../data/application/ApplicationParamType';
import { ISelectInputOption } from '../components/common/form/SelectInput';
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';

export const hasUserDesiredRole = (desiredRoles: Role[]): boolean => {
  const userRoles = store.getState().loginReducer.loginData;

  if (!desiredRoles || !userRoles) {
    return false;
  }
  return desiredRoles.some((r) => userRoles.accessTokenPayload?.resourceAccess?.b2bmu?.roles?.includes(r));
};

export const handleClickOutsideComponent = (e: any, refs: RefObject<any>[], callback: any) => {
  let isOutsideClick = true;
  refs.forEach((ref: RefObject<any>) => {
    if (!!ref.current && ref.current.contains(e.target)) {
      isOutsideClick = false;
    }
  });
  if (isOutsideClick) {
    callback();
  }
};

export const getApplicationParamTypeValue = (
  typeParams: ApplicationParamType[],
  name: ApplicationParamTypeName
): string | undefined => {
  const keyValue = typeParams?.find((typeParam) => typeParam.name === name);
  return !!keyValue ? keyValue.value : undefined;
};

export const getApplicationParamTypeOptions = (
  typeParams: ApplicationParamType[],
  name: ApplicationParamTypeName
): ISelectInputOption[] => {
  return typeParams
    .filter((typeParam) => typeParam.name === name || typeParam.name == ApplicationParamTypeName.DEFAULT_VALUE)
    .map((typeParam) => {
      return {
        value: typeParam.value,
        label: typeParam.value
      };
    });
};

const string2arrayBuffer = (s: any) => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const fileDownload = (dataArray: Array<any> | undefined, filename: any) => {
  if (!dataArray) {
    return undefined;
  }
  // get JSON from data
  const array = typeof dataArray !== 'object' ? JSON.parse(dataArray) : dataArray;

  // create work book
  let workBook = xlsx.utils.book_new();
  workBook.Props = {
    Title: 'B2B report',
    CreatedDate: new Date()
  };

  // create work sheet
  workBook.SheetNames.push('Test Sheet');
  let workSheet = xlsx.utils.json_to_sheet(array);
  workBook.Sheets['Test Sheet'] = workSheet;
  let wBout = xlsx.write(workBook, { bookType: 'xlsx', type: 'binary' });

  // download file
  saveAs(new Blob([string2arrayBuffer(wBout)], { type: 'application/octet-stream' }), filename);
};

export const openPdf = (bytesBase64: string) => {
  const link = document.createElement('a');
  link.download = 'Manual.pdf';
  link.target = '_blank';
  link.href = 'data:application/octet-stream;base64,' + bytesBase64;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};
