import {IMenuShape} from '../components/navbar/menu/Menu';
import {AppRoute} from '../router/appRoutes';
import {Role} from '../data/login/Role';
import i18next from 'i18next';

export const MenuItems: IMenuShape[] = [
    {
        order: 1,
        name: i18next.t('menu.party.title'),
        subMenuItems: [
            {
                order: 1.1,
                name: i18next.t('menu.party.subMenu.list.title'),
                path: AppRoute.PARTY_LIST,
            },
            {
                order: 1.2,
                name: i18next.t('menu.party.subMenu.create.title'),
                path: AppRoute.PARTY_CREATE,
            }
        ]
    },
    {
        order: 2,
        name: i18next.t('menu.operator.title'),
        allowedRoles: [Role.OPERATOR],
        subMenuItems: [
            {
                order: 2.1,
                name: i18next.t('menu.operator.subMenu.tenantList.title'),
                path: AppRoute.TENANT_LIST,
                allowedRoles: [Role.OPERATOR]
            },
            {
                order: 2.2,
                name: i18next.t('menu.operator.subMenu.tenantCreate.title'),
                path: AppRoute.TENANT_CREATE,
                allowedRoles: [Role.OPERATOR]
            },
            {
                order: 2.3,
                name: i18next.t('menu.operator.subMenu.appManagement.title'),
                path: AppRoute.APPLICATION,
                allowedRoles: [Role.OPERATOR]
            }
        ]
    }
];