import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import appReducer from './appReducer';
import loginReducer from './loginReducer'
import tenantReducer from './tenantReducer'
import partyReducer from './partyReducer'
import applicationReducer from './applicationReducer'
import IAppReducer from '../data/app/IAppReducer';
import ILoginReducer from '../data/login/ILoginReducer';
import ITenantReducer from '../data/tenant/ITenantReducer';
import IPartyReducer from '../data/party/IPartyReducer';
import IApplicationReducer from '../data/application/IApplicationReducer';

export interface IState {
    appReducer: IAppReducer
    loginReducer: ILoginReducer
    tenantReducer: ITenantReducer
    partyReducer: IPartyReducer
    applicationReducer: IApplicationReducer
}

const rootReducer = combineReducers({
    appReducer,
    loginReducer,
    tenantReducer,
    partyReducer,
    applicationReducer,
    form: formReducer
});

export default rootReducer;