import ApplicationParamType from '../application/ApplicationParamType';

export default class Party{
    id: string | undefined;
    email: string | undefined;
    firstName: string;
    lastName: string;
    note: string | undefined;
    attributes: ApplicationParamType[] | undefined;


    constructor(id: string | undefined, email: string | undefined, firstName: string, lastName: string, note: string | undefined, attributes: ApplicationParamType[] | undefined) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.note = note;
        this.attributes = attributes;
    }

    static create(party: any): Party | undefined{
        if (!party) {
            return undefined;
        }

        return new Party(
            party?.id,
            party?.email,
            party?.firstName,
            party?.lastName,
            party?.note,
            party?.attributes?.map((attr: ApplicationParamType) => ApplicationParamType.create(attr))
        );
    }
}