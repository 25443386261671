import {connect} from 'react-redux';
import ErrorPage from '../pages/error/ErrorPage';
import {IState} from '../../reducers/indexReducer';

const mapStateToProps = (state: IState) => {
    return {
        appError: state.appReducer.appError
    }
};

const ErrorPageContainer = connect(mapStateToProps, null)(ErrorPage);

export default ErrorPageContainer;