import {Client} from '@stomp/stompjs';
import IdentityTokenPayload from '../data/login/IdentityTokenPayload';
import LogoutTokenPayload from '../data/login/LogoutTokenPayload';
import {parseJWT, redirectToURL} from './restUtils';
import {WebSocketEvent} from '../constants';
import conf from '../configuration/conf';
import LoginData from '../data/login/LoginData';

export const createSSOWebSocketConnection = (identityTokenPayload: IdentityTokenPayload, loginData: LoginData) => {
    const client = new Client({
        brokerURL: conf.ssoWebSocketURL
    });

    client.onConnect = (frame) => {
        if (!!identityTokenPayload.sid) {
            client.publish({destination: `/app/register/${identityTokenPayload.sid}`});
        }
        if (!!identityTokenPayload.sidB2bUser) {
            client.publish({destination: `/app/register/${identityTokenPayload.sidB2bUser}`});
        }

        client.subscribe('/user/queue/logout', (msg) => {
            const logoutTokenPayload = LogoutTokenPayload.create(parseJWT(JSON.parse(msg.body)?.token));
            if (!!logoutTokenPayload && !!logoutTokenPayload?.events?.get(WebSocketEvent.LOGOUT) &&  logoutTokenPayload?.sid === loginData.identityTokenPayload?.sid) {
                redirectToURL(conf.appURL);
            }
        });
    };

    client.onStompError = function (frame) {
        console.log('SSO stomp ws broker reported error: ' + frame.headers['message']);
        console.log('SSO stomp ws additional details: ' + frame.body);
    };

    client.activate();
};