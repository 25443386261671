import React, { useEffect, useRef, useState } from 'react';
import ApplicationResponse from '../../../data/response/ApplicationResponse';
import Tenant from '../../../data/tenant/Tenant';
import history from '../../../router/history';
import { AppRoute } from '../../../router/appRoutes';
import Card from '../../common/other/Card';
import i18next from 'i18next';
import store from '../../../configureStore';
import { destroy, initialize } from 'redux-form';
import ApplicationDetailFormComponent from './ApplicationDetailFormComponent';
import ApplicationDetailPartyTable from './ApplicationDetailPartyTable';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';
import { ModalType } from '../../../constants';
import ModalContainer from '../../containers/ModalContainer';
import ModalAssignApplicationToParty from '../../modal/ModalAssignApplicationToParty';
import Party from '../../../data/party/Party';
import ModalApplicationPartyBindingDetail from '../../modal/ModalApplicationPartyBindingDetail';
import ApplicationParamType from '../../../data/application/ApplicationParamType';
import ModalApplicationPartyBindingDelete from '../../modal/ModalApplicationPartyBindingDelete';
import PartyListFilterRequest from '../../../data/request/PartyListFilterRequest';
import { CheckboxWithLabel } from '../../common/form/InputTypes';

const ApplicationDetailPage = ({
  modalType,
  modalPartyId,
  selectedTenant,
  applicationDetail,
  partyList,
  assignApplicationToPartyFormValues,
  applicationPartyBindingDetail,
  total,
  filter,
  actions: {
    setModalType,
    setModalPartyId,
    getApplicationDetail,
    resetApplicationDetail,
    assignAppToParty,
    getApplicationPartyBindingDetail,
    resetApplicationPartyBindingDetail,
    updateApplicationPartyBinding,
    deleteApplicationPartyBinding,
    importPartiesForApplication,
    changeInput,
    resetApplicationFilter,
    exportPartiesForApplication
  },
  match: {
    params: { applicationId }
  }
}: IApplicationDetailPageProps) => {
  const didMountRef: any = useRef(false);
  const [selectedPartyId, setSelectedPartyId] = useState<any>();

  useEffect(() => {
    return () => {
      resetApplicationFilter();
    };
  }, []);

  useEffect(() => {
    if (!!selectedTenant) {
      if (!didMountRef.current) {
        didMountRef.current = true;
      } else {
        history.push(AppRoute.INDEX);
      }
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (!!selectedTenant) {
      getApplicationDetail(applicationId);
    }

    return () => {
      if (!history.location.pathname.startsWith(AppRoute.APPLICATION + '/')) {
        resetApplicationDetail();
        store.dispatch(destroy('applicationDetail'));
      }
    };
  }, [selectedTenant, applicationId, getApplicationDetail, resetApplicationDetail]);

  useEffect(() => {
    if (!!applicationDetail) {
      store.dispatch(
        initialize('applicationDetail', {
          id: applicationDetail?.application?.id,
          guiName: applicationDetail?.application?.guiName,
          originalName: applicationDetail?.application?.originalName,
          description: applicationDetail?.application?.description
        })
      );
    }
  }, [applicationDetail]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    changeInput(name, e.target.checked);
    getApplicationDetail(applicationId);
  };

  if (!applicationDetail) {
    return <div />;
  }

  // @ts-ignore
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <Card title={i18next.t('pages.application.detail.title')}>
            <div className="row">
              <div className="col-12">
                <ApplicationDetailFormComponent
                  applicationDetail={applicationDetail}
                  applicationId={applicationId}
                  actions={{
                    setModalType,
                    importPartiesForApplication,
                    getApplicationDetail,
                    exportPartiesForApplication
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            title={
              filter.assigned
                ? i18next.t('pages.application.detail.assignedPartyTitle')
                : i18next.t('pages.application.detail.unassignedPartyTitle')
            }
          >
            <CheckboxWithLabel
              name={'assigned'}
              label={i18next.t('pages.application.detail.showAssigned')}
              input={{
                defaultChecked: filter.assigned,
                onClick: (e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e, 'assigned')
              }}
            />
            {!!applicationDetail?.parties &&
            applicationDetail?.parties.content.length === 0 &&
            filter.email === undefined &&
            filter.firstName === undefined &&
            filter.lastName === undefined ? (
              <p>
                {filter.assigned
                  ? i18next.t('pages.application.detail.noAssignedParties')
                  : i18next.t('pages.application.detail.noUnassignedParties')}
              </p>
            ) : (
              <ApplicationDetailPartyTable
                applicationDetail={applicationDetail}
                actions={{
                  setModalType,
                  setModalPartyId,
                  setSelectedPartyId,
                  getApplicationDetail,
                  changeInput
                }}
                total={total}
                filter={filter}
                applicationId={applicationId}
              />
            )}
          </Card>
        </div>
      </div>
      <CSSTransitionHOC renderOn={modalType === ModalType.ASSIGN_APPLICATION_TO_PARTY}>
        <ModalContainer modalContentClassName="modal-overflow--visible">
          <ModalAssignApplicationToParty
            partyList={partyList}
            assignApplicationToPartyFormValues={assignApplicationToPartyFormValues}
            applicationDetail={applicationDetail}
            actions={{
              assignAppToParty,
              getApplicationDetail,
              setModalPartyId
            }}
            partyId={modalPartyId}
          />
        </ModalContainer>
      </CSSTransitionHOC>
      <CSSTransitionHOC renderOn={modalType === ModalType.APPLICATION_PARTY_DETAIL}>
        <ModalContainer modalContentClassName="modal-overflow--visible">
          <ModalApplicationPartyBindingDetail
            assignApplicationToPartyFormValues={assignApplicationToPartyFormValues}
            applicationDetail={applicationDetail}
            selectedPartyId={selectedPartyId}
            applicationPartyBindingDetail={applicationPartyBindingDetail}
            actions={{
              getApplicationPartyBindingDetail,
              resetApplicationPartyBindingDetail,
              updateApplicationPartyBinding
            }}
          />
        </ModalContainer>
      </CSSTransitionHOC>
      <CSSTransitionHOC renderOn={modalType === ModalType.DELETE_APPLICATION_PARTY_BINDING}>
        <ModalContainer>
          <ModalApplicationPartyBindingDelete
            selectedPartyId={selectedPartyId}
            applicationId={applicationId}
            actions={{ deleteApplicationPartyBinding, getApplicationDetail }}
          />
        </ModalContainer>
      </CSSTransitionHOC>
    </div>
  );
};

export default ApplicationDetailPage;

/*PROPS*/

export interface IApplicationDetailPageProps {
  selectedTenant: Tenant | undefined;
  modalType: ModalType;
  modalPartyId: string | undefined;
  applicationDetail: ApplicationResponse | undefined;
  partyList: Party[] | undefined;
  assignApplicationToPartyFormValues: any;
  applicationPartyBindingDetail: ApplicationParamType[] | undefined;
  total: number | undefined;
  filter: PartyListFilterRequest;
  actions: {
    setModalType: any;
    setModalPartyId: any;
    getApplicationDetail: any;
    resetApplicationDetail: any;
    assignAppToParty: any;
    getApplicationPartyBindingDetail: any;
    resetApplicationPartyBindingDetail: any;
    updateApplicationPartyBinding: any;
    deleteApplicationPartyBinding: any;
    importPartiesForApplication: any;
    changeInput: any;
    resetApplicationFilter: any;
    exportPartiesForApplication: any;
  };
  match: {
    params: {
      applicationId: string;
    };
  };
}
