import React from "react";
import {CSSTransition} from "react-transition-group";
import './CSSTransitionAnimation.scss'

const CSSTransitionHOC = (
    {
        children,
        renderOn,
        timeout= 150,
        onExit,
        clazzNames = 'modal'
    }: ICSSTransitionHOCProps
) => {

    return (
        <CSSTransition in={renderOn}
                       timeout={timeout}
                       classNames={clazzNames}
                       onExit={onExit}
                       unmountOnExit
                       mountOnEnter>
            {children}
        </CSSTransition>
    )
};

export default CSSTransitionHOC;

/*PROPS*/

interface ICSSTransitionHOCProps {
    children: any
    renderOn: boolean
    timeout?: number
    onExit?: any
    clazzNames?: 'modal' | 'toggle' | 'router' | 'test' | 'spinner'

}