import React, {ReactNode} from 'react';
import './card.scss'

const Card = (
    {
        children,
        title
    }: ICardProps
) => {

    return (
        <div className="card h-100">
            <div className="card-body">
                {!!title &&
                    <h5 className="card-title">
                        {title}
                    </h5>
                }
                {children}
            </div>
        </div>
    )
};

export default Card;

/*PROPS*/

interface ICardProps {
    children: ReactNode
    title?: string
}