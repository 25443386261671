import React, {useEffect, useRef, useState} from 'react';
import {initialize } from 'redux-form';
import i18next from 'i18next';
import Tenant from '../../../data/tenant/Tenant';
import GetPartyDetailResponse from '../../../data/response/GetPartyDetailResponse';
import {AppRoute} from '../../../router/appRoutes';
import history from '../../../router/history';
import CardFormWrapper from '../../common/other/CardFormWrapper';
import PartyFormComponent from './PartyFormComponent';
import {FormOperation} from '../../../constants';
import store from '../../../configureStore';
import {ButtonPrimary, ButtonSecondary} from '../../common/button/buttonTypes';
import {toast} from 'react-toastify';
import ToastBody from '../../common/other/ToastBody';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';

const PartyDetailPage = (
    {
        handleSubmit,
        selectedTenant,
        partyDetail,
        match: {
            params: {
                partyId
            }
        },
        actions: {
            getPartyDetail,
            resetPartyState,
            updateParty
        }
    }: IPartyDetialPageProps
) => {
    const didMountRef: any = useRef(false);
    const [isUpdatable, setUpdatable] = useState(false);

    useEffect(() => {
        if (!!selectedTenant) {
            if (!didMountRef.current) {
                getPartyDetail(partyId);
                didMountRef.current = true;
            } else {
                history.push(AppRoute.PARTY_LIST);
            }
        }

        return () => {
            resetPartyState();
        }
    },[partyId, selectedTenant, getPartyDetail, resetPartyState]);

    useEffect(() => {
        if (!!partyDetail) {
            store.dispatch(initialize('partyDetail', {
                id: partyDetail?.party?.id,
                email: partyDetail?.party?.email,
                firstName: partyDetail?.party?.firstName,
                lastName: partyDetail?.party?.lastName,
                note: partyDetail?.party?.note
            }))
        }
    }, [partyDetail]);

    const onFormSubmit = () => {
        if (isUpdatable) {
            updateParty().then(() => {
                toast.success(<ToastBody message={i18next.t('messages.success.updateParty')} />)
                history.push(AppRoute.PARTY_LIST);
            });
        }
    };

    const handleEditClick = () => {
        if (!isUpdatable) {
            setUpdatable(true);
        }
    };

    if (!selectedTenant || !partyDetail) {
        return <div/>
    }

    return (
        <CardFormWrapper>
            <PartyFormComponent onFormSubmit={handleSubmit(() => onFormSubmit())}
                                readonly={!isUpdatable}
                                label={i18next.t('pages.party.detail.label')}
                                buttonLabel={i18next.t('common.buttons.edit')}
                                formOperation={FormOperation.UPDATE}>
                <div className="position-relative">
                    <CSSTransitionHOC renderOn={isUpdatable}
                                      timeout={300}
                                      clazzNames="toggle">
                            <ButtonPrimary>
                                {i18next.t('common.buttons.save')}
                            </ButtonPrimary>
                    </CSSTransitionHOC>
                    <CSSTransitionHOC renderOn={!isUpdatable}
                                      timeout={300}
                                      clazzNames="toggle">
                            <ButtonSecondary type={'button'}
                                             onClick={handleEditClick}>
                                {i18next.t('common.buttons.edit')}
                            </ButtonSecondary>
                    </CSSTransitionHOC>
                </div>

            </PartyFormComponent>
        </CardFormWrapper>
    )
};

export default PartyDetailPage;

/*PROPS*/

export interface IPartyDetialPageProps {
    handleSubmit: any
    selectedTenant: Tenant | undefined
    partyDetail: GetPartyDetailResponse | undefined
    match: {
        params: {
            partyId: string
        }
    }
    actions: {
        getPartyDetail: any
        resetPartyState: any
        updateParty: any
    }
}