import AppConfig from './app';

export interface IConf {
    loginDataURL: string
    oauthRedirectURL: string
    backendURL: string
    logoutURL: string
    silentRefreshSeconds: number
    idleSeconds: number
    ssoWebSocketURL: string
    appURL: string
}

let conf: IConf;

conf = AppConfig;


export default conf
