export const customStyles = (hasError: boolean = false) => {
    return ({
        container: (base: any, state: any) => ({
            ...base,
            width: '100%'
        }),
        control: (base: any, state: any) => ({
            ...base,
            border:  state.menuIsOpen || state.isFocused ? '2px solid #000000 !important' : hasError ? '2px solid #dc3545 !important' : '2px solid #a69ba3' ,
            backgroundColor: 'inherit',
            boxShadow: 'none',
            fontFamily: 'HelvNeueOrange75, HelvNeueOrange55, sans-serif',
            borderRadius: 0,
            '&:hover': {
                border: state.menuIsOpen || state.isFocused? '2px solid #000000 !important' : '2px solid #a69ba3',
                cursor: 'pointer'
            },
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: 'inherit'
        }),
        placeholder: (base: any, state: any) => ({
            ...base,
            color: 'inherit',
            opacity: .65
        }),
        dropdownIndicator: (base: any, state: any) => ({
            ...base,
            color: 'inherit',
            transition: 'transform .2s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        }),
        clearIndicator: (base: any, state: any) => ({
            ...base,
            color: 'inherit',
            '&:hover': {
                color: '#000000',
                cursor: 'pointer'
            },
        }),
        menu: (base: any, state: any) => ({
            ...base,
            borderRadius: 0
        }),
        option: (base: any, state: any) => ({
            ...base,
            color: state.isSelected ? '#000000' :'inherit',
            fontFamily: state.isSelected ? 'HelvNeueOrange75, HelvNeueOrange55, sans-serif' : 'HelvNeueOrange55, sans-serif',
            backgroundColor: 'inherit !important',
            '&:hover': {
                fontFamily: 'HelvNeueOrange75, HelvNeueOrange55, sans-serif',
                cursor: 'pointer',
                color: '#000000'
            }
        }),
        multiValue: (base: any, state: any) => ({
            ...base,
            backgroundColor: 'inherit'
        }),
        multiValueLabel: (base: any, state: any) => ({
            ...base,
        }),
        multiValueRemove: (base: any, state: any) => ({
            ...base,
            backgroundColor: 'inherit !important',
            '&:hover': {
                color: '#000000'
            },
        }),
    })
};