import ResourceAccess from './ResourceAccess';
import TokenPayload from './TokenPayload';
import { IRawIdentityTokenPayload } from './IRawTokenPayloads';

export default class IdentityTokenPayload extends TokenPayload{
    sub: string | undefined;
    sid: string | undefined;
    sidB2bUser: string | undefined;
    tenant: string[] | undefined;

    constructor(aud: string, exp: number, iat: number, idf: string, iss: string, jti: string, preferredUsername: string, resourceAccess: ResourceAccess | undefined, sub: string | undefined, subB2bUser: string, subAccount: string | undefined, sid: string | undefined, sidB2bUser: string | undefined, tenant: string[] | undefined) {
        super(aud, exp, iat, idf, iss, jti, preferredUsername, resourceAccess, subB2bUser, subAccount);

        this.sub = sub;
        this.sid = sid;
        this.sidB2bUser = sidB2bUser;
        this.tenant = tenant;
    }

    static create(identityTokenPayload: any): IdentityTokenPayload | undefined {
        if (!identityTokenPayload) {
            return undefined
        }

        return new IdentityTokenPayload(
            identityTokenPayload.aud,
            identityTokenPayload.exp,
            identityTokenPayload.iat,
            identityTokenPayload.idf,
            identityTokenPayload.iss,
            identityTokenPayload.jti,
            identityTokenPayload['preferred_username'],
            ResourceAccess.create(identityTokenPayload['resource_access']),
            identityTokenPayload['sub'],
            identityTokenPayload['sub.account'],
            identityTokenPayload['sub.b2bMu'],
            identityTokenPayload['sid'],
            identityTokenPayload['sid.b2bMu'],
            identityTokenPayload['tenant']
        );
    }

    static validate = (data: IRawIdentityTokenPayload): boolean => !!data && !!data.resource_access && !!data['sub.b2bMu'] && !!data.sid && !!data['sid.b2bMu']
}
