import React, {RefObject} from "react";

const FileUpload = (
    {
        inputRef,
        input,
        afterChangeAction
    }: IFileUploadProps
) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!!e.target.files && e.target.files.length > 0) {
            input.onChange(e.target.files[0]);
            if (!!afterChangeAction) {
                afterChangeAction();
            }
        }
    };

    const handleClick = (e: any) => {
        e.target.value = null
    };

    return (
        <input type="file"
               onChange={handleChange}
               onClick={handleClick}
               accept=".xlsx"
               ref={inputRef}
               hidden />
    )
};

export default FileUpload;

/*PROPS*/

export interface IFileUploadProps {
    inputRef: RefObject<HTMLInputElement>
    afterChangeAction?: any
    input?: any
    error?: string
    meta?: {
        touched?: boolean
        error?: string
    }
}