import React, {ReactFragment, useEffect, useState} from 'react';
import './pagination.scss';
import * as constants from '../../../constants';
import PartyListFilterRequest from "../../../data/request/PartyListFilterRequest";

const Pagination = (
  {
    label,
    total,
    filter,
    actions: {
      getParty
    }
  }: IPaginationProps
) => {

  // local state
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [disabledPrev, setDisabledPrev] = useState<boolean>(false);
  const [disabledNext, setDisabledNext] = useState<boolean>(false);

  const numberOfPages: number = total ? Math.ceil(total / constants.TENANT_PAGE_SIZE) : 1;

  useEffect(() => {
    setSelectedPage(1);
  }, [filter]);

  useEffect(() => {
    if (selectedPage === 1) setDisabledPrev(true);
    else setDisabledPrev(false);

    if (selectedPage === numberOfPages) setDisabledNext(true);
    else setDisabledNext(false);
  });

  const setPage = (page: number): void => {
    setSelectedPage(page);
    getParty(page);
  }

  const prevPage = (): void => {
    if (selectedPage > 1) setPage(selectedPage - 1);
  }

  const nextPage = (): void => {
    if (selectedPage < numberOfPages) setPage(selectedPage + 1);
  }

  const pages = (numberOfPages: number) => {
    let pages: ReactFragment[] = [];

    for (let i: number = 0; i < numberOfPages; ++i) {
      pages.push(
        <li className={ selectedPage === i + 1 ? 'page-item active' : 'page-item' } key={ i + 1 }>
          <a className="page-link" href="#" onClick={ () => setPage(i + 1) }>{ i + 1 }</a>
        </li>
      );
    };
    return pages;
  }

  return (
    <nav aria-label={ label }>
      <ul className="pagination">
        <li className={ disabledPrev ? 'page-item disabled' : 'page-item' }>
          <a className="page-link" href="#" aria-label="Previous" onClick={ () => prevPage() }>&laquo;</a>
        </li>
        { pages(numberOfPages) }
        <li className={ disabledNext ? 'page-item disabled' : 'page-item' }>
          <a className="page-link" href="#" aria-label="Next" onClick={ () => nextPage() }>&raquo;</a>
        </li>
      </ul>
    </nav>
  )
};

export default Pagination;

// PROPS
export interface IPaginationProps {
  label?: string,
  total: number | undefined,
  filter: PartyListFilterRequest
  actions: {
    getParty: any
  }
}