import React, { ReactNode } from 'react';
import './table.scss'
import Input, { InputType } from "../form/Input";
import ITableHeader from "../../../data/common/ITableHeader";

const Table = (
  {
    children,
    headers,
    emptyHeader,
    onInputChange
  }: ITableProps
) => {

  const arrayOfHeaders = Array.isArray(headers);

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            { emptyHeader?.includes("before") && <th scope="col" /> }

            { arrayOfHeaders
              ? headers.map((header: ITableHeader) => (
              <th scope="col" key={ header.dataIndex } className="header-center">
                { header.filterable
                  ? <Input type={ InputType.TEXT }
                           placeholder={ header.title }
                           onChange={ onInputChange }
                           name={ header.dataIndex } />
                  : header.title
                }
              </th> ))
              : (Object.values(headers).map((header: any) => (
                  <th scope="col" key={ header }>{ header }</th>)
              ))
            }

            { emptyHeader?.includes("after") && <th scope="col" /> }
          </tr>
        </thead>
        <tbody>
          { children }
        </tbody>
      </table>
    </div>
  )
};

export default Table;

/*PROPS*/
interface ITableProps {
    children: ReactNode
    headers: ITableHeader[] | any
    emptyHeader?: ("before" | "after")[],
    onInputChange?: any
}