import React from 'react';
import cx from 'classnames';

import './form.scss'

const Textarea = (
    {
        readOnly= false,
        className,
        hidden,
        input,
        error,
        meta
    }: ITextareaProps
) => {

    const classes = cx(
        'form-control',
        'textarea',
        {
            'is-invalid': !!error || (meta && meta.touched && meta.error)
        },
        className
    );

    return (
        <textarea className={classes}
                  hidden={hidden}
                  readOnly={readOnly}
                  {...input}/>
    )
};

export default Textarea

/*PROPS*/

export interface ITextareaProps {
    hidden?: boolean
    readOnly?: boolean,
    className?: string,
    input?: any,
    error?: string,
    meta?: {
        touched?: boolean,
        error?: string
    }
}