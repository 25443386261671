import React, {ReactNode} from 'react';
import cx from 'classnames'

import './form.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const LabelWrapperForInput = (
    {
        label,
        children,
        meta,
        readOnly,
        appendIcon = false,
        hidden = false
    }: ILabelWrapperForInputProps
) => {

    const wrapperClasses = cx(
        'form-group'
    );

    return (
        <div className={wrapperClasses} hidden={hidden}>
            {!!label && <label className={'is-invalid'}>{label}</label>}
            <div className="input-group">
                {children}
                {!readOnly && appendIcon &&
                <div className="input-group-append">
                    <span className="input-group-text">
                        <FontAwesomeIcon className="ml-2" icon={"pen"} />
                    </span>
                </div>
                }
            </div>

            {meta && meta.touched && meta.error &&
            <div className={'invalid-feedback'}>
                {meta.error}
            </div>
            }
        </div>
    )
};

export default LabelWrapperForInput;

/*PROPS*/ 

export interface ILabelWrapperForInputProps {
    label?: any
    children?: ReactNode
    readOnly?: boolean
    appendIcon?: boolean
    hidden?: boolean
    meta?: {
        touched?: boolean
        error?: string
    }
}