import React from 'react';
import i18next from 'i18next';
import { ButtonPrimary, ButtonSecondary } from '../common/button/buttonTypes';
import { ModalType } from '../../constants';
import { tenantForm } from '../containers/form/TenantForm';
import { InputWithLabel } from '../common/form/InputTypes';
import { email, required } from '../../utils/formValidators';
import { InputType } from '../common/form/Input';
import { toast } from 'react-toastify';
import ToastBody from '../common/other/ToastBody';

const ModalCreateTenant = (
    {
        setModalType,
        modalType,
        handleSubmit,
        actions: {
            createTenant
        }
    }: IModalCreateTenantProps
) => {

    const handleCreateClick = ():void => {
        createTenant().then(() => {
            toast.success(<ToastBody message={ i18next.t('messages.success.createTenant') } />);
            setModalType(modalType === ModalType.CREATE_TENANT_REQUIRED ? ModalType.SELECT_TENANT : ModalType.RESELECT_TENANT);
        });
    };

    const onSelectTenantClick = (): void => {
        setModalType(modalType === ModalType.CREATE_TENANT_REQUIRED ? ModalType.SELECT_TENANT : ModalType.RESELECT_TENANT);
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{ i18next.t('modal.createTenant.title') }</h5>
            </div>
            <form onSubmit={ handleSubmit(() => handleCreateClick()) }>
                <div className="modal-body mt-1">
                    <div className="row">
                        <div className="col-12">
                            <InputWithLabel name={'email'}
                                            label={ i18next.t('pages.tenant.form.email') }
                                            validate={[ required, email ]}
                                            type={ InputType.MAIL }
                                            appendIcon />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputWithLabel name={'name'}
                                            label={ i18next.t('pages.tenant.form.name') }
                                            validate={[ required ]}
                                            appendIcon />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputWithLabel name={'externalId'}
                                            label={ i18next.t('pages.tenant.form.externalId') }
                                            validate={[ required ]}
                                            appendIcon
                                            maxlength={ 10 } />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        <div className="col-auto pr-2">
                            <ButtonSecondary isBlock type="button"
                                             onClick={ onSelectTenantClick }>
                                { i18next.t('modal.createTenant.selectTenantBtn') }
                            </ButtonSecondary>
                        </div>
                        <div className="col-auto pl-1">
                            <ButtonPrimary isBlock>
                                { i18next.t('common.buttons.create') }
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default tenantForm<IModalCreateTenantProps>(ModalCreateTenant);

/*PROPS*/

export interface IModalCreateTenantProps {
    setModalType?: any
    modalType?: ModalType
    handleSubmit?: any
    actions: {
        createTenant: any
    }
}