import ApplicationParamType from './ApplicationParamType';
import {CrmParamType} from './CrmParamType';

export default class ApplicationParam {
    guiName: string | undefined;
    ldapName: string;
    type: CrmParamType;
    regexp: string | undefined;
    isMandatory: boolean;
    isLicenced: boolean;
    isMultioccurence: boolean;
    typeParams: ApplicationParamType[];

    constructor(guiName: string | undefined, ldapName: string, type: CrmParamType, regexp: string | undefined, isMandatory: boolean, isLicenced: boolean, isMultioccurence: boolean, typeParams: ApplicationParamType[]) {
        this.guiName = guiName;
        this.ldapName = ldapName;
        this.type = type;
        this.regexp = regexp;
        this.isMandatory = isMandatory;
        this.isLicenced = isLicenced;
        this.isMultioccurence = isMultioccurence;
        this.typeParams = typeParams;
    }

    static create(applicationParam: any): ApplicationParam | undefined {
        if (!applicationParam) {
            return undefined;
        }

        return new ApplicationParam(
            applicationParam?.guiName,
            applicationParam?.ldapName,
            applicationParam?.type,
            applicationParam?.regexp,
            applicationParam?.isMandatory,
            applicationParam?.isLicenced,
            applicationParam?.isMultioccurence,
            applicationParam?.typeParams?.map((typeParam: ApplicationParamType) => ApplicationParamType.create(typeParam))
        );
    }
}