import {parseJWT} from '../../utils/restUtils';
import IRawLoginData from './IRawLoginData';
import ResourceAccess from './ResourceAccess';
import AccessTokenPayload from './AccessTokenPayload';
import IdentityTokenPayload from './IdentityTokenPayload';
import {IRawAccessTokenPayload} from './IRawTokenPayloads';

export default class LoginData {
    accessToken: string;
    idToken: string;
    expiresIn: number;
    accessTokenPayload: AccessTokenPayload | undefined;
    identityTokenPayload: IdentityTokenPayload | undefined;

    constructor({ access_token, id_token, expires_in }: IRawLoginData) {
        this.accessToken = access_token;
        this.idToken = id_token;
        this.expiresIn = expires_in;
        this.accessTokenPayload = AccessTokenPayload.create(parseJWT(access_token));
        this.identityTokenPayload = IdentityTokenPayload.create(parseJWT(id_token));
    }

    static validate = (data: IRawLoginData): boolean => !!data && !!data.access_token && !!data.id_token && !!data.expires_in;

    static validateAccessToken = (data: IRawLoginData): boolean => {
        if (LoginData.validate(data)) {
            const parsedAccessToken: IRawAccessTokenPayload = parseJWT(data.access_token);
            return AccessTokenPayload.validate(parsedAccessToken) && ResourceAccess.validate(parsedAccessToken.resource_access);
        } else {
            return false
        }
    }
}
