import React, { useEffect, useState } from 'react';
import { autofill, initialize } from 'redux-form';
import ReactTooltip from 'react-tooltip';

import '../../common/tooltip/tooltip.scss'
import './applicationConfigurableField.scss'

import ApplicationParam from '../../../data/application/ApplicationParam';
import { CrmParamType } from '../../../data/application/CrmParamType';
import { InputWithLabel, SelectInputWithLabel } from '../../common/form/InputTypes';
import store from '../../../configureStore';
import { ApplicationParamTypeName, FormOperation } from '../../../constants';
import { getApplicationParamTypeOptions, getApplicationParamTypeValue } from '../../../utils/utils';
import { regexCustomMsg, requiredCustomMsg } from '../../../utils/formValidators';
import { ButtonPrimary, ButtonSecondary } from '../../common/button/buttonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISelectInputOption } from '../../common/form/SelectInput';

const ApplicationConfigurableField = (
    {
        inputParameter,
        assignApplicationToPartyFormValues,
        formOperation,
        values,
    }: IApplicationConfigurableFieldProps
) => {

    const getInputs = () => {
        //dynamically created inputs by number of occurrence
        return Array.from({ length: additionalInputsCount + 1 }, (v,i) => `${ inputParameter.ldapName }::${ i }`)
    };

    const [validation, setValidation] = useState<any>();
    //dynamically added total input count for possible multioccurrence
    const [additionalInputsCount, setAdditionalInputsCount] = useState(!!values && Object.keys(values).length > 0 ? Object.keys(values).length - 1 : 0);
    const [inputs, setInputs] = useState(getInputs());

    useEffect(() => {
        let validators: Function[] = [];
        if (inputParameter.isMandatory) {
            validators.push(requiredCustomMsg(getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.ERROR_MSG)))
        }
        if (!!inputParameter.regexp) {
            validators.push(regexCustomMsg(inputParameter.regexp, getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.ERROR_MSG)))
        }
        setValidation(validators);
    }, [inputParameter]);

    const handleAddInputClick = (e?: React.MouseEvent<HTMLElement>) => {
        e?.preventDefault();
        if (inputParameter.isMultioccurence) {
            setInputs(
                [
                    ...inputs,
                    `${inputParameter.ldapName}::${ additionalInputsCount + 1 }`
                ]
            );
            // If param has default value an is multioccurence you have to reinit whole form because you are not able to init only new value
            const defaultValue = getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.DEFAULT_VALUE);
            if (!!defaultValue) {
                store.dispatch(initialize('assignApplicationToPartyForm', {
                    ...assignApplicationToPartyFormValues,
                    [`${ inputParameter.ldapName }::${ additionalInputsCount + 1 }`]: defaultValue
                }));
            }
            setAdditionalInputsCount(additionalInputsCount + 1);
        }
    };

    const handleRemoveInputClick = (name: string, e?: React.MouseEvent<HTMLElement>) => {
        e?.preventDefault();
        if (inputParameter.isMultioccurence && inputs.length > 1) {
            const input = inputs.find((input) => input === name);
            setInputs(inputs.filter((input) => input !== name));
            if (!!input) {
                // remove field from redux
                store.dispatch(autofill('assignApplicationToPartyForm', input, undefined));
            }
        }
    };

    const getDefaultValue = (name: string): ISelectInputOption | undefined => {
        //dynamic form init which depends on form operation and default value in param type params
        const defaultValue = getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.DEFAULT_VALUE);
        if (formOperation === FormOperation.CREATE && !!defaultValue) {
            return { value: defaultValue, label: defaultValue }
        } else if (formOperation !== FormOperation.CREATE && !!values && !!values[name]) {
            return { value: values[name], label: values[name] }
        } else if (formOperation === FormOperation.UPDATE && !!defaultValue) {
            return { value: defaultValue, label: defaultValue }
        } else {
            return undefined;
        }
    };

    return (
        <React.Fragment>
            { inputs.map((name, index) => {
                return (
                    <div className="row" key={ name }>
                        <div className={ inputParameter.isMultioccurence ? "col" : "col-12" }>
                            <ReactTooltip effect="solid"
                                          type="light"
                                          id={ name }
                                          className="tooltip--input">
                                <p>{ getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.TOOL_TIP) }</p>
                            </ReactTooltip>
                            { inputParameter.type === CrmParamType.SELECT ?
                                <SelectInputWithLabel name={ name }
                                                      label={ index === 0 ? inputParameter.guiName : undefined }
                                                      options={ getApplicationParamTypeOptions(inputParameter.typeParams, ApplicationParamTypeName.DROP_DOWN_OPTION) }
                                                      placeholder={ getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.PLACEHOLDER) }
                                                      defaultValue={ getDefaultValue(name) }
                                                      validate={ validation }
                                                      isDisabled={ formOperation === FormOperation.READ }
                                                      tooltipId={ !!getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.TOOL_TIP) ? name : undefined }/>
                                :
                                <InputWithLabel name={ name }
                                                label={ index === 0 ? inputParameter.guiName : undefined }
                                                appendIcon={ formOperation !== FormOperation.READ }
                                                hidden={ inputParameter.type === CrmParamType.HIDDEN }
                                                placeholder={ getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.PLACEHOLDER) }
                                                validate={ validation }
                                                readOnly={ formOperation === FormOperation.READ }
                                                tooltipId={ !!getApplicationParamTypeValue(inputParameter.typeParams, ApplicationParamTypeName.TOOL_TIP) ? name : undefined }/>
                            }
                        </div>
                        { inputParameter.isMultioccurence && inputParameter.type !== CrmParamType.HIDDEN && formOperation !== FormOperation.READ &&
                        <div className="col-auto btn-multiccurence--group">
                            { index === 0 ?
                                <ButtonPrimary type="button"
                                               className="btn-multiccurence--item"
                                               onClick={ handleAddInputClick }>
                                    <FontAwesomeIcon icon={"plus"} />
                                </ButtonPrimary>
                                :
                                <ButtonSecondary type="button"
                                                 className="btn-multiccurence--item"
                                                 onClick={ (e: React.MouseEvent<HTMLElement>) => handleRemoveInputClick(name,e) }>
                                    <FontAwesomeIcon icon={"minus"} />
                                </ButtonSecondary>
                            }
                        </div>
                        }
                    </div>
                )
            })}
        </React.Fragment>
    )
};

export default ApplicationConfigurableField;

/*Props*/

export interface IApplicationConfigurableFieldProps {
    inputParameter: ApplicationParam
    assignApplicationToPartyFormValues: any
    formOperation: FormOperation
    values?: any
}