import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './sidebar.scss'
import Menu from './menu/Menu';
import ApplicationResponse from '../../data/response/ApplicationResponse';

const Sidebar = (
  {
    isSideMenuVisible,
    setIsSideMenuVisible,
    applicationsAssignedToTenant,
    actions: {
      setModalType,
      openApplicationManual
    }
  }: ISidebarProps
) => {

  const onSideBarCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsSideMenuVisible(false);
  };

  return (
    <nav id="sidebar" className={`sidebar ${!isSideMenuVisible ? 'hidden' : ''}`}>
      <div className="sidebar-header">
        <a href="https://www.orange.sk" className="navbar-brand">
          <img alt={'Orange'} src={'/image/orange-logo50.svg'}/>
        </a>
        <a href="/#" className="sidebar-close float-right" onClick={onSideBarCloseClick}>
          <FontAwesomeIcon icon={"times"} size={"lg"}/>
        </a>
      </div>
      <Menu applicationsAssignedToTenant={ applicationsAssignedToTenant}
            actions={{ setModalType, openApplicationManual }} />
    </nav>
  )
};

export default Sidebar;

/*PROPS*/

export interface ISidebarProps {
  isSideMenuVisible: boolean
  setIsSideMenuVisible: Function
  applicationsAssignedToTenant: ApplicationResponse[] | undefined
  actions: {
    setModalType: any,
    openApplicationManual: any
  }
}