import React, {useRef} from 'react';
import Select from 'react-select';
import * as styles  from './SelectInputStyles';
import i18next from 'i18next';
import './form.scss'

const SelectInput = (
    {
        tooltipId,
        options,
        value,
        defaultValue,
        customStyles = styles.customStyles,
        placeholder= i18next.t('common.selectPlaceholder'),
        components = {
            IndicatorSeparator: () => null
        },
        isMulti,
        isClearable,
        isSearchable,
        hidden,
        isDisabled,
        input,
        meta
    } : ISelectInputProps
) => {

    const selectRef: any = useRef(null);

    const onChangeHandler = (option: any) => {
        if (!!option && (Array.isArray(option) ? option.length > 0 : true) ) {
            if (isMulti) {
                input.onChange(option.map((o: ISelectInputOption) => o.value));
            } else {
                input.onChange(option.value);
            }
        } else {
            input.onChange(undefined);
        }
        if (!!selectRef && !!selectRef.current) {
            selectRef.current.blur();
        }
    };

    return (
        <Select options={options}
                value={value}
                onChange={onChangeHandler}
                styles={customStyles(!!meta?.touched && !!meta?.error)}
                placeholder={placeholder}
                defaultValue={defaultValue}
                hidden={hidden}
                components={components}
                isMulti={isMulti}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                ref={selectRef}
                data-tip data-for={tooltipId}
        />
    )
};

export default SelectInput;

/*PROPS*/

export interface ISelectInputOption {
    value: string | number | undefined | string[]
    label: string
}

export interface ISelectInputProps {
    tooltipId?: string
    options?: ISelectInputOption[]
    value?: any
    defaultValue?: ISelectInputOption
    customStyles?: any
    placeholder?: string
    components?: any
    isMulti?: boolean
    isClearable?: boolean
    isSearchable?: boolean
    hidden?: boolean
    isDisabled?: boolean
    input?: any,
    error?: string,
    meta?: {
        touched?: boolean,
        error?: string
    }
}