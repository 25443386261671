import React, {useEffect, useRef, useState} from 'react';
import {Router} from 'react-router';
import qs from 'qs';
import {ToastContainer} from 'react-toastify';

// fa icons
import {library} from '@fortawesome/fontawesome-svg-core'
import {faAlignJustify, faAngleDown, faMinus, faPen, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'

// Styles
import './app.scss';
import 'react-toastify/scss/main.scss'
import './components/common/other/toast.scss'

import routes, {AppRoute} from './router/appRoutes';
import history from './router/history';
import {getLoginDataUrlParamsURL, getOauthStatePath, redirectToURL} from './utils/restUtils';
import LoginData from './data/login/LoginData';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Sidebar from './components/navbar/Sidebar';
import {Role} from './data/login/Role';

import FindTenantsResponse from './data/response/FindTenantsResponse';
import ModalHolderContainer from './components/containers/ModaHolderContainer';
import Tenant from './data/tenant/Tenant';
import ApplicationResponse from './data/response/ApplicationResponse';
import {ModalType} from './constants';
import OAuthFrame from './components/OAuthFrame';
import conf from './configuration/conf';
import ErrorResponse from './data/response/ErrorResponse';
import {createSSOWebSocketConnection} from './utils/webSocketUtils';
import ErrorPage from './components/pages/error/ErrorPage';
import ProgressBar from './components/common/progress-bar/ProgressBar';
import CSSTransitionHOC from './components/css-transition/CSSTransitionHOC';

const App = (
    {
        loginData,
        userRole,
        tenants,
        selectedTenant,
        applicationsAssignedToTenant,
        appError,
        activeRequests,
        actions: {
            setLoginData,
            getTenants,
            getTenantsForParty,
            setModalType,
            selectTenant,
            setAppError,
            openApplicationManual
        }
    }: IAppProps
) => {
    const didMountRef: any = useRef(false);
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(window.innerWidth > 768);
    const [silentRefresh, setSilentRefresh] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [silentRefreshTimeout, setSilentRefreshTimeout] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [idleInterval, setIdleInterval] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [idleIntervalCount, setIdleIntervalCount] = useState(0);

    library.add(
        faAlignJustify,
        faTimes,
        faAngleDown,
        faPen,
        faPlus,
        faMinus
    ); 

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);

        //clear counters, intervals, timeouts, listener
        return () => {
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener('focus', handleBrowserFocus);
            window.removeEventListener('blur', handleBrowserBlur);
            clearInterval(idleInterval);
            clearTimeout(silentRefreshTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
        //parse sso login data or redirect if they are not present
        if (history.location.pathname !== AppRoute.OAUTH_CALLBACK) {
            if (!loginData || !loginData.accessToken) {
                const hash: any = qs.parse(history.location.hash.slice(1), { ignoreQueryPrefix: true });
                if (Object.entries(hash).length === 0) {
                    redirectToURL(getLoginDataUrlParamsURL());
                } else if (LoginData.validateAccessToken(hash)) {
                    setLoginData(new LoginData(hash));
                    const statePath: string | undefined = getOauthStatePath(hash.state);
                    history.push(!!statePath ? statePath : history.location.pathname);
                } else {
                    setAppError(new ErrorResponse(undefined, 'Error occurred in initial login redirect')).then(() => history.push(AppRoute.ERROR))
                }
            }
        }
    }, [setLoginData, loginData, setAppError]);

    useEffect(() => {
        // get tenants, initial silent refresh setup, connect to sso websocket, added listeners for idle logic
        if (!!loginData) {
            if (!didMountRef.current) {
                if (userRole === Role.OPERATOR || userRole === Role.READ_ONLY_OPERATOR) {
                    getTenants();
                } else {
                    getTenantsForParty();
                }
                if (!!loginData.identityTokenPayload) {
                    createSSOWebSocketConnection(loginData.identityTokenPayload, loginData);
                }
                window.addEventListener("focus", handleBrowserFocus);
                window.addEventListener("blur", handleBrowserBlur);

                setUpSilentRefresh(loginData);

                //do this block only once per component lifecycle
                didMountRef.current = true;
            } else {
                clearTimeout(silentRefreshTimeout);
                setUpSilentRefresh(loginData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData, userRole ,getTenants, getTenantsForParty]);

    useEffect(() => {
        //perform tenant autoselect when only one tenant exist. Otherwise force user to manual tenant selection
        if (tenants?.tenants && !selectedTenant) {
            const selectedIdentityID: string | undefined = loginData?.identityTokenPayload?.tenant && loginData.identityTokenPayload.tenant.length === 1
              ? loginData.identityTokenPayload.tenant[0]
              : undefined; 
            if (tenants.tenants.length === 1) {
                selectTenant(tenants.tenants[0])
            } else if (selectedIdentityID) {
                const selectedIdentity: Tenant | undefined = tenants.tenants.find((tenant: Tenant) => tenant.id === selectedIdentityID);
                selectTenant(selectedIdentity);
            } else {
                // selectTenant(tenants.tenants[0])
                setModalType(ModalType.SELECT_TENANT);
            }
        }
    }, [tenants, selectedTenant, selectTenant, setModalType]);

    const onWindowResize = () => {
        // hide/show side menu automatically based on screen width (mobile etc)
        if (window.innerWidth <= 768) {
            setIsSideMenuVisible(false)
        } else {
            setIsSideMenuVisible(true)
        }
    };

    const setUpSilentRefresh = (loginData: LoginData) => {
        //execute silent refresh via iFrame when user is idle less than conf.idleSeconds (time for logout)
        silentRefreshTimeout = setTimeout(() => {
            setIdleIntervalCount((idleIntervalCount: number) => {
                if (idleIntervalCount < conf.idleSeconds) {
                    setSilentRefresh(true);
                }
                return idleIntervalCount;
            })

        }, (loginData.expiresIn > conf.silentRefreshSeconds ? loginData.expiresIn - conf.silentRefreshSeconds : conf.silentRefreshSeconds) * 1000);
    };

    const handleBrowserFocus = () => {
        //reset idle interval and counter
        setIdleIntervalCount(0);
        clearInterval(idleInterval);
    };

    const handleBrowserBlur = () => {
        // logout user if is idle more than conf.idleSeconds
        idleInterval = setInterval(() => {
            setIdleIntervalCount((idleIntervalCount: number) => {
                if (idleIntervalCount > conf.idleSeconds) {
                    clearInterval(idleInterval);
                    clearTimeout(silentRefreshTimeout);
                    history.push(AppRoute.LOGOUT);
                    return idleIntervalCount;
                }

                return idleIntervalCount + 1;
            })
        },1000);
    };

    return (
        <div className="sidebar-wrapper">
            <CSSTransitionHOC renderOn={activeRequests > 0}
                              clazzNames={'spinner'}
                              timeout={200} >
                <ProgressBar/>
            </CSSTransitionHOC>
            { !!userRole && !appError && !history.location.pathname.startsWith(AppRoute.LOGOUT) && //TODO add animation
            <Sidebar isSideMenuVisible={ isSideMenuVisible }
                     setIsSideMenuVisible={ setIsSideMenuVisible }
                     applicationsAssignedToTenant={ applicationsAssignedToTenant }
                     actions={{ setModalType, openApplicationManual }} />
            }
            <div className="d-flex flex-column sticky-footer-wrapper w-100">
                <Navbar isSideMenuVisible={ isSideMenuVisible }
                        setIsSideMenuVisible={ setIsSideMenuVisible }
                        selectedTenant={ selectedTenant }
                        tenants={ tenants }
                        actions={{ setModalType }} />
                <main className="flex-fill p-3">
                    <div className="position-relative">
                        { !loginData && !!appError && <ErrorPage appError={ appError } /> }
                        { !!loginData && <Router history={ history } children={ routes } /> }
                    </div>
                </main>
                <Footer/>
            </div>
            <ToastContainer newestOnTop
                            autoClose={ 3000 } />
            <ModalHolderContainer/>
            { silentRefresh && <OAuthFrame actions={{ setLoginData, setSilentRefresh }} /> }
        </div>
    );
};

export default App;

/*PROPS*/

export interface IAppProps {
    loginData: LoginData | undefined
    userRole: Role | undefined
    tenants: FindTenantsResponse | undefined
    selectedTenant: Tenant | undefined
    applicationsAssignedToTenant: ApplicationResponse[] | undefined
    appError: ErrorResponse | undefined
    activeRequests: number
    actions: {
        setLoginData: Function
        selectTenant: Function
        getTenants: Function
        getTenantsForParty: Function
        setModalType: Function
        setAppError: Function
        openApplicationManual: Function
    }
}
