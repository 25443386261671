import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import App from '../../App';
import { setLoginData } from '../../actions/loginActions';
import { IState } from '../../reducers/indexReducer';
import { getTenants, getTenantsForParty, selectTenant } from '../../actions/tenantActions';
import { setAppError, setModalType } from '../../actions/appActions';
import { openApplicationManual } from '../../actions/applicationActions';

const mapStateToProps = (state: IState) => {
    return {
        loginData: state.loginReducer.loginData,
        userRole: state.loginReducer.userRole,
        tenants: state.tenantReducer.tenants,
        selectedTenant: state.tenantReducer.selectedTenant,
        applicationsAssignedToTenant: state.applicationReducer.applicationsAssignedToTenant,
        appError: state.appReducer.appError,
        activeRequests: state.appReducer.activeRequests
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            setLoginData,
            selectTenant,
            getTenants,
            getTenantsForParty,
            setModalType,
            setAppError,
            openApplicationManual
        }, dispatch)
    }
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;