import { backendClient } from '../rest/restClient';
import { toast } from 'react-toastify';
import ToastBody, {ToastIcons} from '../components/common/other/ToastBody';
import i18next from 'i18next';
import React from 'react';
import Party from '../data/party/Party';
import history from '../router/history';
import { AppRoute } from '../router/appRoutes';
import { FormOperation } from '../constants';
import { getErrorMsg } from '../utils/restUtils';
import ErrorResponse from '../data/response/ErrorResponse';
import moment from 'moment';
import { fileDownload } from '../utils/utils';
import * as constants from '../constants';

export const GET_PARTY_LIST_FOR_TENANT_ACTION = 'GET_PARTY_LIST_FOR_TENANT_ACTION';
export const GET_PARTY_DETAIL_ACTION = 'GET_PARTY_DETAIL_ACTION';
export const RESET_PARTY_DETAIL_ACTION = 'RESET_PARTY_DETAIL_ACTION';
export const SET_PARTY_INPUT_VALUE = 'SET_PARTY_INPUT_VALUE';
export const RESET_PARTY_FILTER = 'RESET_PARTY_FILTER';

export const resetPartyState = () => {
    return (dispatch: any) => {
        dispatch({
            type: RESET_PARTY_DETAIL_ACTION
        })
    }
};

export const resetPartyFilter = () => {
    return (dispatch: any) => {
        dispatch({
            type: RESET_PARTY_FILTER
        })
    }
}

export const changeInput = (name: string, value: string | undefined) => {
    return (dispatch: any) => {
        dispatch({
            type: SET_PARTY_INPUT_VALUE,
            payload: { name, value }
        })
    }
}

export const createParty = (): Function => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);

            const partyFormValues = getState().form?.partyCreate?.values;
            if (!selectedTenantId || !partyFormValues) {
                toast.error(<ToastBody message={ i18next.t('messages.error.basic') } icon={ ToastIcons.ERROR } />);
                reject();
                return;
            }
            backendClient.post(`/tenant/${ selectedTenantId }/party`, getCreatePartyRequest(partyFormValues))
                .then((response) => {
                    dispatch(getPartyForTenant());
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.createParty')) } icon={ ToastIcons.ERROR } />);
                    reject(error);
                })
        })
    }
};

export const updateParty = (): Function => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);

            const partyFormValues = getState().form?.partyDetail?.values;
            if (!selectedTenantId || !partyFormValues) {
                toast.error(<ToastBody message={ i18next.t('messages.error.basic') } icon={ ToastIcons.ERROR } />);
                reject();
                return;
            }
            backendClient.put(`/tenant/${ selectedTenantId }/party/${ partyFormValues.id }`, getCreatePartyRequest(partyFormValues, FormOperation.UPDATE))
                .then((response) => {
                    dispatch(getPartyForTenant());
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.updateParty')) } icon={ ToastIcons.ERROR } />);
                    reject(error);
                })
        })
    }
};

export const deleteParty = (partyId: string) => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);

            if (!selectedTenantId || !partyId) {
                toast.error(<ToastBody message={i18next.t('messages.error.basic')} icon={ToastIcons.ERROR}/>);
                reject();
                return;
            }
            backendClient.delete(`/tenant/${ selectedTenantId }/party/${ partyId }`)
                .then((response) => {
                    dispatch(getPartyForTenant());
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.deleteParty')) } icon={ ToastIcons.ERROR } />);
                    reject(error);
                })
        })
    }
};

export const getPartyDetail = (partyId: string) => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);

            if (!selectedTenantId || !partyId) {
                toast.error(<ToastBody message={ i18next.t('messages.error.basic') } icon={ ToastIcons.ERROR }/>);
                history.push(AppRoute.PARTY_LIST);
                reject();
                return;
            }
            backendClient.get(`/tenant/${ selectedTenantId }/party/${ partyId }`)
                .then((response) => {
                    dispatch({
                        type: GET_PARTY_DETAIL_ACTION,
                        payload: response.data
                    });
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.getPartyDetail')) } icon={ ToastIcons.ERROR } />);
                    history.push(AppRoute.PARTY_LIST);
                    reject(error);
                })
        })
    }
};

export const getPartyForTenant = (
    page: number = 1
) => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);
            const email: string | undefined = getState().partyReducer.partyFilter?.email;
            const firstName: string | undefined = getState().partyReducer.partyFilter?.firstName;
            const lastName: string | undefined = getState().partyReducer.partyFilter?.lastName;

            let data: any = {
                page: page,
                size: constants.TENANT_PAGE_SIZE,
                filter: {
                    email: undefined,
                    firstName: undefined,
                    lastName: undefined
                },
                order: {
                    orderBy: 'EMAIL',
                    direction: 'ASC'
                }
            };

            if (!email && !firstName && !lastName) delete data.filter;
            else {
                if (email) data.filter.email = email;
                if (firstName) data.filter.firstName = firstName;
                if (lastName) data.filter.lastName = lastName;
            }

            if (!selectedTenantId) {
                toast.error(<ToastBody message={ i18next.t('messages.error.selectedTenantNotFound') } icon={ ToastIcons.ERROR }/>);
                reject();
                return;
            }
            backendClient.post(`/tenant/${ selectedTenantId }/party/paged`, data)
                .then((response) => {
                    dispatch({
                        type: GET_PARTY_LIST_FOR_TENANT_ACTION,
                        payload: response.data
                    });
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.getPartyList')) } icon={ ToastIcons.ERROR }/>);
                    reject(error);
                })
        });
    }
};

export const exportPartiesForTenant = () => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const selectedTenantId: string = getSelectedTenantId(getState);
            const size: number = getState().partyReducer.total;

            let data: any = {
                page: 1,
                size: size,
                order: {
                    orderBy: 'EMAIL',
                    direction: 'ASC'
                }
            };

            if (!selectedTenantId) {
                toast.error(<ToastBody message={ i18next.t('messages.error.selectedTenantNotFound') } icon={ ToastIcons.ERROR } />);
                reject();
                return;
            }
            backendClient.post(`/tenant/${ selectedTenantId }/party/paged`, data)
              .then((response) => {
                  if (response.data?.content?.length > 0) {
                      const data = response.data.content.map((party: Party) => {
                          return {
                              email: party.email,
                              name: party.firstName,
                              surname: party.lastName,
                              note: party.note,
                              'deleteFlag (1 = delete)': 0 // delete should be the last parameter in list
                          }
                      })
                      fileDownload(data, `PARTY_${selectedTenantId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
                  }
                  resolve();
              })
              .catch((error: any) => {
                  toast.error(<ToastBody message={ getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.exportPartiesFotTenant')) } icon={ ToastIcons.ERROR } />);
                  reject(error);
              })
        });
    }
}

export const importPartiesForTenant = () => {
    return (dispatch: Function, getState: Function) => {
        return new Promise((resolve, reject) => {
            const partyXLSX = getState().form?.file?.values?.partyXLSX;
            const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
            if (!selectedTenantId || !partyXLSX) {
                toast.error(<ToastBody message={i18next.t('messages.error.importPartiesForTenant')} icon={ToastIcons.ERROR}/>);
                reject();
                return;
            }
            const data = new FormData();
            data.append(`file`, new File([partyXLSX], `PARTY_${selectedTenantId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`));
            backendClient.post(`/party/upload`, data)
                .then((response) => {
                    if (response.data.every((row: any) => row.status === 'created' || row.status === 'updated')){
                        toast.success(<ToastBody message={i18next.t('messages.success.importPartiesForTenant')} icon={ToastIcons.SUCCESS}/>);
                    } else {
                        toast.warn(<ToastBody message={i18next.t('messages.warning.importPartiesForTenant')} icon={ToastIcons.WARNING}/>);
                    }
                    fileDownload(response.data, `PARTY_${selectedTenantId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
                    resolve();
                })
                .catch((error: any) => {
                    toast.error(<ToastBody message={getErrorMsg(ErrorResponse.create(error?.response?.data), i18next.t('messages.error.importPartiesForTenant'))} icon={ToastIcons.ERROR}/>);
                    reject(error);
                })
        });
    }
};

const getCreatePartyRequest = (partyFormValues: any, formOperation: FormOperation = FormOperation.CREATE) => {
    return {
        Party: new Party(
            undefined,
            formOperation === FormOperation.CREATE ? partyFormValues.email : undefined,
            partyFormValues.firstName,
            partyFormValues.lastName,
            partyFormValues.note,
            undefined
        )
    }
};

const getSelectedTenantId = (getState: Function): string  => {
    return getState().tenantReducer.selectedTenant?.externalId
      ? 'external_id::' + getState().tenantReducer.selectedTenant.externalId
      : getState().tenantReducer.selectedTenant?.id;
};