import React from 'react';
import { Route, Switch } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import OperatorRoute from './OperatorRoute';

import '../components/css-transition/CSSTransitionAnimation.scss'

import { PartyCreatePageContainer, PartyDetailPageContainer } from '../components/containers/PartyContainer';
import TenantContainer from '../components/containers/TenantContainer';
import TenantListContainer from '../components/containers/TenantListContainer';
import PartyListContainer from '../components/containers/PartyListContainer';
import ApplicationListPageContainer from '../components/containers/ApplicationListPageContainer';
import ErrorPageContainer from '../components/containers/ErrorPageContainer';
import ApplicationDetailPageContainer from '../components/containers/ApplicationDetailPageContainer';
import LogoutPage from '../components/pages/LogoutPage';


export enum AppRoute {
    INDEX = '/',
    PARTY = '/party',
    PARTY_LIST = '/party/list',
    PARTY_CREATE = '/party/create',
    TENANT_LIST = '/tenant/list',
    TENANT_CREATE = '/tenant/create',
    APPLICATION = '/application',
    ERROR = '/error',
    OAUTH_CALLBACK = '/cb',
    LOGOUT = '/logout'
}

export default (
    <Route
        render={({ location }) => (
            <TransitionGroup>
                <CSSTransition classNames="router"
                               timeout={ 300 }
                               key={ location.pathname }>
                    <Switch location={ location }>
                        <Route path={ AppRoute.PARTY_LIST } component={ PartyListContainer } />
                        <Route path={ AppRoute.PARTY_CREATE } component={ PartyCreatePageContainer } />
                        <Route path={ AppRoute.PARTY + '/:partyId' } component={ PartyDetailPageContainer } />
                        <OperatorRoute path={ AppRoute.TENANT_LIST } component={ TenantListContainer } />
                        <OperatorRoute path={ AppRoute.TENANT_CREATE } component={ TenantContainer } />
                        <Route path={ AppRoute.APPLICATION + '/:applicationId' } component={ ApplicationDetailPageContainer } />
                        <OperatorRoute path={ AppRoute.APPLICATION } component={ ApplicationListPageContainer } />
                        <Route path={ AppRoute.ERROR } component={ ErrorPageContainer } />
                        <Route path={ AppRoute.LOGOUT } component={ LogoutPage } />
                        <Route path={ AppRoute.INDEX } component={ PartyListContainer } />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        )}
    />
);