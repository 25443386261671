import React from 'react';
import { RowInputWithLabel, RowTextareaInputWithLabel } from '../../common/form/InputTypes';
import i18next from 'i18next';
import ImportExportCSV from '../../common/other/ImportExportCSV';
import ApplicationResponse from '../../../data/response/ApplicationResponse';
import Accordion from '../../common/accordeon/Accordion';
import { LicenceType } from '../../../data/application/LicenceType';

const ApplicationDetailFormComponent = ({
  applicationDetail,
  applicationId,
  actions: { importPartiesForApplication, getApplicationDetail, exportPartiesForApplication }
}: IApplicationDetailFormComponentProps) => {
  const handleImportCSVClick = () => {
    importPartiesForApplication().then(() => {
      getApplicationDetail(applicationId);
    });
  };

  const handleExportToCsvClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    exportPartiesForApplication();
  };

  return (
    <form>
      {/*<div className="row">*/}
      {/*  <div className="col-12">*/}
      {/*    <RowInputWithLabel*/}
      {/*      name={'id'}*/}
      {/*      label={i18next.t('pages.application.detail.form.id')}*/}
      {/*      readOnly={true}*/}
      {/*      labelClassName="col-12 col-sm-3 text-sm-right"*/}
      {/*      inputWrapperClassName="col-12 col-sm-8"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-12">
          <RowInputWithLabel
            name={'guiName'}
            label={i18next.t('pages.application.detail.form.guiName')}
            readOnly={true}
            labelClassName="col-12 col-sm-3 text-sm-right"
            inputWrapperClassName="col-12 col-sm-8"
          />
        </div>
      </div>
      {/*<div className="row">*/}
      {/*  <div className="col-12">*/}
      {/*    <RowInputWithLabel*/}
      {/*      name={'originalName'}*/}
      {/*      label={i18next.t('pages.application.detail.form.originalName')}*/}
      {/*      readOnly={true}*/}
      {/*      labelClassName="col-12 col-sm-3 text-sm-right"*/}
      {/*      inputWrapperClassName="col-12 col-sm-8"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-12">
          <RowTextareaInputWithLabel
            name={'description'}
            label={i18next.t('pages.application.detail.form.description')}
            readOnly={true}
            labelClassName="col-12 col-sm-3 text-sm-right"
            inputWrapperClassName="col-12 col-sm-8"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-sm-8 offset-sm-3">
          {applicationDetail?.licence?.type === LicenceType.KEY_BASED && !!applicationDetail?.licence?.keys && (
            <Accordion className="border--gray" title={i18next.t('pages.application.detail.keyListLabel')}>
              <div className="row">
                <div className="col-12">
                  <ul>
                    {applicationDetail?.licence?.keys.map((licenceKey) => (
                      <li key={licenceKey.key}>
                        {`${licenceKey.key}`}
                        <strong>
                          {licenceKey.status === 'used'
                            ? ` (${i18next.t('pages.application.detail.keyUsedLabel')})`
                            : ''}
                        </strong>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Accordion>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-3">
          {/*<ButtonPrimary type={'button'}*/}
          {/*               onClick={(e?: React.MouseEvent<HTMLElement>) => setModalType(ModalType.ASSIGN_APPLICATION_TO_PARTY, e)}*/}
          {/*               className="mr-2">*/}
          {/*    {i18next.t('pages.application.detail.assignPartyButton')}*/}
          {/*</ButtonPrimary>*/}
          <ImportExportCSV
            fileInputName={'applicationCSV'}
            actions={{ handleExportToCsvClick, handleImportCSVClick }}
          />
        </div>
      </div>
    </form>
  );
};

export default ApplicationDetailFormComponent;

/*PROPS*/

export interface IApplicationDetailFormComponentProps {
  applicationDetail: ApplicationResponse | undefined;
  applicationId: string;
  actions: {
    setModalType: any;
    importPartiesForApplication: any;
    getApplicationDetail: any;
    exportPartiesForApplication: any;
  };
}
