import IPartyReducer from '../data/party/IPartyReducer';
import IAction from '../data/common/IAction';
import {
    GET_PARTY_DETAIL_ACTION,
    GET_PARTY_LIST_FOR_TENANT_ACTION,
    RESET_PARTY_DETAIL_ACTION,
    SET_PARTY_INPUT_VALUE,
    RESET_PARTY_FILTER
} from '../actions/partyActions';
import Party from '../data/party/Party';
import GetPartyDetailResponse from '../data/response/GetPartyDetailResponse';
import PartyListFilterRequest from "../data/request/PartyListFilterRequest";

const InitialReducer: IPartyReducer = {
    partyList: undefined,
    total: undefined,
    partyDetail: undefined,
    partyFilter: new PartyListFilterRequest(undefined, undefined, undefined, undefined)
};

const partyReducer = (state: IPartyReducer = InitialReducer, action: IAction) => {
    switch (action.type) {
        case RESET_PARTY_DETAIL_ACTION:
            return {
                ...state,
                partyDetail: undefined,
            };
        case RESET_PARTY_FILTER:
            return {
                ...state,
                partyFilter: new PartyListFilterRequest(undefined, undefined, undefined, undefined)
            };
        case GET_PARTY_DETAIL_ACTION:
            return {
                ...state,
                partyDetail: getPartyDetailResponse(action.payload)
            };
        case GET_PARTY_LIST_FOR_TENANT_ACTION:
            return {
                ...state,
                partyList: action.payload?.content?.map((party: Party) => Party.create(party)),
                total: action.payload?.total
            };
        case SET_PARTY_INPUT_VALUE:
            const { name, value } = action.payload;
            return {
                ...state,
                partyFilter: {
                    ...state.partyFilter,
                    [name]: value || undefined
                }
            }
        default:
            return state;
    }
};

export default partyReducer;

const getPartyDetailResponse = (partyDetailResponse: any): GetPartyDetailResponse | undefined => {
    return GetPartyDetailResponse.create(partyDetailResponse);
};