import React, { useEffect } from 'react';
import i18next from 'i18next';
import { ButtonPrimary, ButtonSecondary } from '../common/button/buttonTypes';
import { ApplicationParamTypeName, FormOperation, ModalType } from '../../constants';
import { assignApplicationToPartyForm } from '../containers/form/ApplicationForm';
import { SelectInputWithLabel } from '../common/form/InputTypes';
import { required } from '../../utils/formValidators';
import Party from '../../data/party/Party';
import ApplicationResponse from '../../data/response/ApplicationResponse';
import ApplicationParam from '../../data/application/ApplicationParam';
import ApplicationConfigurableField from '../pages/app/ApplicationConfigurableField';
import { getApplicationParamTypeValue } from '../../utils/utils';
import store from '../../configureStore';
import { initialize } from 'redux-form';
import { toast } from 'react-toastify';
import ToastBody from '../common/other/ToastBody';
import CSSTransitionHOC from '../css-transition/CSSTransitionHOC';

const ModalAssignApplicationToParty = (
    {
        setModalType,
        handleSubmit,
        partyList,
        applicationDetail,
        assignApplicationToPartyFormValues,
        partyId,
        actions:{
            assignAppToParty,
            getApplicationDetail,
            setModalPartyId
        }
    }: IModalAssignApplicationToPartyProps
) => {

    useEffect(() => {
        const paramsWithDefaultValue: ApplicationParam[] | undefined = applicationDetail?.parameters?.filter((param) => !!getApplicationParamTypeValue(param.typeParams, ApplicationParamTypeName.DEFAULT_VALUE));
        if (!!paramsWithDefaultValue && paramsWithDefaultValue.length > 0) {
            const formData: any = {};
            paramsWithDefaultValue.forEach((param) => {
                formData[`${ param.ldapName }::0`] = getApplicationParamTypeValue(param.typeParams, ApplicationParamTypeName.DEFAULT_VALUE)
            });
            formData['party'] = partyId;
            store.dispatch(initialize('assignApplicationToPartyForm', formData));
        }
    }, [applicationDetail]);

    const handleAssignClick = ():void => {
        assignAppToParty().then(() => {
            toast.success(<ToastBody message={ i18next.t('messages.success.assignAppToParty') } />);
            getApplicationDetail(applicationDetail?.application?.id);
            setModalType(ModalType.UNSET);
        });
    };

    const onCancelClick = (): void => {
        setModalPartyId(undefined);
        setModalType(ModalType.UNSET);
    };

    const availableParties = partyList?.filter((party: Party) => party.id === partyId).map(party => {
        return {
            value: party.id,
            label: `${ party.firstName } ${ party.lastName }`
        }
    });

    const defaultPartyValue = availableParties?.length === 1 ? availableParties[0] : undefined;

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{ i18next.t('modal.assignApplicationToParty.title') }</h5>
            </div>
            <form onSubmit={ handleSubmit(() => handleAssignClick()) }>
                <div className="modal-body modal-overflow--visible mt-1">
                    <div className="row">
                        <div className="col-12">
                            <SelectInputWithLabel name={'party'}
                                                  label={ i18next.t('modal.assignApplicationToParty.form.party') }
                                                  placeholder={ i18next.t('modal.assignApplicationToParty.form.partyPlaceholder') }
                                                  validate={ [required] }
                                                  options={ availableParties }
                                                  defaultValue={ defaultPartyValue }
                                                  readOnly={ !!defaultPartyValue }
                            />
                        </div>
                    </div>
                    <CSSTransitionHOC renderOn={ !!assignApplicationToPartyFormValues?.party }
                                      clazzNames="toggle">
                        <div>
                            { applicationDetail?.parameters?.map((param: ApplicationParam, index) => {
                                return (
                                    <div className="row" key={ index }>
                                        <div className="col-12">
                                            <ApplicationConfigurableField inputParameter={ param }
                                                                          formOperation={ FormOperation.CREATE }
                                                                          assignApplicationToPartyFormValues={ assignApplicationToPartyFormValues }

                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </CSSTransitionHOC>
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        <div className="col-auto pr-2">
                            <ButtonSecondary isBlock type="button"
                                             onClick={ onCancelClick }>
                                { i18next.t('common.buttons.back') }
                            </ButtonSecondary>
                        </div>
                        <div className="col-auto pl-1">
                            <ButtonPrimary isBlock>
                                { i18next.t('common.buttons.assign') }
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default assignApplicationToPartyForm<IModalAssignApplicationToPartyProps>(ModalAssignApplicationToParty);

// PROPS
export interface IModalAssignApplicationToPartyProps {
    setModalType?: any
    handleSubmit?: any
    partyList: Party[] | undefined
    assignApplicationToPartyFormValues: any
    applicationDetail: ApplicationResponse | undefined
    partyId: string | undefined
    actions: {
        assignAppToParty: any
        getApplicationDetail: any
        setModalPartyId: any
    }
}