import { toast } from 'react-toastify';
import ToastBody, { ToastIcons } from '../components/common/other/ToastBody';
import i18next from 'i18next';
import { backendClient } from '../rest/restClient';
import { Scope } from '../constants';
import { setAppError } from './appActions';
import ErrorResponse from '../data/response/ErrorResponse';
import history from '../router/history';
import { AppRoute } from '../router/appRoutes';
import React from 'react';
import { LicenceType } from '../data/application/LicenceType';
import AssignAppToTenantRequest from '../data/request/AssignAppToTenantRequest';
import UpdateApplicationLicence from '../data/request/UpdateApplicationLicence';
import ApplicationResponse from '../data/response/ApplicationResponse';
import ApplicationParamType from '../data/application/ApplicationParamType';
import AssignAppToPartyRequest from '../data/request/AssignAppToPartyRequest';
import { getErrorMsg } from '../utils/restUtils';
import moment from 'moment';
import { fileDownload, openPdf } from '../utils/utils';
import * as constants from '../constants';
import conf from '../configuration/conf';

export const GET_APPLICATIONS_ACTION = 'GET_APPLICATIONS_ACTION';
export const GET_APPLICATION_DETAIL_ACTION = 'GET_APPLICATION_DETAIL_ACTION';
export const RESET_APPLICATION_DETIAL_ACTION = 'RESET_APPLICATION_DETIAL_ACTION';
export const GET_APPLICATION_PARTY_BINDING_DETAIL_ACTION = 'GET_APPLICATION_PARTY_BINDING_DETAIL_ACTION';
export const RESET_APPLICATION_PARTY_BINDING_DETAIL_ACTION = 'RESET_APPLICATION_PARTY_BINDING_DETAIL_ACTION';
export const SET_APPLICATION_INPUT_VALUE = 'SET_APPLICATION_INPUT_VALUE';
export const RESET_APPLICATION_FILTER = 'RESET_APPLICATION_FILTER';

export const changeInput = (name: string, value: string | undefined) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_APPLICATION_INPUT_VALUE,
      payload: { name, value }
    });
  };
};

export const resetApplicationFilter = () => {
  return (dispatch: any) => {
    dispatch({
      type: RESET_APPLICATION_FILTER
    });
  };
};

export const getApplications = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      if (!selectedTenantId) {
        toast.error(<ToastBody message={i18next.t('messages.error.selectedTenantNotFound')} icon={ToastIcons.ERROR} />);
        reject();
        return;
      }
      backendClient
        .get(`/tenant/${selectedTenantId}/application`, {
          params: { scope: [Scope.BASE_INFO, Scope.LICENCE].join(',') }
        })
        .then((response) => {
          dispatch({
            type: GET_APPLICATIONS_ACTION,
            payload: response.data
          });
          resolve();
        })
        .catch((error: any) => {
          dispatch(
            setAppError(new ErrorResponse(error?.response?.data?.errorCode, error?.response?.data?.errorMessage))
          );
          history.push(AppRoute.ERROR);
          reject(error);
        });
    });
  };
};

export const getApplicationDetail = (applicationId: string, page: number = 1) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const email: string | undefined = getState().applicationReducer.applicationFilter?.email;
      const firstName: string | undefined = getState().applicationReducer.applicationFilter?.firstName;
      const lastName: string | undefined = getState().applicationReducer.applicationFilter?.lastName;
      const assigned: boolean | undefined = getState().applicationReducer.applicationFilter?.assigned;

      const data: any = {
        page: page,
        size: constants.TENANT_PAGE_SIZE,
        filter: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          assigned: assigned
        },
        order: {
          orderBy: 'EMAIL',
          direction: 'ASC'
        }
      };

      if (!selectedTenantId || !applicationId) {
        toast.error(<ToastBody message={i18next.t('messages.error.selectedTenantNotFound')} icon={ToastIcons.ERROR} />);
        reject();
        return;
      }
      backendClient
        .post(
          `/tenant/${selectedTenantId}/application/${applicationId}/paged?scope=${Object.values(Scope).join(',')}`,
          data
        )
        .then((response) => {
          dispatch({
            type: GET_APPLICATION_DETAIL_ACTION,
            payload: response.data
          });
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.getApplicationDetail')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const resetApplicationDetail = () => {
  return (dispatch: any) => {
    return new Promise((resolve) => {
      dispatch({
        type: RESET_APPLICATION_DETIAL_ACTION
      });
      resolve();
    });
  };
};

export const assignAppToTenant = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationFormValues = getState().form?.application?.values;
      const applicationDetail = getState().applicationReducer.applicationDetail;
      if (!selectedTenantId || !applicationDetail || !applicationFormValues) {
        toast.error(<ToastBody message={i18next.t('messages.error.selectedTenantNotFound')} icon={ToastIcons.ERROR} />);
        reject();
        return;
      }
      backendClient
        .post(
          `/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}`,
          getAssignAppToTenantData(applicationFormValues)
        )
        .then((response) => {
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.assignAppToTenant')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const updateApplicationLicence = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationFormValues = getState().form?.application?.values;
      const applicationDetail = getState().applicationReducer.applicationDetail;
      if (!selectedTenantId || !applicationFormValues || !applicationDetail) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.updateApplicationLicence')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }
      backendClient
        .put(
          `/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}/licence`,
          getUpdateApplicationLicenceData(applicationFormValues, applicationDetail),
          { params: { operation: applicationFormValues.licenceOperation } }
        )
        .then((response) => {
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.updateApplicationLicence')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const assignAppToParty = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const assignApplicationToPartyFormValues = getState().form?.assignApplicationToPartyForm?.values;
      const applicationDetail = getState().applicationReducer.applicationDetail;
      if (!selectedTenantId || !assignApplicationToPartyFormValues || !applicationDetail) {
        toast.error(<ToastBody message={i18next.t('messages.error.assignAppToParty')} icon={ToastIcons.ERROR} />);
        reject();
        return;
      }
      backendClient
        .post(
          `/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}/party/${assignApplicationToPartyFormValues.party}`,
          getAssignAppToPartyData(assignApplicationToPartyFormValues, applicationDetail)
        )
        .then((response) => {
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.assignAppToParty')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const getApplicationPartyBindingDetail = (partyId: string) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationDetail = getState().applicationReducer.applicationDetail;
      if (!selectedTenantId || !partyId || !applicationDetail) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.getApplicationPartyBindingDetail')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }
      backendClient
        .get(`/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}/party/${partyId}`)
        .then((response) => {
          dispatch({
            type: GET_APPLICATION_PARTY_BINDING_DETAIL_ACTION,
            payload: response.data
          });
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.getApplicationPartyBindingDetail')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const resetApplicationPartyBindingDetail = () => {
  return (dispatch: any) => {
    return new Promise((resolve) => {
      dispatch({
        type: RESET_APPLICATION_PARTY_BINDING_DETAIL_ACTION
      });
      resolve();
    });
  };
};

export const updateApplicationPartyBinding = (partyId: string) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const assignApplicationToPartyFormValues = getState().form?.assignApplicationToPartyForm?.values;
      const applicationDetail = getState().applicationReducer.applicationDetail;

      if (!selectedTenantId || !assignApplicationToPartyFormValues || !applicationDetail || !partyId) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.updateApplicationPartyBinding')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }

      backendClient
        .put(
          `/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}/party/${partyId}`,
          getAssignAppToPartyData(assignApplicationToPartyFormValues, applicationDetail)
        )
        .then((response) => {
          dispatch(getApplicationDetail(applicationDetail.application?.id));
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.updateApplicationPartyBinding')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const deleteApplicationPartyBinding = (partyId: string) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationDetail = getState().applicationReducer.applicationDetail;
      if (!selectedTenantId || !applicationDetail || !partyId) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.deleteApplicationPartyBinding')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }
      backendClient
        .delete(`/tenant/${selectedTenantId}/application/${applicationDetail.application?.id}/party/${partyId}`)
        .then((response) => {
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.deleteApplicationPartyBinding')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const deleteApplicationTenantBinding = (applicationId: string) => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      if (!selectedTenantId || !applicationId) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.deleteApplicationTenantBinding')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }
      backendClient
        .delete(`/tenant/${selectedTenantId}/application/${applicationId}`)
        .then((response) => {
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.deleteApplicationTenantBinding')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const importPartiesForApplication = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const applicationCSV = getState().form?.file?.values?.applicationCSV;
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationId: string = getState().applicationReducer.applicationDetail?.application?.id;
      if (!selectedTenantId || !applicationId || !applicationCSV) {
        toast.error(
          <ToastBody message={i18next.t('messages.error.importPartiesForApplication')} icon={ToastIcons.ERROR} />
        );
        reject();
        return;
      }
      const data = new FormData();
      data.append(
        `file`,
        new File(
          [applicationCSV],
          `APPLICATION_${selectedTenantId}_${applicationId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        )
      );
      backendClient
        .post(`/party/application/upload`, data)
        .then((response) => {
          if (response.data.every((row: any) => row.status === 'created' || row.status === 'updated')) {
            toast.success(
              <ToastBody
                message={i18next.t('messages.success.importPartiesForApplication')}
                icon={ToastIcons.SUCCESS}
              />
            );
          } else {
            toast.warn(
              <ToastBody
                message={i18next.t('messages.warning.importPartiesForApplication')}
                icon={ToastIcons.WARNING}
              />
            );
          }
          fileDownload(
            response.data,
            `STATUS_APPLICATION_${selectedTenantId}_${applicationId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`
          );
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.importPartiesForApplication')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const openApplicationManual = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const url =
        process.env.REACT_APP_APPLICATION_ENV === 'local'
          ? 'https://787d0fcb-656c-453a-ab4b-feeadef4aab1.mock.pstmn.io/static/external/manual.pdf'
          : `${conf.oauthRedirectURL}/static/external/manual.pdf`;

      backendClient
        .get(url)
        .then((response) => {
          if (response.data) openPdf(response.data);
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.openApplicationManual')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

export const exportPartiesForApplication = () => {
  return (dispatch: Function, getState: Function) => {
    return new Promise((resolve, reject) => {
      const selectedTenantId: string = getState().tenantReducer.selectedTenant?.id;
      const applicationId: string = getState().applicationReducer.applicationDetail.application?.id;
      const size: number = getState().applicationReducer.applicationDetail.parties?.total;
      const assigned: boolean | undefined = getState().applicationReducer.applicationFilter?.assigned;

      const data: any = {
        page: 1,
        size: size,
        filter: {
          assigned: assigned
        },
        order: {
          orderBy: 'EMAIL',
          direction: 'ASC'
        }
      };

      if (!selectedTenantId) {
        toast.error(<ToastBody message={i18next.t('messages.error.selectedTenantNotFound')} icon={ToastIcons.ERROR} />);
        reject();
        return;
      }

      backendClient
        .post(
          `/tenant/${selectedTenantId}/application/${applicationId}/paged?scope=${Object.values(Scope).join(',')}`,
          data
        )
        .then((response) => {
          if (response.data) {
            const data = response.data;
            const partyListForXlsx = [];

            if (!data.parties || data.parties.content?.length === 0) {
              const row: any = {
                email: null
              };
              data.parameters
                ?.filter((param: any) => param.type !== 'hidden')
                .forEach((param: any) => {
                  row[param.ldapName] = null;
                });
              partyListForXlsx.push(row);
            } else {
              data.parties.content.forEach((party: any) => {
                const row: any = {
                  email: party.email
                };
                data.parameters
                  ?.filter((param: any) => param.type !== 'hidden' && param.guiName !== 'deleteFlag')
                  .forEach((param: any) => {
                    const values = party.attributes
                      ?.filter((attr: any) => attr.name === param.ldapName)
                      .map((param: any) => param.value);
                    // @ts-ignore
                    row[param.guiName] =
                      !!values && values.length > 0
                        ? values.join('|')
                        : param.guiName === 'deleteFlag (1 = delete)'
                        ? 0
                        : null;
                  });
                row['deleteFlag (1 = delete)'] = 0; // delete should be the last parameter in list
                partyListForXlsx.push(row);
              });
            }
            fileDownload(
              partyListForXlsx,
              `APPLICATION_${selectedTenantId}_${applicationId}_${moment().format('YYYYMMDDHHmmss')}.xlsx`
            );
          }
          resolve();
        })
        .catch((error: any) => {
          toast.error(
            <ToastBody
              message={getErrorMsg(
                ErrorResponse.create(error?.response?.data),
                i18next.t('messages.error.exportPartiesForApplication')
              )}
              icon={ToastIcons.ERROR}
            />
          );
          reject(error);
        });
    });
  };
};

const getAssignAppToTenantData = (applicationFormValues: any): any => {
  switch (applicationFormValues?.licenceType) {
    case LicenceType.UNLIMITED:
      return { Licence: new AssignAppToTenantRequest(LicenceType.UNLIMITED, undefined, undefined, undefined) };
    case LicenceType.QUANTITY_BASED:
      return {
        Licence: new AssignAppToTenantRequest(
          LicenceType.QUANTITY_BASED,
          applicationFormValues?.quantity,
          undefined,
          undefined
        )
      };
    case LicenceType.KEY_BASED:
      return {
        Licence: new AssignAppToTenantRequest(
          LicenceType.KEY_BASED,
          undefined,
          undefined,
          applicationFormValues?.licenceKeys?.split(',')
        )
      };
    default:
      return undefined;
  }
};

const getUpdateApplicationLicenceData = (applicationFormValues: any, applicationDetail: any): any => {
  switch (applicationDetail?.licence?.type) {
    case LicenceType.QUANTITY_BASED:
      return { Licence: new UpdateApplicationLicence(applicationFormValues?.quantity, undefined) };
    case LicenceType.KEY_BASED:
      return { Licence: new UpdateApplicationLicence(undefined, applicationFormValues?.licenceKeys?.split(',')) };
    default:
      return undefined;
  }
};

const getAssignAppToPartyData = (
  assignApplicationToPartyFormValues: any,
  applicationDetail: ApplicationResponse
): AssignAppToPartyRequest => {
  const licenceKeyParam = applicationDetail.parameters?.find((param) => param.isLicenced);
  const licenceKey = assignApplicationToPartyFormValues[`${licenceKeyParam?.ldapName}::0`];
  const params = Object.keys(assignApplicationToPartyFormValues)
    .filter(
      (key: string) =>
        key !== 'party' && key !== `${licenceKeyParam?.ldapName}::0` && !!assignApplicationToPartyFormValues[key]
    )
    .map((key: string) => {
      return new ApplicationParamType(key.substring(0, key.indexOf('::')), assignApplicationToPartyFormValues[key]);
    });
  return new AssignAppToPartyRequest(licenceKey, params);
};
