import {IConf} from './conf';

let runtime_conf = (window as any).$__APP_CONFIG__;

const def_value = 'default';

var env_auth_url = runtime_conf?.loginDataURL ?? def_value;
var env_rdrk_url = runtime_conf?.oauthRedirectURL ?? def_value;
var env_back_url = runtime_conf?.backendURL ?? def_value;
var env_logo_url = runtime_conf?.logoutURL ?? def_value;
var env_sock_url = runtime_conf?.ssoWebSocketURL ?? def_value;
var env_appl_url = runtime_conf?.appURL ?? def_value;
var env_idle_sec = runtime_conf?.idleSeconds ?? def_value;
var env_refr_sec = runtime_conf?.silentRefreshSeconds ?? def_value;

var conf: IConf = {
    loginDataURL: env_auth_url,
    oauthRedirectURL: env_rdrk_url,
    backendURL: env_back_url,
    logoutURL: env_logo_url,
    silentRefreshSeconds: env_refr_sec,
    idleSeconds: env_idle_sec,
    ssoWebSocketURL: env_sock_url,
    appURL: env_appl_url
};

export default conf
