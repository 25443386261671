import React from 'react';


export enum ToastIcons {
    ERROR = 'error',
    WARNING = 'exclamation',
    INFO = 'info',
    SUCCESS = 'success'
}

const ToastBody = (
    {
        message,
        icon = ToastIcons.SUCCESS
    }: IToastBodyProps
) => {
    return (
        <div className="custom-toast--body">
            <img className="custom-toast--icon" src={`/image/icons/toast/${icon}-circle.svg`} alt=""/>
            <div className="custom-toast--msg">
                {message}
            </div>
        </div>
    )
};

export default ToastBody;

/*PROPS*/

interface IToastBodyProps {
    message: string
    icon?: ToastIcons
}