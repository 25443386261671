import React from 'react';
import cx from 'classnames';

import './progressBar.scss';

const CLASS_ROOT = 'page-loader';

const ProgressBar = (
    {
        className
    }: IProgressBarProps
) => {
    const classes = cx(CLASS_ROOT, className);
    const infinityBar = <div className={`${CLASS_ROOT}__infinity-bar`} />;

    return (
        <div className={classes}>
            <div className={`${CLASS_ROOT}__infinity`}>
                {infinityBar}
                {infinityBar}
            </div>
        </div>
    )
};

export default ProgressBar;

/*PROPS*/

interface IProgressBarProps {
    className?: string
}