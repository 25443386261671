import React from 'react';
import ErrorResponse from '../../../data/response/ErrorResponse';
import Card from '../../common/other/Card';
import i18next from 'i18next';

const ErrorPage = (
    {
        appError
    }: IErrorPageProps
) => {

    if (!appError) {
        return <div/>
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Card title={i18next.t('pages.error.title')}>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="mb-0">
                                    {i18next.t('pages.error.codeLabel')}
                                </h6>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                {appError?.errorCode}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="mb-0">
                                    {i18next.t('pages.error.msgLabel')}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {appError?.errorMessage}
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
};

export default ErrorPage;

/*PROPS*/

export interface IErrorPageProps {
    appError: ErrorResponse | undefined
}