import React, {useState} from 'react';
import SubMenuItem from './SubMenuItem';
import {IMenuShape} from './Menu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasUserDesiredRole} from '../../../utils/utils';
import history from '../../../router/history';

const MenuItem = (
    {
        menuItem: {
            name,
            subMenuItems,
            order,
            path
        },
        activeMenuItem,
        actions: {
            setActiveMenuItem,
        }
    }: IMenuItemProps
) => {
    const [isMenuActive, setIsMenuActive] = useState(false);

    const onMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (!subMenuItems) {
            setActiveMenuItem(order);
            if (!!path) {
                history.push(path);
            }
        } else {
            setIsMenuActive(!isMenuActive)
        }
    };

    return (
        <li className={activeMenuItem === order ? 'active' : ''}>
            <a href={!!path ? path : '/#'}
               onClick={onMenuClick}>
                {name}
                {!!subMenuItems && subMenuItems.filter((subMenuItem) => !subMenuItem.allowedRoles || hasUserDesiredRole(subMenuItem.allowedRoles)).length > 0 &&
                <FontAwesomeIcon icon={"angle-down"} className={`dropdown-custom-toggle ${isMenuActive ? 'rotate' : ''}`}/>
                }
            </a>
            {!!subMenuItems &&
            <ul className={!isMenuActive ? 'hidden' : ''} >
                {subMenuItems.filter((subMenuItem) => !subMenuItem.allowedRoles || hasUserDesiredRole(subMenuItem.allowedRoles))
                    .map((subMenuItem: any) => <SubMenuItem subMenuItem={subMenuItem}
                                                            activeMenuItem={activeMenuItem}
                                                            actions={{setActiveMenuItem}}
                                                            key={subMenuItem.order} />
                )}
            </ul>
            }
        </li>
    )
};

export default MenuItem;

/*PROPS*/

interface IMenuItemProps {
    menuItem: IMenuShape,
    activeMenuItem: number
    actions: {
        setActiveMenuItem: Function
    }
}