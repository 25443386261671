import Party from '../party/Party';
import Tenant from '../tenant/Tenant';

export default class FindTenantsResponse {
    party: Party | undefined;
    tenants: Tenant[] | undefined;

    constructor({party, tenants}: FindTenantsResponse) {
        if (!!party) {
            this.party = Party.create(party);
        }
        if (!!tenants) {
            this.tenants = tenants
              .map((t: Tenant) => new Tenant(t.id, t.name, t.externalId))
              .sort((a: any, b: any) => a.name.localeCompare(b.name, 'sk'));
        }
    }
}