import React from 'react';
import cx from 'classnames';

import './button.scss'

export enum ButtonShape {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SECONDARY_INVERTED = "secondary-inverted",
    NAVBAR = "navbar"
}

const Button = (
    {
        shape= ButtonShape.PRIMARY,
        type = "submit",
        size,
        children,
        onClick,
        isBlock,
        disabled,
        className
    }: IButtonProps
) => {

    const classes = cx(
        className,
        'btn',
        `btn--${shape}`,
        {
            'btn-block': isBlock,
            [`btn-${size}`]: size
        }

    );

    return (
        <button className={classes}
                type={type}
                onClick={onClick}
                disabled={disabled}>
            {children}
        </button>
    )
};

export default Button;

/*PROPS*/

export interface IButtonProps {
    shape?: ButtonShape
    type?: "submit" | "reset" | "button"
    size?: "sm" | "lg"
    children: any
    onClick?: any
    isBlock?: boolean
    disabled?: boolean
    className?: string
}