import React, {useEffect, useState} from 'react';
import i18next from 'i18next';
import {ButtonPrimary, ButtonSecondary} from '../common/button/buttonTypes';
import {FormOperation, ModalType} from '../../constants';
import {assignApplicationToPartyForm} from '../containers/form/ApplicationForm';
import ApplicationResponse from '../../data/response/ApplicationResponse';
import ApplicationParam from '../../data/application/ApplicationParam';
import ApplicationConfigurableField from '../pages/app/ApplicationConfigurableField';
import ApplicationParamType from '../../data/application/ApplicationParamType';
import store from '../../configureStore';
import {initialize} from 'redux-form';
import CSSTransitionHOC from '../css-transition/CSSTransitionHOC';
import {toast} from 'react-toastify';
import ToastBody from '../common/other/ToastBody';

const ModalApplicationPartyBindingDetail = (
    {
        setModalType,
        handleSubmit,
        applicationDetail,
        assignApplicationToPartyFormValues,
        selectedPartyId,
        applicationPartyBindingDetail,
        actions:{
            getApplicationPartyBindingDetail,
            resetApplicationPartyBindingDetail,
            updateApplicationPartyBinding
        }
    }: IModalApplicationPartyBindingDetailProps
) => {

    const [paramsValues, setParamsValues] = useState();
    const [isUpdatable, setUpdatable] = useState(false);

    useEffect(() => {
        getApplicationPartyBindingDetail(selectedPartyId);

        return () => resetApplicationPartyBindingDetail();
    },[selectedPartyId, getApplicationPartyBindingDetail, resetApplicationPartyBindingDetail]);

    useEffect(() => {
        if (!!applicationPartyBindingDetail) {
            // Parsing values from detail to proper form init
            const formData: any = {};
            const uniqueParamNames = Array.from(new Set(applicationPartyBindingDetail.map((param) => param.name)));
            const paramsValues = uniqueParamNames.map((paramName) => {
                const paramValues: any = {};
                applicationPartyBindingDetail
                    .filter((param) => param.name === paramName)
                    .forEach((param, index) => {
                        formData[`${param.name}::${index}`] = param.value;
                        paramValues[`${param.name}::${index}`] = param.value;
                    });
                return {
                    name: paramName,
                    values: paramValues
                };
            });
            store.dispatch(initialize('assignApplicationToPartyForm', formData));
            // @ts-ignore
            setParamsValues(paramsValues);
        }

    },[applicationPartyBindingDetail]);

    const handleSaveClick = ():void => {
        updateApplicationPartyBinding(selectedPartyId).then(() => {
            toast.success(<ToastBody message={i18next.t('messages.success.updateApplicationPartyBinding')} />);
            setModalType(ModalType.UNSET);
        });
    };

    const onCancelClick = (): void => {
        setModalType(ModalType.UNSET);
    };

    const handleEditClick = () => {
        if (!isUpdatable) {
            setUpdatable(true);
        }
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{i18next.t('modal.applicationPartyBindingDetail.title')}</h5>
            </div>
            <form onSubmit={handleSubmit(() => handleSaveClick())}>
                <div className="modal-body modal-overflow--visible mt-1">
                {!!paramsValues && applicationDetail?.parameters?.map((param: ApplicationParam, index) => {
                    // @ts-ignore
                    const paramValues = paramsValues.find((p:any) => p.name === param.ldapName);
                    return (
                        <div className="row" key={index}>
                            <div className="col-12">
                                <ApplicationConfigurableField inputParameter={param}
                                                              formOperation={isUpdatable ? FormOperation.UPDATE : FormOperation.READ}
                                                              values={!!paramValues ? paramValues.values : undefined}
                                                              assignApplicationToPartyFormValues={assignApplicationToPartyFormValues}/>
                            </div>
                        </div>
                    )
                })}
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        <div className="col-auto pr-2">
                            <div className="position-relative">
                                <CSSTransitionHOC renderOn={isUpdatable}
                                                  timeout={300}
                                                  clazzNames="toggle">
                                    <ButtonPrimary>
                                        {i18next.t('common.buttons.save')}
                                    </ButtonPrimary>
                                </CSSTransitionHOC>
                                <CSSTransitionHOC renderOn={!isUpdatable}
                                                  timeout={300}
                                                  clazzNames="toggle">
                                    <ButtonSecondary type={'button'}
                                                     onClick={handleEditClick}>
                                        {i18next.t('common.buttons.edit')}
                                    </ButtonSecondary>
                                </CSSTransitionHOC>
                            </div>
                        </div>
                        <div className="col-auto pl-1">
                            <ButtonSecondary isBlock type="button"
                                             onClick={onCancelClick}>
                                {i18next.t('common.buttons.back')}
                            </ButtonSecondary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default assignApplicationToPartyForm<IModalApplicationPartyBindingDetailProps>(ModalApplicationPartyBindingDetail);

/*PROPS*/

export interface IModalApplicationPartyBindingDetailProps {
    setModalType?: any
    handleSubmit?: any
    assignApplicationToPartyFormValues: any
    applicationDetail: ApplicationResponse | undefined
    selectedPartyId: string
    applicationPartyBindingDetail: ApplicationParamType[] | undefined
    actions: {
        getApplicationPartyBindingDetail: any
        resetApplicationPartyBindingDetail: any
        updateApplicationPartyBinding: any
    }
}