import React from 'react';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';
import { ModalType } from '../../../constants';
import ModalSelectTenant from '../../modal/ModalSelectTenant';
import ModalContainer from '../../containers/ModalContainer';
import FindTenantsResponse from '../../../data/response/FindTenantsResponse';
import ModalCreateTenant from '../../modal/ModalCreateTenant';
import { Role } from '../../../data/login/Role';
import LoginData from '../../../data/login/LoginData';
import ModalLogout from '../../modal/ModalLogout';

const ModalHolder = (
    {
        modalType,
        tenants,
        userRole,
        loginData,
        actions: {
            selectTenant,
            createTenant,
        }
    }: IModalHolderProps
) => {
    return (
        <div>
            <CSSTransitionHOC renderOn={ modalType === ModalType.SELECT_TENANT || modalType === ModalType.RESELECT_TENANT } >
                <ModalContainer modalContentClassName="modal-overflow--visible" disableOutsideClickFor={ [ModalType.SELECT_TENANT] } >
                    <ModalSelectTenant userRole={ userRole }
                                       tenants={ tenants }
                                       actions={{ selectTenant }} />
                </ModalContainer>
            </CSSTransitionHOC>
            <CSSTransitionHOC renderOn={ modalType === ModalType.CREATE_TENANT || modalType === ModalType.CREATE_TENANT_REQUIRED } >
                <ModalContainer disableOutsideClickFor={ [ModalType.CREATE_TENANT_REQUIRED] } >
                    <ModalCreateTenant actions={{ createTenant }} />
                </ModalContainer>
            </CSSTransitionHOC>
            <CSSTransitionHOC renderOn={ modalType === ModalType.LOGOUT } >
                <ModalContainer>
                    <ModalLogout loginData={ loginData } tenants={ tenants } />
                </ModalContainer>
            </CSSTransitionHOC>
        </div>
    )
};

export default ModalHolder;

/*PROPS*/

interface IModalHolderProps {
    modalType: ModalType
    tenants: FindTenantsResponse | undefined
    userRole: Role | undefined
    loginData: LoginData | undefined
    actions: {
        selectTenant: Function
        createTenant: any
    }
}