import IApplicationReducer from '../data/application/IApplicationReducer';
import IAction from '../data/common/IAction';
import ApplicationResponse from '../data/response/ApplicationResponse';
import {
    GET_APPLICATION_DETAIL_ACTION,
    GET_APPLICATION_PARTY_BINDING_DETAIL_ACTION,
    GET_APPLICATIONS_ACTION,
    RESET_APPLICATION_DETIAL_ACTION,
    RESET_APPLICATION_PARTY_BINDING_DETAIL_ACTION,
    SET_APPLICATION_INPUT_VALUE,
    RESET_APPLICATION_FILTER
} from '../actions/applicationActions';
import ApplicationParamType from '../data/application/ApplicationParamType';
import PartyListFilterRequest from "../data/request/PartyListFilterRequest";

const InitialState: IApplicationReducer = {
    applications: undefined,
    applicationsAssignedToTenant: undefined,
    applicationsUnassignedToTenant: undefined,
    applicationDetail: undefined,
    applicationPartyBindingDetail: undefined,
    applicationFilter: new PartyListFilterRequest(undefined, undefined, undefined, false)
};

const applicationReducer = (state: IApplicationReducer = InitialState, action: IAction) => {
    switch (action.type) {
        case GET_APPLICATIONS_ACTION:
            return {
                ...state,
                applications: action.payload?.map((app: ApplicationResponse) => ApplicationResponse.create(app)),
                applicationsAssignedToTenant: action.payload?.filter((app: ApplicationResponse) => app.application?.activated).map((app: ApplicationResponse) => ApplicationResponse.create(app)),
                applicationsUnassignedToTenant: action.payload?.filter((app: ApplicationResponse) => !app.application?.activated).map((app: ApplicationResponse) => ApplicationResponse.create(app))
            };
        case GET_APPLICATION_DETAIL_ACTION:
            return {
                ...state,
                applicationDetail: ApplicationResponse.create(action.payload)
            };
        case RESET_APPLICATION_DETIAL_ACTION:
            return {
                ...state,
                applicationDetail: undefined,
            };
        case RESET_APPLICATION_FILTER:
            return {
                ...state,
                applicationFilter: new PartyListFilterRequest(undefined, undefined, undefined, state.applicationFilter?.assigned)
            };
        case GET_APPLICATION_PARTY_BINDING_DETAIL_ACTION:
            return {
                ...state,
                applicationPartyBindingDetail: action.payload?.map((appParam: ApplicationParamType) => ApplicationParamType.create(appParam))
            };
        case RESET_APPLICATION_PARTY_BINDING_DETAIL_ACTION:
            return {
                ...state,
                applicationPartyBindingDetail: undefined
            };
        case SET_APPLICATION_INPUT_VALUE:
            const { name, value } = action.payload;
            const val = typeof value === 'boolean' ? value : value || undefined;
            return {
                ...state,
                applicationFilter: {
                    ...state.applicationFilter,
                    [name]: val
                }
            }
        default:
            return state;
    }
};

export default applicationReducer;