import React from 'react';
import './footer.scss'

const Footer = () => {

    return (
        <footer className="bg-dark">
            <div className="container-fluid text-light footer"/>
        </footer>
    )
};

export default Footer;