import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import './modal.scss'
import { ModalType } from '../../constants';
import { handleClickOutsideComponent } from '../../utils/utils';

const Modal = (
  {
    children,
    modalType,
    modalContentClassName,
    disableOutsideClickFor,
    actions: {
      setModalType,
      setModalPartyId
    }
  }: IModalProps
) => {
  const modalRef = useRef(null);

  const handleCloseModal = () => {
    setModalPartyId(undefined);
    setModalType(ModalType.UNSET);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clickOutsideListener = (e: any) => {
    handleClickOutsideComponent(e, [modalRef], () => handleCloseModal());
  };

  useEffect(() => {
    if (!disableOutsideClickFor?.includes(modalType)) {
      document.addEventListener('mousedown', clickOutsideListener);
    }

    return () => {
      document.removeEventListener('mousedown', clickOutsideListener);
    };
  }, [clickOutsideListener, disableOutsideClickFor, modalType]);

  const modalContentClazzName = cx(
    'modal-content',
    modalContentClassName
  );


  return (
    <div>
      <div className="modal">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className={modalContentClazzName}
               ref={modalRef}>
            {React.cloneElement(children, {modalType, setModalType})}
          </div>
        </div>
      </div>
      <div className="modal-backdrop"/>
    </div>
  )
};

export default Modal;

/*PROPS*/

interface IModalProps {
  children: any
  modalType: number
  modalContentClassName?: string
  disableOutsideClickFor?: ModalType[]
  actions: {
    setModalType: any
    setModalPartyId: any
  }
}