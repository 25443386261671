import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PartyCreatePage from '../pages/party/PartyCreatePage';
import {createParty, getPartyDetail, resetPartyState, updateParty} from '../../actions/partyActions';
import PartyDetailPage from '../pages/party/PartyDetailPage';
import {IState} from '../../reducers/indexReducer';
import {partyCreateForm, partyDetailForm} from './form/PartyForm';

const mapStateToProps = (state: IState) => {
    return {
        selectedTenant: state.tenantReducer.selectedTenant,
        partyDetail: state.partyReducer.partyDetail
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            createParty,
            updateParty,
            getPartyDetail,
            resetPartyState
        }, dispatch)
    }
};

export const PartyCreatePageContainer = connect(mapStateToProps, mapDispatchToProps)(partyCreateForm(PartyCreatePage));

export const PartyDetailPageContainer = connect(mapStateToProps, mapDispatchToProps)(partyDetailForm(PartyDetailPage));