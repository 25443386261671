import LicenceKey from './LicenceKey';

export default class Licence {
    type: string;
    quantityAssigned: number;
    quantityUsed: number;
    keys: LicenceKey[];

    constructor(type: string, quantityAssigned: number, quantityUsed: number, keys: LicenceKey[]) {
        this.type = type;
        this.quantityAssigned = quantityAssigned;
        this.quantityUsed = quantityUsed;
        this.keys = keys;
    }

    static create(licence: any): Licence | undefined{
        if (!licence) {
            return undefined;
        }

        return new Licence(
            licence?.type ,
            licence?.quantityAssigned,
            licence?.quantityUsed,
            licence?.keys?.map((key: LicenceKey) => LicenceKey.create(key))
        )
    }
}