import React from 'react';
import { Redirect, Route } from 'react-router';
import { AppRoute } from './appRoutes';
import { hasUserDesiredRole } from '../utils/utils';
import { Role } from '../data/login/Role';

const OperatorRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            { ...rest }
            render={ props =>
                hasUserDesiredRole([Role.OPERATOR]) ? (
                    <Component { ...props } />
                ) : (
                    <Redirect to={{ pathname: AppRoute.INDEX }} />
                )
            }
        />
    );
};

export default OperatorRoute;