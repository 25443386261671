import React from 'react';
import i18next from 'i18next';
import { ButtonPrimary, ButtonSecondary } from '../common/button/buttonTypes';
import { ModalType } from '../../constants';
import FindTenantsResponse from '../../data/response/FindTenantsResponse';
import { tenantSelectForm } from '../containers/form/TenantForm';
import { SelectInputWithLabel } from '../common/form/InputTypes';
import Tenant from '../../data/tenant/Tenant';
import { required } from '../../utils/formValidators';
import { Role } from '../../data/login/Role';

const ModalSelectTenant = (
    {
        setModalType,
        modalType,
        tenants,
        handleSubmit,
        userRole,
        actions: {
            selectTenant
        }
    }: IModalSelectTenantProps
) => {

    const handleSelectClick = ():void => {
        selectTenant().then(() => setModalType(ModalType.UNSET))
    };

    const onCreateTenantClick = (): void => {
        setModalType(modalType === ModalType.SELECT_TENANT ? ModalType.CREATE_TENANT_REQUIRED : ModalType.CREATE_TENANT);
    };

    const getTenantSelectOptions = () => {
        if (!tenants || !tenants.tenants) {
            return []
        }
        return tenants.tenants.map((tenant: Tenant) => {
            return {
                value: tenant.id,
                label: tenant.name
            }
        })
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{ i18next.t('modal.selectTenant.title') }</h5>
            </div>
            <form onSubmit={ handleSubmit(() => handleSelectClick()) }>
                <div className="modal-body modal-overflow--visible mt-1">
                    <div className="row">
                        <div className="col-12">
                            <SelectInputWithLabel name={'selected'} options={ getTenantSelectOptions() } validate={[required]}/>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        { userRole === Role.OPERATOR &&
                        <div className="col-auto pr-2">
                            <ButtonSecondary isBlock type="button"
                                             onClick={ onCreateTenantClick }>
                                { i18next.t('modal.selectTenant.createTenantBtn') }
                            </ButtonSecondary>
                        </div>
                        }
                        <div className="col-auto pl-1">
                            <ButtonPrimary isBlock>
                                { i18next.t('common.buttons.select') }
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default tenantSelectForm<IModalSelectTenantProps>(ModalSelectTenant);

/*PROPS*/

export interface IModalSelectTenantProps {
    setModalType?: any
    modalType?: ModalType
    tenants: FindTenantsResponse | undefined
    userRole: Role | undefined
    handleSubmit?: any
    actions: {
        selectTenant: any
    }
}