import ApplicationParamType from '../application/ApplicationParamType';

export default class AssignAppToPartyRequest {
    licenceKey: string;
    partyAttributes: ApplicationParamType[];

    constructor(licenceKey: string, partyAttributes: ApplicationParamType[]) {
        this.licenceKey = licenceKey;
        this.partyAttributes = partyAttributes;
    }
}