import React, {useEffect, useState} from 'react';
import i18next from 'i18next';
import {ButtonPrimary, ButtonSecondary} from '../common/button/buttonTypes';
import {LicenceOperation, ModalType} from '../../constants';
import {InputWithLabel, SelectInputWithLabel, TextareaInputWithLabel} from '../common/form/InputTypes';
import {applicationForm} from '../containers/form/ApplicationForm';
import {maxValue, minValue, minValue1, required} from '../../utils/formValidators';
import {InputType} from '../common/form/Input';
import ApplicationResponse from '../../data/response/ApplicationResponse';
import {LicenceType} from '../../data/application/LicenceType';
import CSSTransitionHOC from '../css-transition/CSSTransitionHOC';
import {toast} from 'react-toastify';
import ToastBody from '../common/other/ToastBody';
import Accordion from '../common/accordeon/Accordion';

const ModalUpdateApplicationLicence = (
    {
        setModalType,
        handleSubmit,
        applicationDetailId,
        applicationDetail,
        licenceOperationFieldValue,
        actions: {
            getApplications,
            getApplicationDetail,
            resetApplicationDetail,
            updateApplicationLicence
        }
    }: IModalUpdateApplicationLicenceProps
) => {

    const [quantityValidation, setQuantityValidation] = useState<any>();


    useEffect(() => {
        getApplicationDetail(applicationDetailId);
        return () => resetApplicationDetail();
    }, [applicationDetailId, getApplicationDetail, resetApplicationDetail]);

    useEffect(() => {
        if (!!licenceOperationFieldValue) {
            const quantityAssigned = applicationDetail?.licence?.quantityAssigned;
            const quantityUsed = applicationDetail?.licence?.quantityUsed;

            if (licenceOperationFieldValue === LicenceOperation.INCREASE) {
                setQuantityValidation(() => minValue(1));
            } else if (!!quantityAssigned && quantityUsed !== undefined){
                    setQuantityValidation(() => maxValue(quantityAssigned - quantityUsed - 1));
            } else {
                setQuantityValidation(minValue1);
            }
        }
    }, [applicationDetail,licenceOperationFieldValue]);

    const handleSaveClick = ():void => {
        updateApplicationLicence().then(() => {
            toast.success(<ToastBody message={i18next.t('messages.success.assignAppToTenant')} />)
            getApplications().then(() => {
                setModalType(ModalType.UNSET);
            })
        });
    };

    const onCancelClick = (): void => {
        setModalType(ModalType.UNSET);
    };

    const licenceOperationOptions = [
        {
            value: LicenceOperation.INCREASE,
            label: i18next.t('modal.updateApplicationLicence.form.licenceOperationOptions.add')
        },
        {
            value: LicenceOperation.DECREASE,
            label: i18next.t('modal.updateApplicationLicence.form.licenceOperationOptions.remove')
        }
    ];

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{i18next.t('modal.updateApplicationLicence.title')}</h5>
            </div>
            <form onSubmit={handleSubmit(() => handleSaveClick())}>
                <div className="modal-body modal-overflow--visible mt-1">
                    <div className="row">
                        <div className="col-12">
                            <SelectInputWithLabel name={'licenceOperation'}
                                                  label={i18next.t('modal.updateApplicationLicence.form.licenceOperation')}
                                                  placeholder={i18next.t('modal.updateApplicationLicence.form.licenceOperationPlaceholder')}
                                                  options={licenceOperationOptions}
                                                  validate={[required]}/>
                        </div>
                    </div>
                    <CSSTransitionHOC renderOn={ applicationDetail?.licence?.type === LicenceType.QUANTITY_BASED && licenceOperationFieldValue !== undefined}
                                      clazzNames="toggle">
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <InputWithLabel name={'quantity'}
                                                    type={InputType.NUMBER}
                                                    appendIcon
                                                    label={i18next.t('modal.updateApplicationLicence.form.quantity')}
                                                    validate={[required, quantityValidation]}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p dangerouslySetInnerHTML={{
                                        __html: i18next.t('modal.updateApplicationLicence.currentQuantityCount',
                                            {
                                                quantityAssigned: applicationDetail?.licence?.quantityAssigned,
                                                quantityUsed: applicationDetail?.licence?.quantityUsed
                                            })
                                    }}/>
                                </div>
                            </div>
                        </React.Fragment>
                    </CSSTransitionHOC>
                    <CSSTransitionHOC renderOn={ applicationDetail?.licence?.type === LicenceType.KEY_BASED && licenceOperationFieldValue !== undefined}
                                      clazzNames="toggle">
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <TextareaInputWithLabel name={'licenceKeys'}
                                                            label={i18next.t('modal.updateApplicationLicence.form.licenceKeys')}
                                                            validate={[required]}/>
                                </div>
                            </div>
                            {!!applicationDetail?.licence?.keys &&
                            <div className="row">
                                <div className="col-12">
                                    <Accordion className="border--gray" title={i18next.t('modal.updateApplicationLicence.currentKeyListLabel')}>
                                        <div className="row" >
                                            <div className="col-12">
                                                <ul>
                                                    {applicationDetail?.licence?.keys
                                                        .map((licenceKey) =>
                                                            <li key={licenceKey.key}>
                                                                {`${licenceKey.key}`}
                                                                <strong>
                                                                    {licenceKey.status === 'used' ? ` (${i18next.t('modal.updateApplicationLicence.currentKeyUsedLabel')})` : ''}
                                                                </strong>
                                                            </li>)
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                    </CSSTransitionHOC>
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        <div className="col-auto pr-2">
                            <ButtonSecondary isBlock type="button"
                                             onClick={onCancelClick}>
                                {i18next.t('common.buttons.back')}
                            </ButtonSecondary>
                        </div>
                        <div className="col-auto pl-1">
                            <ButtonPrimary isBlock>
                                {i18next.t('common.buttons.save')}
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default applicationForm<IModalUpdateApplicationLicenceProps>(ModalUpdateApplicationLicence);

/*PROPS*/

interface IModalUpdateApplicationLicenceProps {
    setModalType?: any
    handleSubmit?: any
    applicationDetailId: string | undefined
    applicationDetail: ApplicationResponse | undefined
    licenceOperationFieldValue: LicenceOperation | undefined
    actions: {
        getApplications: any
        getApplicationDetail: any
        resetApplicationDetail: any
        updateApplicationLicence: any
    }
}