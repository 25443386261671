import React, { useCallback, useEffect, useState } from 'react';
import Tenant from '../../../data/tenant/Tenant';
import Party from '../../../data/party/Party';
import Card from '../../common/other/Card';
import i18next from 'i18next';
import { ButtonSecondary } from '../../common/button/buttonTypes';
import Table from '../../common/table/Table';
import { ModalType } from '../../../constants';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';
import ModalContainer from '../../containers/ModalContainer';
import ModalDeleteParty from '../../modal/ModalDeleteParty';
import history from '../../../router/history';
import { AppRoute } from '../../../router/appRoutes';
import { debounce } from '../../../utils/utils';
import ImportExportCSV from '../../common/other/ImportExportCSV';
import Pagination from "../../common/other/Pagination";
import ITableHeader from "../../../data/common/ITableHeader";
import PartyListFilterRequest from "../../../data/request/PartyListFilterRequest";

const PartyListPage = (
    {
        selectedTenant,
        total,
        partyList,
        modalType,
        filter,
        actions: {
            deleteParty,
            setModalType,
            importPartiesForTenant,
            getPartyForTenant,
            changeInput,
            resetPartyFilter,
            exportPartiesForTenant
        }
    }: IPartyListPageProps
) => {

    const [partyIdForDelete, setPartyIdForDelete] = useState<any>();

    useEffect(() => {
        if (selectedTenant) getPartyForTenant(1);

        return () => {
            resetPartyFilter();
        }
    }, []);

    const handleDetailClick = (e: React.MouseEvent<HTMLElement>, partyId: string | undefined): void => {
        if (!!partyId) {
            history.push(AppRoute.PARTY + `/${partyId}`)
        }
    };

    const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, partyId: string | undefined): void => {
        if (!!partyId) {
            setPartyIdForDelete(partyId);
            setModalType(ModalType.DELETE_PARTY, e);
        }
    };

    const handleExportToCsvClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        exportPartiesForTenant();
    };

    const getParty = (page: number): void => {
        getPartyForTenant(page);
    }

    const handleImportCSVClick = () => {
        importPartiesForTenant().then(() => {
            getPartyForTenant();
        });
    };

    const debouncedGetPartyForTenant = useCallback(
      debounce(() => getPartyForTenant()), []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
        changeInput(e.target.name, e.target.value);
        debouncedGetPartyForTenant();
    }

    const partyTableColumns: ITableHeader[] = [
        {
            title: i18next.t('pages.party.list.table.header.order'),
            dataIndex: 'order',
            filterable: false,
        },
        {
            title: i18next.t('pages.party.list.table.header.email'),
            dataIndex: 'email',
            filterable: true,
        },
        {
            title: i18next.t('pages.party.list.table.header.firstName'),
            dataIndex: 'firstName',
            filterable: true,
        },
        {
            title: i18next.t('pages.party.list.table.header.lastname'),
            dataIndex: 'lastName',
            filterable: true,
        },
    ];

    if (!selectedTenant) {
        return <div/>
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Card title={ i18next.t('pages.party.list.label') }>
                        <div className="row float-right">
                            <div className="col-12">
                                <ImportExportCSV fileInputName={ 'partyXLSX' }
                                                 actions={{ handleExportToCsvClick, handleImportCSVClick }}/>
                            </div>
                        </div>

                        <Table headers={ partyTableColumns } emptyHeader={ ["after"] } onInputChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e) }>
                            { partyList && partyList.length > 0
                              ? partyList?.map((party, index) => {
                                return (
                                    <tr key={ party.id }>
                                        <th scope="row">{ ++index }</th>
                                        <td>{ party.email }</td>
                                        <td>{ party.firstName }</td>
                                        <td>{ party.lastName }</td>
                                        <td className="p-2">
                                            <span>
                                                <ButtonSecondary type={'button'}
                                                                 className="mr-2"
                                                                 onClick={ (e: React.MouseEvent<HTMLElement>) => handleDetailClick(e, party.id) }>
                                                    { i18next.t('common.buttons.detail') }
                                                </ButtonSecondary>
                                                <ButtonSecondary type={'button'}
                                                                 onClick={ (e: React.MouseEvent<HTMLElement>) => handleDeleteClick(e, party.id) }>
                                                    { i18next.t('common.buttons.delete') }
                                                </ButtonSecondary>
                                            </span>
                                        </td>
                                    </tr>
                                )})
                              : <tr><td colSpan={ 8 }>{ i18next.t('pages.application.detail.noPartiesFound') }</td></tr>
                            }
                        </Table>
                        <Pagination label="party list pagination"
                                    total={ total }
                                    filter={ filter }
                                    actions={{ getParty }} />
                    </Card>
                </div>
            </div>
            <CSSTransitionHOC renderOn={ modalType === ModalType.DELETE_PARTY }>
                <ModalContainer>
                    <ModalDeleteParty partyId={ partyIdForDelete } actions={{ deleteParty }} />
                </ModalContainer>
            </CSSTransitionHOC>
        </div>
    )
};

export default PartyListPage;

/*PROPS*/

export interface IPartyListPageProps {
    selectedTenant: Tenant | undefined
    total: number | undefined
    partyList: Party[] | undefined
    modalType: ModalType
    filter: PartyListFilterRequest
    actions: {
        deleteParty: any
        setModalType: any
        importPartiesForTenant: any
        getPartyForTenant: any
        changeInput: any
        resetPartyFilter: any
        exportPartiesForTenant: any
    }
}