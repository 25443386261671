import React, { useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import { AppRoute } from '../../../router/appRoutes';
import { Role } from '../../../data/login/Role';
import { hasUserDesiredRole } from '../../../utils/utils';
import { MenuItems } from '../../../other/MenuItems';
import ApplicationResponse from '../../../data/response/ApplicationResponse';
import i18next from 'i18next';
import { ModalType } from '../../../constants';
import history from "../../../router/history";

const Menu = (
    {
        applicationsAssignedToTenant,
        actions: {
            setModalType,
            openApplicationManual
        }
    }: IMenuProps
) => {
    const [activeMenuItem, setActiveMenuItem] = useState(0);

    useEffect(() => {
        if (history.location.pathname === AppRoute.INDEX && activeMenuItem !== 1.1) {
            setActiveMenuItem(1.1);
        } else {
            MenuItems.forEach(menuItem => {
               menuItem.subMenuItems?.forEach(submenuItem => {
                   if (submenuItem.path === history.location.pathname && activeMenuItem !== submenuItem.order) setActiveMenuItem(submenuItem.order);
               })
            });
        }
    }, [history.location.pathname]);

    const applications: IMenuShape = {
        order: 3,
        name: i18next.t('menu.application.title'),
        subMenuItems: applicationsAssignedToTenant?.map((app: ApplicationResponse, index) => {
            return {
                order: 3 + (0.1 * ++index),
                name: app.application?.guiName,
                path: `${ AppRoute.APPLICATION }/${ app.application?.id }`,
            }
        })
    };

    const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setModalType(ModalType.LOGOUT);
    };

    const downloadManual = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        openApplicationManual();
    }

    return (
        <ul className="list-unstyled components">
            { MenuItems.filter((menuItem) => !menuItem.allowedRoles || hasUserDesiredRole(menuItem.allowedRoles))
                .map((menuItem) => <MenuItem menuItem={ menuItem }
                                                  activeMenuItem={ activeMenuItem }
                                                  actions={{ setActiveMenuItem }}
                                                  key={ menuItem.order } />
            )}
            { !!applicationsAssignedToTenant && applicationsAssignedToTenant.length > 0 &&
                <MenuItem menuItem={ applications }
                          activeMenuItem={ activeMenuItem }
                          actions={{ setActiveMenuItem }} />
            }
            <li>
                <a href="/manual" target="_blank" onClick={ downloadManual }>
                    { i18next.t('menu.manual.title') }
                </a>
            </li>
            <li>
                <a href="/logout" onClick={ handleLogoutClick }>
                    { i18next.t('menu.logout.title') }
                </a>
            </li>
        </ul>
    )
};

export default Menu;

/*PROPS*/

interface IMenuProps {
    applicationsAssignedToTenant: ApplicationResponse[] | undefined
    actions: {
        setModalType: any,
        openApplicationManual: any
    }
}

export interface IMenuShape {
    order: number
    name: string
    path?: AppRoute
    subMenuItems?: ISubMenuShape[]
    allowedRoles?: Role[]
}

export interface ISubMenuShape {
    order: number
    name: string | undefined
    path: AppRoute | string,
    allowedRoles?: Role[]
}
