import React, { RefObject, useRef } from 'react';
import { FileUploadInput } from '../form/InputTypes';
import { ButtonPrimary, ButtonSecondary } from '../button/buttonTypes';
import i18next from 'i18next';
import { fileForm } from '../../containers/form/FIleForm';

/**
 * D.Blazicek, ISDD, 07/2021
 * @ImportExportCSV changed to process XLSX files
 */

const ImportExportCSV = (
    {
        fileInputName,
        actions: {
            handleExportToCsvClick,
            handleImportCSVClick
        }
    }: IImportExportCSVProps
) => {
    const fileInputRef: RefObject<HTMLInputElement> = useRef(null);

    const handleImportCSVButtonClick = (e: any) => {
        e.preventDefault();
        if (!!fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <React.Fragment>
            <FileUploadInput name={ fileInputName }
                             inputRef={ fileInputRef }
                             afterChangeAction={ handleImportCSVClick } />
            <ButtonPrimary className="mr-2"
                           onClick={ handleExportToCsvClick }>
                { i18next.t('common.buttons.xlsx.export') }
            </ButtonPrimary>
            <ButtonSecondary onClick={ handleImportCSVButtonClick }>
                { i18next.t('common.buttons.xlsx.import') }
            </ButtonSecondary>
        </React.Fragment>
    )
};

export default fileForm<IImportExportCSVProps>(ImportExportCSV);

/*PROPS*/

interface IImportExportCSVProps {
    fileInputName: string
    actions: {
        handleExportToCsvClick: any
        handleImportCSVClick: any
    }
}