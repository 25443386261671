import {connect} from 'react-redux';
import {TenantCreatePage} from '../pages/tenant/TenantCreatePage';
import {tenantForm} from './form/TenantForm';
import {bindActionCreators} from 'redux';
import {createTenant} from '../../actions/tenantActions';
import {IState} from '../../reducers/indexReducer';

const mapStateToProps = (state: IState) => {
    return {
        selectedTenant: state.tenantReducer.selectedTenant
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({
            createTenant
        }, dispatch)
    }
};

const TenantContainer = connect(mapStateToProps, mapDispatchToProps)(tenantForm(TenantCreatePage));

export default TenantContainer;