import Redux, {createStore, applyMiddleware} from 'redux';
import thunk, {ThunkMiddleware} from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from './reducers/indexReducer'

const composeEnhancers = composeWithDevTools({
    serialize: true
});

const store = createStore(reducers,
    process.env.REACT_APP_APPLICATION_ENV !== 'prod' ?
        composeEnhancers(
            applyMiddleware(thunk as ThunkMiddleware<Redux.Store>)
        )
        :
        applyMiddleware(thunk as ThunkMiddleware<Redux.Store>)
);

export default store;