import React from 'react';
import PartyFormComponent from './PartyFormComponent';
import i18next from 'i18next';
import CardFormWrapper from '../../common/other/CardFormWrapper';
import { FormOperation } from '../../../constants';
import { toast } from 'react-toastify';
import ToastBody from '../../common/other/ToastBody';
import history from '../../../router/history';
import { AppRoute } from '../../../router/appRoutes';
import Tenant from '../../../data/tenant/Tenant';

const PartyCreatePage = (
    {
        handleSubmit,
        selectedTenant,
        actions: {
            createParty
        }
    }: IPartyCreatePageProps
) => {

    const onFormSubmit = () => {
        createParty().then(() => {
            toast.success(<ToastBody message={i18next.t('messages.success.createParty')} />);
            history.push(AppRoute.PARTY_LIST);
        });
    };

    if (!selectedTenant) {
        return <div/>
    }

    return (
        <CardFormWrapper>
            <PartyFormComponent onFormSubmit={handleSubmit(() => onFormSubmit())}
                                label={i18next.t('pages.party.create.label')}
                                buttonLabel={i18next.t('common.buttons.create')}
                                formOperation={FormOperation.CREATE}/>
        </CardFormWrapper>
    )
};

export default PartyCreatePage;

/*PROPS*/

export interface IPartyCreatePageProps {
    handleSubmit: any
    error: any,
    selectedTenant: Tenant | undefined
    actions: {
        createParty: any
    }
}