import React, {ReactNode} from 'react';
import cx from 'classnames'

import './form.scss'

const LabelWrapperForCheckbox = (
    {
        label,
        children,
        meta
    }: ILabelWrapperForCheckboxProps
) => {

    const wrapperClasses = cx(
        'custom-control',
        'custom-checkbox'
    );

    const labelClasses = cx(
        'custom-control-label'
    );

    return (
        <div className={wrapperClasses}>
            {children}
            <label className={labelClasses}>{label}</label>
            {meta && meta.touched && meta.error &&
            <div className={'invalid-feedback'}>
                {meta.error}
            </div>
            }
        </div>
    )
};

export default LabelWrapperForCheckbox;

/*PROPS*/ 

export interface ILabelWrapperForCheckboxProps {
    label?: any
    children?: ReactNode
    meta?: {
        touched?: boolean
        error?: string
    }
}