import { SET_LOGIN_DATA_ACTION } from '../actions/loginActions';
import ILoginReducer from '../data/login/ILoginReducer';
import { Role } from '../data/login/Role';
import LoginData from '../data/login/LoginData';
import IAction from '../data/common/IAction';

const InitialState: ILoginReducer = {
    loginData: undefined,
    userRole: undefined
};

const LoginReducer = (state: ILoginReducer = InitialState, action: IAction): ILoginReducer => {
    switch (action.type) {
        case SET_LOGIN_DATA_ACTION:
            return {
                ...state,
                loginData: action.payload,
                userRole: getUserRole(action.payload)
            };
        default:
            return state;
    }
};

export default LoginReducer;

const getUserRole = (loginData: LoginData) => {
    const roles: Role[] | undefined = loginData.accessTokenPayload?.resourceAccess?.b2bmu?.roles;
    if (!!roles && roles.indexOf(Role.OPERATOR) > -1) {
        return Role.OPERATOR
    } else if (!!roles && roles.indexOf(Role.READ_ONLY_OPERATOR) > -1) {
        return Role.READ_ONLY_OPERATOR
    } else if (!!roles && roles.indexOf(Role.CUSTOMER) > -1) {
        return Role.CUSTOMER
    } else {
        return undefined;
    }
};