import React, { useEffect, useState } from 'react';
import Card from '../../common/other/Card';
import Table from '../../common/table/Table';
import FindTenantsResponse from '../../../data/response/FindTenantsResponse';
import i18next from 'i18next';
import { ButtonSecondary } from '../../common/button/buttonTypes';
import { Role } from '../../../data/login/Role';
import ModalContainer from '../../containers/ModalContainer';
import ModalDeleteTenant from '../../modal/ModalDeleteTenant';
import { ModalType } from '../../../constants';
import CSSTransitionHOC from '../../css-transition/CSSTransitionHOC';
import Tenant from '../../../data/tenant/Tenant';

const TenantListPage = (
  {
    tenants,
    userRole,
    modalType,
    selectedTenant,
    actions: {
      setModalType,
      deleteTenant,
      getTenants
    }
  }: ITenantListPageProps
) => {

    const [tenantIdForDelete, setTenantIdForDelete] = useState<any>();

    useEffect(() => {
      getTenants();
    }, []);

    const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, tenantId: string | undefined): void => {
        if (!!tenantId) {
            setTenantIdForDelete(tenantId);
            setModalType(ModalType.DELETE_TENANT, e);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Card title={ i18next.t('pages.tenant.list.label') }>
                        <Table headers={ i18next.t('pages.tenant.list.table.header', { returnObjects: true }) } emptyHeader={["after"]}>
                            { tenants?.tenants?.map((tenant, index) => {
                                return (
                                    <tr key={ tenant.id }>
                                        <th scope="row">{ ++index }</th>
                                        <td>{ tenant.name }</td>
                                        <td>{ tenant.id }</td>
                                        <td className="p-2">
                                            { userRole === Role.OPERATOR && tenant.id !== selectedTenant?.id &&
                                            <ButtonSecondary type={'button'}
                                                             onClick={ (e: React.MouseEvent<HTMLElement>) => handleDeleteClick(e, tenant.id) }>
                                                { i18next.t('common.buttons.delete') }
                                            </ButtonSecondary>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </Table>
                    </Card>
                </div>
            </div>
            <CSSTransitionHOC renderOn={ modalType === ModalType.DELETE_TENANT }>
                <ModalContainer>
                    <ModalDeleteTenant actions={{ deleteTenant }} tenantId={ tenantIdForDelete } />
                </ModalContainer>
            </CSSTransitionHOC>
        </div>
    )
};

export default TenantListPage;

/*PROPS*/

export interface ITenantListPageProps {
  tenants: FindTenantsResponse | undefined
  userRole: Role | undefined
  modalType: ModalType
  selectedTenant: Tenant | undefined
  actions: {
    setModalType: Function
    deleteTenant: Function
    getTenants: Function
  }
}