import {Role} from './Role';

export default class ResourceAccess {
    b2bmu: B2Bmu | undefined;


    constructor(b2bmu: undefined | B2Bmu) {
        this.b2bmu = b2bmu;
    }

    static create(resourceAccess: any) {
        if (!resourceAccess?.b2bmu.roles) {
            return undefined;
        }

        return new ResourceAccess(
            B2Bmu.create(resourceAccess.b2bmu.roles)
        )
    }

    static validate = (data: ResourceAccess): boolean => !!data && !!data.b2bmu && !!data.b2bmu.roles && data.b2bmu.roles.some((role: string) => Object.values(Role).some(val => val === role))
}

class B2Bmu {
    roles: Role[] | undefined;

    constructor(roles: Role[]) {
        this.roles = roles;
    }

    static create(roles: any) {
        if (!roles) {
            return undefined;
        }

        return new B2Bmu(roles.filter((role: string) => Object.values(Role).some(val => val === role)))
    }
}