import React, {ReactNode} from 'react';
import {RowInputWithLabel} from '../../common/form/InputTypes';
import i18next from 'i18next';
import {email, required} from '../../../utils/formValidators';
import {InputType} from '../../common/form/Input';
import {ButtonPrimary} from '../../common/button/buttonTypes';
import {FormOperation} from '../../../constants';

const PartyFormComponent = (
    {
        onFormSubmit,
        label,
        buttonLabel,
        readonly = false,
        formOperation= FormOperation.READ,
        children
    }: IPartyFormComponentProps
) => {

    return (
        <form onSubmit={onFormSubmit}>
            <div className="row mb-3">
                <div className="col-12 col-sm-9 offset-sm-3">
                    <h5>{label}</h5>
                </div>
            </div>
            {formOperation !== FormOperation.CREATE &&
                <div className="row">
                    <div className="col-12">
                        <RowInputWithLabel name={'id'}
                                           label={i18next.t('pages.party.form.id')}
                                           readOnly={true}
                                           labelClassName="col-12 col-sm-3 text-sm-right"
                                           inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-12">
                    <RowInputWithLabel name={'email'}
                                       label={i18next.t('pages.party.form.email')}
                                       validate={[required, email]}
                                       type={InputType.MAIL}
                                       readOnly={readonly}
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <RowInputWithLabel name={'firstName'}
                                       label={i18next.t('pages.party.form.firstName')}
                                       validate={[required]}
                                       readOnly={readonly}
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <RowInputWithLabel name={'lastName'}
                                       label={i18next.t('pages.party.form.lastName')}
                                       validate={[required]}
                                       readOnly={readonly}
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <RowInputWithLabel name={'note'}
                                       label={i18next.t('pages.party.form.note')}
                                       readOnly={readonly}
                                       appendIcon
                                       labelClassName="col-12 col-sm-3 text-sm-right"
                                       inputWrapperClassName="col-12 col-sm-8 col-lg-5" />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-9 offset-sm-3">
                    {!!children ?
                        children
                        :
                        <ButtonPrimary>
                            {buttonLabel}
                        </ButtonPrimary>
                    }
                </div>
            </div>
        </form>
    )
};

export default PartyFormComponent;

/*PROPS*/

export interface IPartyFormComponentProps {
    onFormSubmit: any
    label: string
    buttonLabel: string
    readonly?: boolean
    formOperation?: FormOperation
    children?: ReactNode
}