export enum LicenceType {
    UNLIMITED = 'unlimited',
    QUANTITY_BASED = 'quantityBased',
    KEY_BASED = 'keyBased'
}

// export enum LicenceType {
//     UNLIMITED = 'UNLIMITED',
//     QUANTITY_BASED = 'QUANTITY_BASED',
//     KEY_BASED = 'KEY_BASED'
// }