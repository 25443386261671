export default class LicenceKey {
    key: string;
    status: string;

    constructor(key: string, status: string) {
        this.key = key;
        this.status = status;
    }

    static create(licenceKey: LicenceKey): LicenceKey {
        return new LicenceKey(licenceKey?.key, licenceKey?.status)
    }
}