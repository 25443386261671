import React, {useEffect} from 'react';
import i18next from 'i18next';
import {ButtonPrimary, ButtonSecondary} from '../common/button/buttonTypes';
import {ModalType} from '../../constants';
import {InputWithLabel, SelectInputWithLabel, TextareaInputWithLabel} from '../common/form/InputTypes';
import {applicationForm} from '../containers/form/ApplicationForm';
import {LicenceType} from '../../data/application/LicenceType';
import {minValue1, required} from '../../utils/formValidators';
import CSSTransitionHOC from '../css-transition/CSSTransitionHOC';
import store from '../../configureStore';
import {change, untouch} from 'redux-form';
import {InputType} from '../common/form/Input';
import {toast} from 'react-toastify';
import ToastBody from '../common/other/ToastBody';
import ApplicationResponse from '../../data/response/ApplicationResponse';
import ApplicationParam from '../../data/application/ApplicationParam';

const ModalAssignApplicationToTenant = (
    {
        setModalType,
        handleSubmit,
        applicationDetailId,
        applicationDetail,
        licenceTypeFieldValue,
        actions: {
            assignAppToTenant,
            getApplications,
            getApplicationDetail,
            resetApplicationDetail
        }
    }: IModalAssignApplicationToTenantProps
) => {

    useEffect(() => {
        getApplicationDetail(applicationDetailId);

        return () => resetApplicationDetail();
    }, [applicationDetailId, getApplicationDetail, resetApplicationDetail]);

    const handleAssignClick = ():void => {
        assignAppToTenant().then(() => {
            toast.success(<ToastBody message={i18next.t('messages.success.assignAppToTenant')} />)
            getApplications().then(() => {
                setModalType(ModalType.UNSET);
            })
        });
    };

    const onCancelClick = (): void => {
        setModalType(ModalType.UNSET);
    };

    const handleFieldExit = (fieldName: string): void => {
        store.dispatch(change('application', fieldName, null));
        store.dispatch(untouch('application', fieldName));
    };

    const licenceTypeOptions = () => {
        if (applicationDetail?.parameters?.find((appParam: ApplicationParam) => appParam.isLicenced)) {
            return [
                {
                    value: LicenceType.KEY_BASED,
                    label: i18next.t('modal.assignApplicationToTenant.form.licenceTypeOptions.keyBased')
                }
            ]
        }

        return [
            {
                value: LicenceType.UNLIMITED,
                label: i18next.t('modal.assignApplicationToTenant.form.licenceTypeOptions.unlimited')
            },
            {
                value: LicenceType.QUANTITY_BASED,
                label: i18next.t('modal.assignApplicationToTenant.form.licenceTypeOptions.quantityBased')
            }
        ];
    };

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">{i18next.t('modal.assignApplicationToTenant.title')}</h5>
            </div>
            <form onSubmit={handleSubmit(() => handleAssignClick())}>
                <div className="modal-body modal-overflow--visible mt-1">
                    <div className="row">
                        <div className="col-12">
                            <SelectInputWithLabel name={'licenceType'}
                                                  label={i18next.t('modal.assignApplicationToTenant.form.licenceType')}
                                                  placeholder={i18next.t('modal.assignApplicationToTenant.form.licenceTypePlaceholder')}
                                                  options={licenceTypeOptions()}
                                                  validate={[required]}/>
                        </div>
                    </div>
                    <div className="position-relative">
                        <CSSTransitionHOC renderOn={licenceTypeFieldValue === LicenceType.QUANTITY_BASED}
                                          clazzNames="toggle"
                                          onExit={() => handleFieldExit('quantity')}>
                            <div className="row">
                                <div className="col-12">
                                    <InputWithLabel name={'quantity'} type={InputType.NUMBER}
                                                    label={i18next.t('modal.assignApplicationToTenant.form.quantity')}
                                                    validate={[required, minValue1]}/>
                                </div>
                            </div>
                        </CSSTransitionHOC>
                        <CSSTransitionHOC renderOn={licenceTypeFieldValue === LicenceType.KEY_BASED}
                                          clazzNames="toggle"
                                          onExit={() => handleFieldExit('licenceKeys')}>
                            <div className="row">
                                <div className="col-12">
                                    <TextareaInputWithLabel name={'licenceKeys'}
                                                            label={i18next.t('modal.assignApplicationToTenant.form.licenceKeys')}
                                                            validate={[required]}/>
                                </div>
                            </div>
                        </CSSTransitionHOC>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row float-right">
                        <div className="col-auto pr-2">
                            <ButtonSecondary isBlock type="button"
                                             onClick={onCancelClick}>
                                {i18next.t('common.buttons.back')}
                            </ButtonSecondary>
                        </div>
                        <div className="col-auto pl-1">
                            <ButtonPrimary isBlock>
                                {i18next.t('common.buttons.assign')}
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default applicationForm<IModalAssignApplicationToTenantProps>(ModalAssignApplicationToTenant);

/*PROPS*/

export interface IModalAssignApplicationToTenantProps {
    setModalType?: any
    handleSubmit?: any
    applicationDetailId: string | undefined
    licenceTypeFieldValue: LicenceType | undefined
    applicationDetail: ApplicationResponse | undefined
    actions: {
        assignAppToTenant: any
        getApplications: any
        getApplicationDetail: any
        resetApplicationDetail: any
    }
}