import React from 'react';
import history from '../../../router/history';
import {ISubMenuShape} from './Menu';

const SubMenuItem = (
    {
        subMenuItem: {
            order,
            name,
            path
        },
        activeMenuItem,
        actions: {
            setActiveMenuItem
        }
    }: ISubMenuItemProps
) => {

    const onSubMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setActiveMenuItem(order)
        history.push(path)
    };

    return (
        <li>
            <a href={path}
               className={activeMenuItem === order ? 'active' : ''}
               onClick={onSubMenuClick}>
                {name}
            </a>
        </li>
    )
};

export default SubMenuItem;

/*PROPS*/

interface ISubMenuItemProps {
    subMenuItem: ISubMenuShape
    activeMenuItem: Number
    actions: {
        setActiveMenuItem: Function
    }
}