import {reduxForm} from "redux-form";

export const applicationForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'application',
    touchOnBlur: false
})(component);

export const applicationDetailForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'applicationDetail',
    touchOnBlur: false,
    enableReinitialize: true,
    destroyOnUnmount: false
})(component);

export const assignApplicationToPartyForm = <T extends {}>(component: any) => reduxForm<{}, T>({
    form: 'assignApplicationToPartyForm',
    touchOnBlur: false,
})(component);