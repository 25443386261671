import React from 'react';
import cx from 'classnames';

import './form.scss'

export enum InputType {
    TEXT = "text",
    PASS = "password",
    MAIL = "email",
    TEL = "tel",
    CHECKBOX = "checkbox",
    NUMBER = 'number',
    RADIO = 'radio'
}

const Input = (
    {
        tooltipId,
        type = InputType.TEXT,
        readOnly= false,
        className,
        hidden,
        placeholder,
        input,
        error,
        meta,
        onChange,
        name,
        maxlength
    }: IInputProps
) => {

    const classes = cx(
        {
            'form-control': type !== InputType.CHECKBOX,
            'custom-control-input': type === InputType.CHECKBOX,
            'is-invalid': !!error || (meta && meta.touched && meta.error)
        },
        className
    );

    return (
        <input className={ classes }
               type={ type }
               hidden={ hidden }
               readOnly={ readOnly }
               placeholder={ placeholder }
               data-tip data-for={ tooltipId }
               onChange={ onChange }
               name={ name }
               maxLength={ maxlength }
               { ...input } />
    )
};

export default Input

/*PROPS*/

export interface IInputProps {
    tooltipId?: string
    hidden?: boolean
    type?: InputType,
    readOnly?: boolean,
    className?: string,
    placeholder?: string
    input?: any,
    error?: string,
    meta?: {
        touched?: boolean,
        error?: string
    },
    onChange?: any,
    name?: string,
    maxlength?: number
}