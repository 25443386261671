import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './navbar.scss'
import Tenant from '../../data/tenant/Tenant';
import {ButtonNavbar} from '../common/button/buttonTypes';
import {ModalType} from '../../constants';
import FindTenantsResponse from '../../data/response/FindTenantsResponse';

const Navbar = (
    {
        selectedTenant,
        tenants,
        isSideMenuVisible,
        setIsSideMenuVisible,
        actions: {
            setModalType
        }
    }: INavbarProps
) => {

    return (
        <div className="navbar navbar-dark bg-dark">
            <button className="navbar-toggler" type="button" onClick={() => setIsSideMenuVisible(!isSideMenuVisible)}>
                <FontAwesomeIcon icon={"align-justify"} />
            </button>
            <div className="m-1">
                {!!selectedTenant &&
                <ButtonNavbar onClick={(e: React.MouseEvent<HTMLElement>) => setModalType(ModalType.RESELECT_TENANT, e)} disabled={tenants?.tenants?.length === 1}>
                    <span>
                        {selectedTenant.name}
                        {tenants?.tenants?.length && tenants?.tenants?.length > 1 &&
                            <FontAwesomeIcon className="ml-2" icon={"pen"} />
                        }
                    </span>
                </ButtonNavbar>
                }
            </div>
        </div>
    )
};

export default Navbar;

/*PROPS*/

export interface INavbarProps {
    selectedTenant: Tenant | undefined
    tenants: FindTenantsResponse | undefined
    isSideMenuVisible: boolean
    setIsSideMenuVisible: Function
    actions: {
        setModalType: Function
    }
}