import Application from '../application/Application';
import Party from '../party/Party';

export default class GetPartyDetailResponse {
    applications: Application[] | undefined;
    party: Party | undefined;

    constructor(applications: Application[] | undefined, party: Party | undefined) {
        this.applications = applications;
        this.party = party;
    }

    static create(getPartyDetailResponse: any): GetPartyDetailResponse | undefined {
        if (!getPartyDetailResponse) {
            return undefined;
        }

        return new GetPartyDetailResponse(
            getPartyDetailResponse?.applications?.map((app: Application) => Application.create(app)),
            Party.create(getPartyDetailResponse.party)
        )
    }
}